import { useLocation, useNavigate } from "react-router-dom";
import "./ProgressEdit.scss";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { toast } from "react-toastify";
import { CustomDropDown } from "../../Components/CustomDropDown/CustomDropDown";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import { BackButton } from "../../Components/Common/BackButton/BackButton";

const text1 = "Back";
const text2 = "Edit Progress";
const text3 = "Current State";
const text4 = "Desired State";

export const ProgressEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(location.state || {});
  const [updatedProgress, setUpdatedProgress] = useState("");
  const [progressOptions, setProgressOptions] = useState([]);

  const handleUpdate = async () => {
    // updateCandidateAvailability;
    try {
      const endPoint = "/erp/client/role/update/progress";

      let stageNamesAfterUpdatedProgress;

      // Find the index of `updatedProgress` in `progressOptions`
      const updatedProgressIndex = progressOptions.findIndex(
        (stage) => stage.stageName === updatedProgress
      );

      // If `updatedProgress` is found, start from that index
      stageNamesAfterUpdatedProgress =
        updatedProgressIndex !== -1
          ? progressOptions
              .slice(updatedProgressIndex)
              .map((stage) => stage.stageName)
          : []; // Return an empty list if `updatedProgress` is not found

      const data = {
        id: userData?.emailId,
        clientId: localStorage.getItem("clientId"),
        stages: stageNamesAfterUpdatedProgress,
      };

      // console.log("to be deleted", data);

      const res = await axios.post(endPoint, data);

      if (res) {
        console.log(res);
        handleBackButton();
      }
    } catch (err) {
      console.log("handleUpdate error progress==>", err);
      toast.error(err.response.data.exception);
    }
  };

  const fetchRoundData = async () => {
    try {
      const apiUrl = "/erp/client/report/getStages";
      const requestData = {
        clientId: localStorage.getItem("clientId"),
        roleId: userData.userRole,
      };
      const response = await axios.post(apiUrl, requestData);

      setProgressOptions([...response?.data]);
    } catch (error) {
      console.error("Error fetching round data:", error);
    }
  };

  const handleBackButton = () => {
    // Example: navigate to the previous page
    window.history.back();
  };

  useEffect(() => {
    if (!userData.emailId) {
      toast.error("User data is missing!");
    } else {
      fetchRoundData();
    }
  }, [userData, navigate]);

  return (
    <PageLayout>
      <div className="availibilty-edit">
        <GoToAllUsers title={text1} handleBackButton={handleBackButton} />
        <EditTitle title={text2} />
        <UserName name={userData?.userName} />
        <UserProgress
          userData={userData}
          updatedProgress={updatedProgress}
          setUpdatedProgress={setUpdatedProgress}
          progressOptions={progressOptions}
        />
        <SubmitButton handleUpdate={handleUpdate} />
      </div>
    </PageLayout>
  );
};

const GoToAllUsers = ({ title, handleBackButton }) => {
  return (
    <div className="back-arrow">
      <BackButton handleBackButton={handleBackButton} name={title} />
    </div>
  );
};

const UserName = ({ name }) => {
  return <div className="userName text2">{name}</div>;
};

const EditTitle = ({ title }) => {
  return <div className="userName">{title}</div>;
};

const SubmitButton = ({ handleUpdate }) => {
  return (
    <div className="submit-button">
      <button onClick={() => handleUpdate()}>Update</button>
    </div>
  );
};

const UserProgress = ({
  userData,
  updatedProgress,
  setUpdatedProgress,
  progressOptions,
}) => {
  // Assuming `userData.progress` holds the current stageId
  const currentStageId = userData?.progress;

  // Find the index of the current stage
  const currentStageIndex = progressOptions.findIndex(
    (stage) => stage?.stageId === currentStageId
  );

  // Get all stages before and including the current stage
  const progressOptions2 = progressOptions
    ?.slice(0, currentStageIndex + 1) // Slice up to and including the current stage
    ?.map((stage) => stage.stageName); // Map to get only stage names

  // console.log("progressOptions=>", progressOptions);
  // console.log("progressOptions2=>", progressOptions2);
  // console.log("current=>", userData?.progress);

  // Find the stage object based on `userData.progress` (stageId)
  const currentStage = progressOptions.find(
    (stage) => stage.stageId === userData?.progress
  );

  // Determine what to display: stage name or "Personal Details"
  const displayStageName =
    userData?.progress?.toLowerCase() === "personal details".toLowerCase()
      ? "Personal Details"
      : currentStage?.stageName || userData?.progress; // Fallback to stageId if no match is found

  return (
    <div className="progress-update">
      <div className="col-1">
        <div className="text">{text3}</div>
        <div className="item">{displayStageName}</div>
      </div>
      <div>
        <div className="text">{text4}</div>
        <div>
          <CustomDropDown
            options={progressOptions2}
            handleChange={(val) => setUpdatedProgress(val)}
            value={updatedProgress}
            placeholder={"Select..."}
          />
        </div>
      </div>
    </div>
  );
};
