import React, { useEffect, useState } from "react";
import {
  Button,
  InputBase,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import styles from "./CreateRolePage.module.scss";
import { BackButton } from "../../Components/Common/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MCQConfigComponent from "../../Components/SetConfig/MCQConfigComponent/MCQConfigComponent";
import CodingConfigComponent from "../../Components/SetConfig/CodingConfigComponent/CodingConfigComponent";
import axios from "axios";
import { toast } from "react-toastify";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";
import { useLocation } from "react-router-dom";
import HrInterviewConfigComponent from "../../Components/SetConfig/HrInterviewConfig/HrInterviewConfig";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Delete, Add } from "@mui/icons-material";

export const CreateRolePage = () => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/roles/list");
  };

  // State to track which configuration is currently selected and formData
  const [currentConfig, setCurrentConfig] = useState(null);
  const [formData, setFormData] = useState({
    businessUnit: "",
    roleName: "",
    rounds: [{ name: "", type: "", config: "" }],
    file: null,
  });
  const [allConfigsData, setAllConfigsData] = useState({});
  const [loading, setLoading] = useState(false);

  // fetching previous data
  const location = useLocation();
  const { name, businessUnit, clientId, edit, uniqueRoleId } =
    location.state || {};
  const [responseData, setResponseData] = useState(null);
  const [rows, setRows] = useState([{ name: "", email: "" }]);

  const [selectedOption, setSelectedOption] = useState("bulk");
  const [isEditable, setIsEditable] = useState(true);

  // Validation function for Business Unit, Role Name, Round Name, and Round Type
  const validateFormData = () => {
    if (!formData.businessUnit) {
      toast.error("Business Unit is required");
      return false;
    }

    if (!formData.roleName) {
      toast.error("Role Name is required");
      return false;
    }

    for (let i = 0; i < formData.rounds.length; i++) {
      const round = formData.rounds[i];

      if (!round.name) {
        toast.error(`Round ${i + 1} Name is required`);
        return false;
      }

      if (!round.type) {
        toast.error(`Round ${i + 1} Type is required`);
        return false;
      }
    }

    if (selectedOption === "manual") {
      for (let i = 0; i < rows.length; i++) {
        if (!rows[i].name || !rows[i].email) {
          toast.error(`Row ${i + 1} Name and Email are required`);
          return false;
        }
      }
    }

    // If no errors, return true
    return true;
  };

  const prepareAndSendApiData = async (index) => {
    // Find the round where type is 'mcq'
    // const mcqRound = formData?.rounds?.find((round) => round?.type === "mcq");
    const mcqRound = formData?.rounds?.[index];

    // Extract the name of the round, or null if no round is found
    const mcqRoundName = mcqRound ? mcqRound.name : null;
    try {
      // Extract required data
      const roleName = formData.roleName;
      // const mcqConfig = formData.rounds.find(
      //   (round) => round.type === "mcq"
      // )?.config;
      const mcqConfig = formData?.rounds?.[index]?.config;
      if (!mcqConfig?.totalQuestions || mcqConfig.totalQuestions <= 0) {
        return false;
      }

      // Languages list can be dynamically selected from available options, case-insensitive check
      const languageOptions = [
        "Python",
        "Java",
        "JavaScript",
        "React",
        "C++",
        "ServiceNow",
        "ITSM",
        "ITOM",
        "GRC",
      ]; // Add more as needed
      const languageOptionsLowerCase = languageOptions.map((option) =>
        option.toLowerCase()
      );

      // Prepare the languageQuestions array dynamically from form data
      const languageQuestions = mcqConfig
        ? mcqConfig?.questionTypes
            ?.filter((q) =>
              languageOptionsLowerCase.includes(q.questionType.toLowerCase())
            ) // Case-insensitive check
            ?.map((q) => ({
              language: q.questionType, // Keep original case for language name
              totalQuestions: q?.questions || 0,
              languageThreshold: q?.threshold || 0,
              languageQuestionInPercent: {
                Easy: q.easy || 0,
                Medium: q.medium || 0,
                Difficult: q.hard || 0,
              },
            }))
        : [];

      // db list can be dynamically selected from available options, case-insensitive check
      const dbOptions = ["MySql", "MongoDb"]; // Add more as needed
      const dbOptionsLowerCase = dbOptions.map((option) =>
        option.toLowerCase()
      );

      // Prepare the dbQuestions array dynamically from form data
      const dbQuestions = mcqConfig
        ? mcqConfig?.questionTypes
            ?.filter((q) =>
              dbOptionsLowerCase.includes(q.questionType.toLowerCase())
            ) // Case-insensitive check
            ?.map((q) => ({
              database: q.questionType, // Keep original case for db  name
              totalQuestions: q?.questions || 0,
              databaseThreshold: q?.threshold || 0,
              databaseQuestionInPercent: {
                Easy: q.easy || 0,
                Medium: q.medium || 0,
                Difficult: q.hard || 0,
              },
            }))
        : [];

      // Prepare the payload for the API
      const apiData = {
        clients: [
          {
            clientId: localStorage.getItem("clientId"), // Client ID, change dynamically if needed
            fields: [
              {
                field: "Development", // Business Unit from the form
                roles: [
                  {
                    role: roleName, // Role Name from the form
                    stages: [
                      {
                        overallThreshold: mcqConfig?.thresholdQuestions,
                        // stageId: "mcq", // MCQ round
                        stageId: mcqRoundName, // MCQ round
                        aptiQuestions: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "aptitude"
                            )?.questions || 0
                          : 0,
                        aptiEasyQuestionInPercent: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "aptitude"
                            )?.easy || 0
                          : 0,
                        aptiMediumQuestionInPercent: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "aptitude"
                            )?.medium || 0
                          : 0,
                        aptiDifficultQuestionInPercent: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "aptitude"
                            )?.hard || 0
                          : 0,
                        aptiThreshold: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "aptitude"
                            )?.threshold || 0
                          : 0,
                        dsaQuestions: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "dsa"
                            )?.questions || 0
                          : 0,
                        dsaEasyQuestionInPercent: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "dsa"
                            )?.easy || 0
                          : 0,
                        dsaMediumQuestionInPercent: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "dsa"
                            )?.medium || 0
                          : 0,
                        dsaDifficultQuestionInPercent: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "dsa"
                            )?.hard || 0
                          : 0,
                        dsaThreshold: mcqConfig
                          ? mcqConfig.questionTypes.find(
                              (q) => q.questionType.toLowerCase() === "dsa"
                            )?.threshold || 0
                          : 0,
                        totalQuestions: mcqConfig
                          ? mcqConfig.totalQuestions
                          : 0,
                        totalTimeInMins: mcqConfig
                          ? mcqConfig.totalQuestions
                          : 0, // Adjust time dynamically based on your form

                        // Dynamic language handling
                        languageQuestions:
                          languageQuestions.length > 0 ? languageQuestions : [],
                        // Dynamic db handling
                        databaseQuestions:
                          dbQuestions.length > 0 ? dbQuestions[0] : {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
      return apiData;
    } catch (error) {
      // console.error("Error while sending API data:", error);
      console.error("Error while creating API data:", error);
    }
  };

  const prepareAndSendHrApiData = async (index) => {
    // Prepare the HR interview data based on the round index from formData
    const hrConfig = formData.rounds[index].config;
    // if (!hrConfig?.interviewerName || hrConfig.interviewerName.trim() === "") {
    //   return false;
    // }

    console.log("hrConfig before sending => ", formData.rounds[index]);

    // Example structure for HR API data:
    const hrApiData = {
      clients: [
        {
          clientId: localStorage.getItem("clientId"), // Client ID dynamically from localStorage
          fields: [
            {
              field: formData.businessUnit, // Business unit from formData
              roles: [
                {
                  role: formData.roleName, // Role name from formData
                  stages: [
                    {
                      stageId: formData.rounds[index].name || "", // Use stageId if present in hrConfig, else empty
                      interviewerName: hrConfig?.interviewerName || "", // Interviewer Name
                      interviewType: !hrConfig?.manualScheduling
                        ? "softSkillsInterview"
                        : "", // Default interview type
                      meetingDuration: hrConfig?.meetingDuration || "", // Meeting duration, default 45 minutes
                      interviewerEmail: hrConfig?.emailId || "", // Interviewer's email
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };

    return hrApiData; // Return the prepared HR data
  };

  const prepareAndSendCodingApiData = async (index) => {
    try {
      // Prepare the coding round data based on the round index from formData
      const codingConfig = formData.rounds[index]?.config;
      console.log("coding config for formattings: ", codingConfig);
      if (!codingConfig?.totalQuestions || codingConfig.totalQuestions <= 0) {
        return false;
      }

      // Prepare topics array from the codingConfig
      const topics =
        codingConfig?.topics?.map((topic) => ({
          topic: topic.topic, // Topic name
          totalTopicQuestions: topic.numOfQuestions, // Number of questions for the topic
        })) || [];

      // Prepare the coding API data
      const codingApiData = {
        clients: [
          {
            clientId: localStorage.getItem("clientId"), // Client ID from localStorage
            fields: [
              {
                field: formData.businessUnit, // Static field, adjust if needed
                roles: [
                  {
                    role: formData.roleName, // Static role, adjust if needed
                    stages: [
                      {
                        stageId: formData.rounds[index]?.name || "coding", // Stage ID from round name or "coding"
                        topics: topics, // Array of topics with total questions
                        totalQuestions: codingConfig?.totalQuestions || 0, // Total questions
                        totalSkipQuestions:
                          codingConfig?.totalSkipQuestions || 0, // Total skip questions
                        thresholdQuestions:
                          codingConfig?.thresholdQuestions || 0, // Threshold questions
                        totalTimeInMins: codingConfig?.totalTime || 0, // Total time in minutes
                        languagesAllowed: codingConfig?.selectedLanguages,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };

      return codingApiData; // Return the prepared coding data
    } catch (error) {
      console.error("Error while creating coding API data:", error);
    }
  };

  // const handleAllConfigsCreate = async () => {
  //   try {
  //     // Array to hold all the API call promises
  //     const apiCalls = [];

  //     // Loop through all rounds in formData
  //     for (const [index, round] of formData.rounds.entries()) {
  //       if (round.type === "mcq") {
  //         console.log("Preparing API call for mcqConfig...");
  //         const apiEndpoint = "/erp/client/role/config/mcq";
  //         // Wait for data to be prepared before proceeding with the API call
  //         const dataForMcqConfig = await prepareAndSendApiData(index);
  //         console.log("Data sending for mcqRound =>", dataForMcqConfig);
  //         // Make the API call and store the promise in apiCalls array
  //         const mcqCall = axios.post(apiEndpoint, dataForMcqConfig);
  //         apiCalls.push(mcqCall);
  //       }
  //       // Add the HR interview API call logic here
  //       else if (round.type === "softSkillsInterview") {
  //         console.log("Preparing API call for hrConfig...");
  //         const apiEndpoint = "/erp/client/role/config/hr-interview";
  //         // Wait for data to be prepared before proceeding with the API call
  //         const dataForHrConfig = await prepareAndSendHrApiData(index); // Function for HR data
  //         console.log("Data sending for hrRound =>", dataForHrConfig);
  //         // Make the API call and store the promise in apiCalls array
  //         const hrCall = axios.post(apiEndpoint, dataForHrConfig);
  //         apiCalls.push(hrCall);
  //       }
  //       // Add the coding round API call logic here
  //       else if (round.type === "coding") {
  //         console.log("Preparing API call for codingConfig...");
  //         const apiEndpoint = "/erp/client/role/config/coding";
  //         // Wait for data to be prepared before proceeding with the API call
  //         const dataForHrConfig = await prepareAndSendCodingApiData(index); // Function for HR data
  //         console.log("Data sending for coding config =>", dataForHrConfig);
  //         // Make the API call and store the promise in apiCalls array
  //         const codingCall = axios.post(apiEndpoint, dataForHrConfig);
  //         apiCalls.push(codingCall);
  //       }
  //     }
  //     // return;
  //     // Make all API calls concurrently and wait for all to finish
  //     const responses = await Promise.all(apiCalls);

  //     // Log all the responses
  //     responses.forEach((response, index) => {
  //       console.log(`API response ${index + 1}:`, response.data);
  //     });

  //     // Handle success
  //     console.log("All API calls completed successfully.");
  //     toast.success("Config created successfully!");
  //     navigate("/roles/list");
  //   } catch (error) {
  //     // Handle error if any API call fails
  //     console.error("Error occurred during API calls:", error);
  //     toast.error("Failed to create config. Please try again.");
  //   }
  // };

  // handleCreate function
  const handleAllConfigsCreate = async () => {
    try {
      // Loop through all rounds in formData sequentially
      for (const [index, round] of formData.rounds.entries()) {
        let apiEndpoint;
        let data;

        // Determine the API endpoint and data preparation function based on the round type
        if (round.type === "mcq") {
          console.log("Preparing API call for mcqConfig...");
          apiEndpoint = "/erp/client/role/config/mcq";
          data = await prepareAndSendApiData(index);
          console.log("Data sending for mcqRound =>", data);
        } else if (round.type === "softSkillsInterview") {
          console.log("Preparing API call for hrConfig...");
          apiEndpoint = "/erp/client/role/config/hr-interview";
          data = await prepareAndSendHrApiData(index);
          console.log("Data sending for hrRound =>", data);
        } else if (round.type === "coding") {
          console.log("Preparing API call for codingConfig...");
          apiEndpoint = "/erp/client/role/config/coding";
          data = await prepareAndSendCodingApiData(index);
          console.log("Data sending for coding config =>", data);
        }

        // If an endpoint is set, make the API call and wait for it to complete before continuing
        if (apiEndpoint && data) {
          const response = await axios.post(apiEndpoint, data);
          console.log(`API response for ${round.type} round:`, response.data);
        }
      }

      // Handle success after all API calls are complete
      console.log("All API calls completed successfully.");
      // toast.success("Config created successfully!");
      navigate("/roles/list");
    } catch (error) {
      // Handle error if any API call fails
      console.error(
        "Error occurred during API calls for config creation :",
        error
      );
      // toast.error("Failed to create config. Please try again.");
    }
  };

  const handleCreate = async () => {
    // await handleAllConfigsCreate();
    // return;
    // Call validation function before making the API calls
    if (!validateFormData()) {
      return; // If validation fails, stop execution
    }
    setLoading(true);
    console.log("config data=>", allConfigsData);
    console.log("form data=>", formData);
    // return;
    // Example API endpoints (replace these with actual API URLs)
    const FIRST_API_URL = "/erp/client/role/create"; // First API call
    const SECOND_API_URL = "/erp/client/role/stages"; // Second API call
    // First API payload
    const firstApiData = {
      clientId: localStorage.getItem("clientId"), // Dummy value for now, replace dynamically if needed
      roleName: formData?.roleName,
      businessUnit: formData?.businessUnit,
      numberOfRounds: formData?.rounds?.filter(
        (round) => round?.name && round?.type
      )?.length, // Count only rounds with selected values
      uniqueRoleId: uniqueRoleId,
      // description: "", // Dummy description
    };

    try {
      // First API call
      if (isEditable) {
        const firstApiResponse = await axios.post(FIRST_API_URL, firstApiData);
        console.log("First API response:", firstApiResponse.data);
      }

      // Create a new FormData object to handle form data and the file
      const formDataToSubmit = new FormData();

      // Prepare the JSON data for stages
      const jsonData = {
        roleId: formData.roleName, // Using roleName as the roleId
        clientId: localStorage.getItem("clientId"),
        stages: formData?.rounds
          ?.filter((round) => round.name && round.type) // Only include rounds that have both name and type
          .map((round) => ({
            stageType:
              round.type === "softSkillsInterview"
                ? "softSkillsInterview"
                : round.type, // Round type (mcq, coding, etc.)
            stageName: round.name, // Round name
          })),
      };

      // Append the JSON data as a string to FormData
      formDataToSubmit.append("jsonData", JSON.stringify(jsonData));

      // Append the file if present
      if (selectedOption === "bulk" && formData.file) {
        formDataToSubmit.append("file", formData.file);
      } else {
        console.log("file not found");
      }

      if (selectedOption === "manual") {
        const invitees = { invites: rows };
        formDataToSubmit.append("invitees", JSON.stringify(invitees));
      }

      // Second API call
      const secondApiResponse = await axios.post(
        SECOND_API_URL,
        formDataToSubmit
      );
      toast.success("Successfully crearted role and hiring process");
      console.log("Second API response:", secondApiResponse.data);

      await handleAllConfigsCreate();
      // Handle success (e.g., show success message, redirect, etc.)
      // console.log("Both API calls succeeded!");
    } catch (error) {
      // Handle errors from either API call
      console.error("Error during API calls:", error);
      const message = error?.response?.data || "Error updating role";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  // TODO: Implement this function to prefill the form with existing role data
  const prefillData = (data) => {
    setIsEditable(data?.editable ?? false);

    setFormData({
      businessUnit: data.businessUnit,
      roleName: data.roleId,
      rounds: data?.stages?.map((round) => ({
        name: round.stageName,
        type: round.stageType,
        stageId: round.stageId,
      })),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (name && businessUnit && clientId && edit) {
        setLoading(true); // Set loading to true when API call starts
        try {
          const endPoint = "/erp/client/role/details/get";
          const data = {
            roleId: name,
            clientId,
            businessUnit,
          };
          const response = await axios.post(endPoint, data);
          setResponseData(response.data);
          prefillData(response.data); // Prefill the form with existing role data if edit is true
        } catch (error) {
          console.error("Error fetching role data:", error);
        } finally {
          setLoading(false); // Set loading to false after the API call is complete
        }
      }
    };

    fetchData();
  }, [name, businessUnit, clientId, edit]);

  return (
    <PageLayout>
      {/* Conditionally render either the role creation form or the configuration component */}
      {currentConfig ? (
        <SetConfigComponent
          currentConfig={currentConfig}
          formData={formData}
          setFormData={setFormData}
          setCurrentConfig={setCurrentConfig}
          allConfigsData={allConfigsData}
          setAllConfigsData={setAllConfigsData}
          isEditable={isEditable}
        />
      ) : loading ? (
        <GlobalLoader />
      ) : (
        <>
          <div className={styles.createRoleContainer}>
            <BackButton handleBackButton={handleBackButton} name={"Back"} />
            <h1 className={styles.title}>
              {edit ? "Edit Role" : "Create New Role"}
            </h1>

            <RoleForm
              formData={formData}
              setFormData={setFormData}
              setCurrentConfig={setCurrentConfig}
              setAllConfigsData={setAllConfigsData}
              allConfigsData={allConfigsData}
              edit={edit}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              rows={rows}
              setRows={setRows}
              isEditable={isEditable}
            />

            <Button
              variant="contained"
              color="primary"
              className={styles.createButton}
              onClick={handleCreate}
            >
              {edit ? "Update" : "Create"}
            </Button>
          </div>
        </>
      )}

      {/* <DynamicPopup open={true} /> */}
    </PageLayout>
  );
};

// RoleForm Component with single state for all form data
const RoleForm = ({
  formData,
  setFormData,
  setCurrentConfig,
  setAllConfigsData,
  allConfigsData,
  edit,
  selectedOption,
  setSelectedOption,
  rows,
  setRows,
  isEditable,
}) => {
  const [errors, setErrors] = useState({
    inviteesFileError: "",
  });

  const updateRowValue = (rowIndex, field, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][field] = value;
    setRows(updatedRows);
  };

  const addNewRow = () => {
    setRows([...rows, { name: "", email: "" }]);
  };

  const removeRow = (rowIndex) => {
    const updatedRows = rows.filter((_, index) => index !== rowIndex);
    setRows(updatedRows);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    //remove errors
    setErrors({
      inviteesFileError: "",
    });

    // remove file if manual selected
    if (value === "manual") {
      // Remove file field from formData
      setFormData((prev) => {
        const { file, ...rest } = prev;
        return rest;
      });
    } else if (value === "bulk") {
      setRows([{ name: "", email: "" }]);
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle round input changes
  const handleRoundChange = (index, field, value) => {
    const updatedRounds = formData.rounds.map((round, i) => {
      if (i === index) {
        // If the round type is being changed, clear the config for that round
        if (field === "type") {
          return { ...round, [field]: value, config: {} }; // Clear the config
        } else {
          return { ...round, [field]: value }; // Regular field update
        }
      }
      return round;
    });
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  // Add a new round
  const addRound = () => {
    setFormData((prev) => ({
      ...prev,
      rounds: [...prev.rounds, { name: "", type: "", config: "" }],
    }));
  };

  // Remove a round
  // const removeRound = (index) => {
  //   console.log("Removing round " + index);
  //   return;
  //   const updatedRounds = formData.rounds.filter((_, i) => i !== index);
  //   setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  // };

  const removeRound = (index) => {
    console.log("Removing round " + index);

    // Check if formData.rounds is defined and if the index is valid
    if (!formData.rounds || index < 0 || index >= formData.rounds.length) {
      console.warn("Invalid round index");
      return;
    }

    // Check if the round type is 'mcq' (case-insensitive)
    const roundToRemove = formData.rounds[index];
    console.log("roundToRemove " + roundToRemove?.type);
    // return;
    if (roundToRemove.type?.toLowerCase() === "mcq") {
      console.log("Removing mcqConfig as the round type is 'mcq'");
      setAllConfigsData((prevData) => {
        const { mcqConfig, ...otherConfigs } = prevData; // Remove mcqConfig
        return { ...otherConfigs };
      });
    } else if (roundToRemove.type?.toLowerCase() === "coding") {
      console.log("Removing mcqConfig as the round type is 'mcq'");
      setAllConfigsData((prevData) => {
        const { codingConfig, ...otherConfigs } = prevData; // Remove mcqConfig
        return { ...otherConfigs };
      });
    }

    // Remove the round from formData.rounds
    const updatedRounds = formData?.rounds?.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  // Handle file upload
  const handleFileUpload = (files) => {
    const file = files[0];

    // Clear any previous errors
    setErrors((prevErrors) => ({ ...prevErrors, inviteesFileError: "" }));

    if (file) {
      const fileType = file.type;
      const fileSize = file.size;

      // Check if the file is a CSV
      if (fileType !== "text/csv") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          inviteesFileError: "Only CSV files are allowed",
        }));
        return;
      }

      // Check if file size exceeds 5 MB
      if (fileSize > 5 * 1024 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          inviteesFileError: "File size should not exceed 5 MB",
        }));
        return;
      }

      // Set file in formData if valid
      setFormData((prev) => ({ ...prev, file: file }));
    }
  };

  // Handle file drop in drag-and-drop area
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileUpload(files);
  };

  // Trigger file input on click
  const triggerFileInput = () => {
    document.getElementById("fileUpload").click();
  };

  // Add function to remove the selected file
  const removeFile = () => {
    setFormData((prev) => ({ ...prev, file: null }));
  };

  // Handle sample file download
  const handleDownloadSample = () => {
    const sampleData =
      "Name,Email\nJohn Doe,john@example.com\nJane Smith,jane@example.com";
    const blob = new Blob([sampleData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample_invitees.csv";
    a.click();
  };

  const handleRoleChange = (e) => {
    // clients[0].fields.[0].roles.[0].role
    setFormData((prev) => ({ ...prev, roleName: e.target.value }));
    setAllConfigsData((prevData) => {
      // Check the condition for the specific nested value
      if (prevData?.mcqConfig?.clients?.[0]?.fields?.[0]?.roles?.[0]?.role) {
        // Update the specific field while keeping the rest of the data unchanged
        return {
          ...prevData, // Keep all previous data
          mcqConfig: {
            ...prevData.mcqConfig,
            clients: [
              {
                ...prevData.mcqConfig.clients[0],
                fields: [
                  {
                    ...prevData.mcqConfig.clients[0].fields[0],
                    roles: [
                      {
                        ...prevData.mcqConfig.clients[0].fields[0].roles[0],
                        role: e.target.value, // Change the role value here
                      },
                    ],
                  },
                ],
              },
            ],
          },
        };
      }
      return prevData; // If the condition is not met, return the previous data unchanged
    });
  };

  return (
    <div className={styles.roleFormContainer}>
      {/* Business Unit and Role Name */}
      <div className={styles.row}>
        <FieldWrapper label="Business Unit" error={errors.businessUnit}>
          {/* <Select
            value={formData.businessUnit}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, businessUnit: e.target.value }))
            }
            displayEmpty
            className={`${styles.dropdown} ${
              errors.businessUnit ? styles.errorField : ""
            }`}
            style={{ color: formData.businessUnit === "" ? "#7a7a7a" : "" }}
          >
            <MenuItem value="" disabled>
              Select Business Unit
            </MenuItem>
            <MenuItem value="Development">Development</MenuItem>
            <MenuItem value="HR">HR</MenuItem>
            <MenuItem value="Design">Design</MenuItem>
          </Select> */}
          {console.log(isEditable)}
          <InputBase
            placeholder="Select Business Unit"
            disabled={!isEditable}
            value={formData.businessUnit}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, businessUnit: e.target.value }))
            }
            className={`${styles.inputField} ${
              errors.businessUnit ? styles.errorField : ""
            }`}
          />
        </FieldWrapper>

        <FieldWrapper label="Role Name" error={errors.roleName}>
          <InputBase
            placeholder="Enter Role Name"
            disabled={!isEditable}
            value={formData.roleName}
            onChange={(e) => handleRoleChange(e)}
            className={`${styles.inputField} ${
              errors.roleName ? styles.errorField : ""
            }`}
          />
        </FieldWrapper>
      </div>

      {/* Dynamically generated rounds */}
      {formData.rounds.map((round, index) => (
        <RoundRow
          key={index}
          index={index}
          round={round}
          onDelete={() => removeRound(index)}
          onChange={handleRoundChange}
          errors={errors}
          setCurrentConfig={setCurrentConfig}
          edit={edit}
          isEditable={isEditable}
        />
      ))}

      {/* Add Round Button */}
      <Button
        variant="outlined"
        color="primary"
        onClick={addRound}
        className={styles.addRoundButton}
      >
        <span>
          <AddIcon />
        </span>
        Add Round
      </Button>

      {/* Invitees Section */}
      {formData.rounds.length > 0 && (
        <FieldWrapper
          label="List of invitees"
          error={errors.inviteesFileError}
          style={{ width: "100%", maxWidth: "100%", marginTop: "50px" }}
        >
          <FormControl>
            <RadioGroup
              value={selectedOption}
              onChange={handleChange}
              row
              aria-labelledby="radio-buttons-group"
            >
              <FormControlLabel
                value="bulk"
                control={<Radio />}
                label="Bulk upload"
              />
              <FormControlLabel
                value="manual"
                control={<Radio />}
                label="Invite manually"
              />
            </RadioGroup>
            {/* <p>Selected Option: {selectedOption}</p> */}
          </FormControl>

          {selectedOption.toLowerCase() == "bulk" ? (
            <>
              <div className={styles.uploadSection}>
                <div
                  className={styles.dragDropArea}
                  onClick={triggerFileInput}
                  onDrop={handleDrop}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <FileUploadOutlinedIcon className={styles.uploadIcon} />
                  <p>
                    Drag & drop or <span> Choose file </span>to invite
                    candidates
                  </p>
                  <input
                    type="file"
                    id="fileUpload"
                    accept=".csv"
                    onChange={(e) => handleFileUpload(e.target.files)}
                    className={styles.fileInput}
                  />
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.sampleFileButton}
                  onClick={handleDownloadSample}
                >
                  Download Sample File
                </Button>
              </div>

              {/* Display selected file with an option to remove it */}
              {formData.file && (
                <div className={styles.fileDisplay}>
                  <span>{formData.file.name}</span>
                  <IconButton
                    // color="secondary"
                    onClick={removeFile}
                    className={styles.removeFileButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}

              {errors.inviteesFileError && (
                <span className={styles.errorText}>
                  {errors.inviteesFileError}
                </span>
              )}
            </>
          ) : (
            <>
              <div className={styles["bulk-upload-table"]}>
                <div>
                  {rows.map((row, index) => (
                    <div className={styles.roundRow}>
                      <div className={styles.rowContent}>
                        <FieldWrapper label={index === 0 ? "Name" : ""}>
                          <InputBase
                            placeholder={`Name`}
                            value={row.name}
                            onChange={(e) =>
                              updateRowValue(index, "name", e.target.value)
                            }
                            className={`${styles.inputField} `}
                          />
                        </FieldWrapper>
                        <FieldWrapper label={index === 0 ? "Email ID" : ""}>
                          <InputBase
                            placeholder={`Name`}
                            value={row.email}
                            onChange={(e) =>
                              updateRowValue(index, "email", e.target.value)
                            }
                            className={`${styles.inputField} `}
                          />
                        </FieldWrapper>
                        <IconButton
                          color="secondary"
                          onClick={() => removeRow(index)}
                          className={styles.deleteIcon}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={addNewRow}
                  className={styles["add-button"]}
                >
                  Add User
                </Button>
              </div>
            </>
          )}
        </FieldWrapper>
      )}
    </div>
  );
};

// FieldWrapper Component for uniform row structure and error handling
const FieldWrapper = ({ label, error, children, style }) => {
  return (
    <div className={styles.fieldWrapper} style={style}>
      <label className={styles.fieldLabel}>{label}</label>
      {children}
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

// RoundRow Component for each round
const RoundRow = ({
  index,
  round,
  onDelete,
  onChange,
  errors,
  setCurrentConfig,
  edit,
  isEditable,
}) => {
  return (
    <div className={styles.roundRow}>
      <div className={styles.rowContent}>
        <FieldWrapper
          label={`Round ${index + 1} Name`}
          error={errors[`roundName${index}`]}
        >
          <InputBase
            disabled={!isEditable}
            placeholder={`Enter Round ${index + 1} Name`}
            value={round.name}
            onChange={(e) => onChange(index, "name", e.target.value)}
            className={`${styles.inputField} ${
              errors[`roundName${index}`] ? styles.errorField : ""
            }`}
          />
        </FieldWrapper>

        <FieldWrapper label="Round Type" error={errors[`roundType${index}`]}>
          <Select
            disabled={!isEditable}
            value={round.type}
            onChange={(e) => onChange(index, "type", e.target.value)}
            displayEmpty
            className={`${styles.dropdown} ${
              errors[`roundType${index}`] ? styles.errorField : ""
            }`}
            style={{ color: round.type === "" ? "#7a7a7a" : "" }}
          >
            <MenuItem value="" disabled>
              Select Round Type
            </MenuItem>
            <MenuItem value="mcq">MCQ</MenuItem>
            <MenuItem value="coding">Coding</MenuItem>
            <MenuItem value="softSkillsInterview">
              Soft Skills Interview
            </MenuItem>
          </Select>
        </FieldWrapper>

        <IconButton
          color="secondary"
          onClick={onDelete}
          className={styles.deleteIcon}
        >
          <DeleteIcon />
        </IconButton>

        <Button
          variant="contained"
          color="primary"
          disabled={!round.name || !round.type}
          className={styles.configButton}
          style={{
            opacity: !round.name || !round.type ? "0.4" : "1",
          }}
          onClick={() => {
            // console.log(round.name, round.stageId);
            setCurrentConfig({
              roundIndex: index,
              roundName: round.name,
              roundType: round.type,
              stageId: round.stageId,
            });
          }}
        >
          {edit ? "View Configuration" : "Set Configuration"}
        </Button>
      </div>
    </div>
  );
};

// Placeholder component for Set Config
const SetConfigComponent = ({
  currentConfig,
  formData,
  setFormData,
  setCurrentConfig,
  allConfigsData,
  setAllConfigsData,
  isEditable,
}) => {
  const { roundIndex, roundName, roundType, stageId } = currentConfig;

  // This will be the placeholder logic for future components based on round type
  const getComponentForRoundType = () => {
    switch (roundType) {
      case "mcq":
        return (
          <MCQConfigComponent
            roundName={roundName}
            formData={formData}
            setFormData={setFormData}
            roundIndex={roundIndex}
            setCurrentConfig={setCurrentConfig}
            allConfigsData={allConfigsData}
            setAllConfigsData={setAllConfigsData}
            stageId={stageId}
            isEditable={isEditable}
          />
        );
      case "coding":
        return (
          <CodingConfigComponent
            roundName={roundName}
            formData={formData}
            setFormData={setFormData}
            roundIndex={roundIndex}
            setCurrentConfig={setCurrentConfig}
            allConfigsData={allConfigsData}
            setAllConfigsData={setAllConfigsData}
            stageId={stageId}
            isEditable={isEditable}
          />
        );
      case "softSkillsInterview":
        return (
          <HrInterviewConfigComponent
            roundName={roundName}
            formData={formData}
            setFormData={setFormData}
            roundIndex={roundIndex}
            setCurrentConfig={setCurrentConfig}
            allConfigsData={allConfigsData}
            setAllConfigsData={setAllConfigsData}
            stageId={stageId}
            isEditable={isEditable}
          />
        );
      default:
        return null;
    }
  };

  return <div>{getComponentForRoundType()}</div>;
};

const DynamicPopup = ({
  open,
  onClose,
  title = "Role creation error!",
  description = "Only 2 roles can be created for this account.",
  buttonLabel = "Okay",
  onButtonClick,
  icon: IconComponent, // Accepting the icon as a prop
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      {/* Close Icon */}
      <Box sx={{ position: "absolute", top: 8, right: 8 }}>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ textAlign: "center", padding: "24px 16px" }}>
        {/* First Row: Dynamic Icon */}
        {IconComponent && (
          <Box sx={{ marginBottom: 2 }}>
            <IconComponent sx={{ color: "red", fontSize: 48 }} />
          </Box>
        )}

        {/* Second Row: Title */}
        <Typography variant="h6" color="error" fontWeight="bold" gutterBottom>
          {title}
        </Typography>

        {/* Third Row: Description */}
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {description}
        </Typography>
      </DialogContent>

      {/* Button Row */}
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "24px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClick || onClose}
          sx={{ textTransform: "none", padding: "8px 32px", fontSize: "16px" }}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
