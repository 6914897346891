import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Drawer,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import styles from "./Sidebar.module.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

const Sidebar = () => {
  const location = useLocation(); // To check active route

  // Items list in state, now including icons
  const [menuItems] = useState([
    {
      label: "Dashboard ",
      path: "/dashboard/overview",
      icon: <DashboardIcon />,
    },
    { label: "Roles ", path: "/roles/list", icon: <SearchIcon /> },
    {
      label: "Configuration",
      path: "/analytics",
      icon: <BookmarkAddOutlinedIcon />,
    },
    {
      label: "Interview",
      path: "/candidate-metting",
      icon: <PersonSearchIcon />,
    },
  ]);

  return (
    <div className={styles.sidebarWrapper}>
      <Drawer variant="permanent" className={styles.sidebar}>
        <div className={styles.logoContainer}>
          <img
            src="/images/zeero-logo.svg"
            alt="Logo"
            className={styles.logo}
          />
        </div>

        <List className={styles.menuList}>
          {menuItems.map((item, index) => (
            <ListItem
              button
              component={Link}
              to={item.path}
              key={index}
              selected={location.pathname === item.path}
              className={`${styles.item} ${
                location.pathname === item.path ? styles.selectedItem : ""
              }`}
            >
              <ListItemIcon className={styles.icons}>{item.icon}</ListItemIcon>
              <ListItemText className={styles.label} primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
