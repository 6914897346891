import { useEffect, useState } from "react";
import "./McqRoundReport.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { ReportHeader } from "../ReportHeader/ReportHeader";
import icon1 from "../../../Assets/SVG/assessment-report/attempted.svg";
import icon2 from "../../../Assets/SVG/assessment-report/calendar.svg";
import icon3 from "../../../Assets/SVG/assessment-report/calendar2.svg";
import icon4 from "../../../Assets/SVG/assessment-report/correct.svg";
import icon5 from "../../../Assets/SVG/assessment-report/failed.svg";
import icon6 from "../../../Assets/SVG/assessment-report/target.svg";
import icon7 from "../../../Assets/SVG/assessment-report/time.svg";
import icon8 from "../../../Assets/SVG/Passed.svg";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const McqRoundReport = ({
  selectedRound,
  setSelectedRound,
  roundValue,
  userDetails,
  setLoader,
  pageInput,
}) => {
  const [performanceR1, setPerformanceR1] = useState({});
  const [questionData, setQuestionData] = useState([]);
  const [viewCompleteReport, setViewCompleteReport] = useState(false);

  const convertProgrammingAssessmentData = (assessmentData) => {
    console.log("got the assessment data=>", assessmentData);
    const questions = assessmentData?.mcqRoundQuestionsResponse;

    let convertedData = [];

    questions.forEach((question, index) => {
      const convertedQuestion = {
        questionText: question.question,
        options: question.choices || [], // If choices exist, use them, otherwise, use an empty array
        answerText: question.correctAnswer?.trim(), // Extracting answer text
        selectedAnswerText: question.developerAnswer?.trim() || "", // Extracting developer answer text if available
        questionStatus: question.questionStatus,
        score: question.score,
        totalScore: question.totalScore,
      };
      convertedData.push(convertedQuestion);
    });
    return convertedData;
  };

  const fetchMCQData = async () => {
    setLoader(true);
    const apiUrl = "/erp/client/report/mcq/report1";
    const requestData = {
      id: userDetails?.emailId,
      clientId: localStorage.getItem("clientId"),
      role: selectedRound.role,
      stageId: selectedRound.stageId,
    };

    try {
      const response = await axios.post(apiUrl, requestData);
      console.log("MCQ Data:", response.data);
      setPerformanceR1({ ...response?.data });
    } catch (error) {
      console.error("Error fetching MCQ data:", error);
      toast.error("Failed to fetch MCQ data.");
    } finally {
      setLoader(false);
    }
  };

  const getAlLDataRound1 = async () => {
    // setLoader(true);
    const apiUrl = "/erp/client/report/mcq/report2";
    const requestData = {
      id: userDetails?.emailId,
      clientId: localStorage.getItem("clientId"),
      role: selectedRound.role,
      stageId: selectedRound.stageId,
    };
    try {
      const response = await axios.post(apiUrl, requestData);
      console.log("MCQ full Data:", response.data);
      if (response?.data) {
        // Process the data as needed
        const data = convertProgrammingAssessmentData(response?.data);
        setQuestionData([...data]);
        // console.log("Converted", data);
      }
    } catch (err) {
      console.log("error in getAlLDataROund1=>", err);
    }
  };

  useEffect(() => {
    fetchMCQData();
    getAlLDataRound1();
    // Add cleanup logic here if needed
    return () => {
      // Clean up code here
    };
  }, []);

  return (
    <div>
      {" "}
      <ReportHeader
        selectedRound={selectedRound}
        setSelectedRound={setSelectedRound}
        roundValue={roundValue}
        userDetails={userDetails}
        setLoader={setLoader}
        pageInput={pageInput}
        submissionDate={performanceR1?.testEndTime?.split("-")[0]}
      />
      <div className="dev-report-mcq">
        <RoundReport
          selectedRound={selectedRound}
          setViewCompleteReport={setViewCompleteReport}
          performanceR1={performanceR1}
        />
        {viewCompleteReport && (
          <CompleteReport
            selectedRound={selectedRound}
            roundValue={roundValue}
            questionData={questionData}
            performanceR1={performanceR1}
          />
        )}
      </div>
    </div>
  );
};

const RoundReport = ({ setViewCompleteReport, performanceR1 }) => {
  return (
    <div className="round-report">
      <div className="data">
        <div style={{ border: "1px solid #DF7A03" }}>
          <div>
            <img src={icon6} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Total Score :{" "}
              <span style={{ color: "#DF7A03" }}>
                {" "}
                {typeof performanceR1?.totalScore === "number" &&
                  performanceR1?.totalScore?.toFixed(2)}
              </span>
              / <span>100</span>
            </div>
          </div>
        </div>
        <div
          style={{
            border:
              performanceR1?.status?.toLowerCase() === "passed"
                ? "1px solid #0BB762"
                : "1px solid #783ADE",
          }}
        >
          <div>
            <img
              src={
                performanceR1?.status?.toLowerCase() === "passed"
                  ? icon8
                  : icon5
              }
              alt=""
            />
          </div>
          <div
            style={{
              color:
                performanceR1?.status?.toLowerCase() === "passed"
                  ? "#0BB762"
                  : "#DE3A3B",
            }}
          >
            {performanceR1?.status}
          </div>
        </div>
        <div style={{ border: "1px solid #E4AD15" }}>
          <div>
            <img src={icon1} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Question Attempted :{" "}
              <span style={{ color: "#E4AD15" }}>
                {performanceR1?.questionsAttempted}{" "}
              </span>
              / <span>{performanceR1?.totalQuestions}</span>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #34A853" }}>
          <div>
            <img src={icon4} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Correct Answer :{" "}
              <span style={{ color: "#34A853" }}>
                {performanceR1?.correctQuestionsAttempted}
              </span>
              / <span>{performanceR1?.totalQuestions}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="title" style={{ marginTop: "50px" }}>
        Test Time Analysis
      </div>
      <div className="data">
        <div style={{ border: "1px solid #0A4FA0" }}>
          <div>
            <img src={icon7} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Time Taken For Completion :{" "}
              <span style={{ color: "#0A4FA0" }}>
                {" "}
                {performanceR1?.totalTimeTaken}{" "}
              </span>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #0D8AA5" }}>
          <div>
            <img src={icon2} alt="" />
          </div>
          <div>
            <div>
              {" "}
              Test Start Time :{" "}
              <span style={{ color: "#0D8AA5" }}>
                {performanceR1?.testStartTime}{" "}
              </span>{" "}
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #AA0047" }}>
          <div>
            <img src={icon3} alt="" />
          </div>
          <div>
            <div>
              Test End Time :{" "}
              <span style={{ color: "#AA0047" }}>
                {" "}
                {performanceR1?.testEndTime}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="view-report">
        <button onClick={() => setViewCompleteReport(true)}>
          View Complete Report
        </button>
      </div>
    </div>
  );
};

const CompleteReport = ({
  selectedRound,
  roundValue,
  questionData,
  performanceR1,
}) => {
  return (
    <div className="assessment-report">
      {/* {JSON.stringify(questionData)} */}
      <div className="top-group">
        <div className="round-num">
          <span> {selectedRound?.stageName} </span>
          <span>{selectedRound?.stageType}</span>
        </div>
        <div className="status">
          <div>
            Status : <span> {performanceR1?.status}</span>
          </div>
          <div>
            Final Score:{" "}
            <span>
              {performanceR1?.correctQuestionsAttempted}/{" "}
              {performanceR1?.totalQuestions}
            </span>
          </div>
        </div>
      </div>
      <DisplayQuestionData questionData={questionData} />
    </div>
  );
};

const DisplayQuestionData = ({ questionData }) => {
  return (
    <div className="question-data">
      {questionData.map((item, index) => (
        <div key={index} className="each">
          <div className="num">
            <div>Question : {index + 1}</div>
            <div className="answer">
              <div
                style={{
                  border:
                    item?.questionStatus === "Incorrect"
                      ? "1px solid #E15140"
                      : "",
                  color: item?.questionStatus === "Incorrect" ? "#E15140" : "",
                }}
              >
                {" "}
                {item?.questionStatus}
              </div>
              <div>
                {item?.score} / {item?.totalScore}
              </div>
            </div>
          </div>
          <div className="desc">{item?.questionText}</div>
          {/* <div className="options">
            {item?.options?.map((option, index) => (
              <div className="each-question">
                <div key={index} className="each-option">
                  {option}
                </div>
                <div className="response">
                  {item?.answerText === option && (
                    <div className="correct">
                      <CheckIcon /> Correct answer
                    </div>
                  )}
                  {item?.selectedAnswerText === option && (
                    <div
                      className={
                        item?.selectedAnswerText === item?.answerText
                          ? "correct"
                          : "candidate"
                      }
                    >
                      {item?.selectedAnswerText !== item?.answerText && (
                        <CloseIcon />
                      )}
                      Candidate's answer
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          <div className="options">
            {item?.options?.map((option, index) => (
              <div className="each-question" key={index}>
                <div
                  className="each-option"
                  dangerouslySetInnerHTML={{
                    __html: option.replace(/\n/g, "<br />"),
                  }}
                ></div>
                <div className="response">
                  {item?.answerText === option.trim() && (
                    <div className="correct">
                      <CheckIcon /> Correct answer
                    </div>
                  )}
                  {item?.selectedAnswerText === option && (
                    <div
                      className={
                        item?.selectedAnswerText === item?.answerText
                          ? "correct"
                          : "candidate"
                      }
                    >
                      {item?.selectedAnswerText !== item?.answerText && (
                        <CloseIcon />
                      )}
                      Candidate's answer
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
