import { useEffect, useRef, useState } from "react";
import {
  getPassedData,
  getUserCustomTestFormat,
} from "../../../Common/flowAfterLogin";
import { CandidateSubHeader } from "../../../Components/CandidateSubHeader/CandidateSubHeader";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import {
  round2Instructions,
  betweenTestInstructions,
  round2InstructionsDataScience,
} from "../../../mockData/roundInstructions/round2Instructions";
import { fullScreenExit } from "../../../mockData/roundInstructions/round1Instructions";
import { RoundInstructions } from "../../../Components/RoundInstructions/RoundInstructions";
import Popup from "../../../Components/Common/popUp/popUp";
import crosssIcon from "../../../Assets/SVG/Close.svg";
// import { BackToHome } from "../../../Components/BackToHome/BackToHome";
import blueClock from "../../../Assets/SVG/blueClock.svg";
import arrowDown from "../../../Assets/SVG/Dropdown.svg";
import "./AssessmentRound2.scss";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
// import { mockR2 } from "../../../mockData/round2";
import resetSvg from "../../../Assets/SVG/reset.svg";
import axios from "axios";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
// import { useNavigate } from "react-router";
import { SuccessRoundMessage } from "../../../Components/SuccessRoundMessage/SuccessRoundMessage";
import { FailureMessage } from "../../../Components/FailureMessage/FailureMessage";
import { HoldOnR2 } from "../../../Components/HoldOnR2/HoldOnR2";
import { CircularProgress } from "@mui/material";
import { SuccessAndFailureMessage } from "../../../mockData/successAndFailMessage";
import { InstructionsUi } from "../../../Components/InstructionsForEachRound/InstructionsForEachRound";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import { FullScreenExited } from "../../../Components/FullScreenModeExit/FullScreenModeExit";
import {
  MultipleTabSwitchError,
  TabSwitchUi,
} from "../../../Components/Common/TabSwitch/TabSwitch";
import { clearAllOldIntervals } from "../../../Common/resolve";
import executeAllCasesImage from "../../../Assets/SVG/round2/all_test_cases.gif";
import holdToNextQuestion from "../../../Assets/SVG/round2/SC_button.gif";
import { percentageImages } from "../../../mockData/round2Percentage";
import io from "socket.io-client";
import { PreProctoringScreenRound2 } from "../../../Components/Round2Proctoring/PreProctoringScreenRound2/PreProctoringScreenRound2";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { v4 as uuidv4 } from "uuid";

// const socketUrl = process.env.REACT_APP_SOCKET_URL;
const socketUrl = "https://erp-proctor.zeero.us/";
const proctorSocketUrl = "https://prod-erp-be.zeero.us/proctor/ws";
// const proctorSocketUrl = "wss://erp-be.wissda.cloud/proctor";

const initialQuestionConfig = {
  id: null,
  thresholdQuestions: null,
  totalQuestions: null,
  totalSkipQuestions: null,
  totalTimeInMins: null,
};

export const AssessmentRound2 = () => {
  const [instructions, setInstructions] = useState([]);
  const [passedData, setPassedData] = useState([]);
  const [isRound2Clear, setIsRoundClear] = useState(false);
  const [startTest, setStartTest] = useState(false);
  const [viewSuccess, setViewSuccess] = useState(false);
  const [viewFail, setViewFail] = useState(false);
  const [reapplydate, setreApplydate] = useState("");
  const [isRound2Failed, setIsRound2Failed] = useState({
    status: false,
    reApply: "",
  });
  const [resumeStatus, setResumeStatus] = useState({});
  const [viewButton, setViewButton] = useState(true);
  const [viewLoader, setViewLoader] = useState(false);
  const [isTerminated, setTerminated] = useState(false);
  const [multipleSwitchError, setmultipleSwitchError] = useState(false);
  const [viewHoldMessage, setViewHoldMessage] = useState(false);
  const [candidateRole, setCandidateRole] = useState("");
  const [viewPretest, setViewPretest] = useState(false);

  // proctoring related
  const [viewProctoringScreen, setViewProctoringScreen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const screenRecorderRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);

  // stages for test to determine sub header and pass failed redirection flow
  const [stages, setStages] = useState({});
  const [stageId, setStageId] = useState();
  const location = useLocation();

  const checkForPassedRound = async () => {
    try {
      const res = await getPassedData();
      setViewHoldMessage(false);
      const passedArray = res?.passed ? res.passed : [];
      console.log("checkForPassedRound2==>", passedArray);

      setPassedData([...passedArray]);

      // if (passedArray?.includes("Round1")) {
      if (passedArray?.includes(stageId)) {
        setIsRoundClear(true);
        setViewSuccess(true);
      } else if (res?.failed?.includes(stageId)) {
        setIsRound2Failed({
          status: true,
          reApply: res?.retestAcceptance,
        });
        setViewFail(true);
        setreApplydate(res?.retestAcceptance);
      } else if (res?.terminated?.includes(stageId)) {
        setViewButton(false);
        setmultipleSwitchError(true);
        setTerminated(true);
      }
      // } else {
      //   setViewButton(false);
      // }
    } catch (err) {
      console.log("checkForPassedRound error==>", err);
    }
  };

  const instructionsModify = (data) => {
    // Destructure dynamic values from data
    const { totalQuestions, totalTime } = data;

    // Update the round2Instructions array with dynamic values
    const modifiedInstructions = [
      {
        title: "Round Instructions",
        desc: [
          {
            desc: [
              `The test consists of ${totalQuestions} coding problems.`,
              "It is mandatory to attempt all the questions.",
              `Total time to complete the test is ${totalTime} mins.`,
              "Once the test begins, it must be completed in one session without the option to pause and resume later.",
              "You cannot move from one screen to another while the test is going on.",
              "You cannot copy or paste anything in the answer section.",
              "We highly recommend you to code in the programming language in which you are more proficient.",
              "Please revise the code before submitting. You cannot change the code once you click <span>'Submit'</span>.",
            ],
          },
        ],
      },
      {
        title: "General Instructions",
        desc: [
          {
            subtitle: "Before you start the test",
            desc: [
              "Make sure you have a good Internet connection.",
              "Please ensure that you are using the latest version of Chrome or Safari.",
              // "Once each test round is started, it must be completed in one sitting, you cannot abort and resume later.",
            ],
          },
          {
            subtitle: "During the test",
            desc: [
              "You can’t move to the previous question once the answer is submitted.",
              "Click on <span> 'Run Code'</span> to run the test case against your code.",
              "When you click on <span>'Skip to Next Question'</span>, you will automatically move to the next question, and the code written in the editor will be automatically submitted for evaluation.",
              "Once you click on <span>'Submit Code'</span>, you will automatically move to the next question, and your code will be submitted for evaluation.",
              "If you experience any difficulties accessing or submitting the test, please record your screen and send the recording to <span>assessment@zeero.us</span> for further assistance.",
            ],
          },
        ],
      },
    ];

    setInstructions(modifiedInstructions);
  };

  const resumeStatusR2 = async () => {
    try {
      if (!stageId) return;
      const endpoint = "/candidate/round2/getConfig/resume/config";
      const stageData = await getUserCustomTestFormat();
      // Find the stage with stageType 'mcq'
      const codingStage = stageData.stages.find(
        (stage) => stage.stageId === stageId
      );

      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
        clientId: stageData.clientId,
        stageId: stageId,
        role: stageData.roleId,
      };
      const res = await axios.post(endpoint, data);
      if (res?.data) {
        setResumeStatus({ ...res?.data });
        instructionsModify(res?.data);
      }
    } catch (err) {
      console.log("error in config r2==>", err);
    }
  };

  const getProfessionalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/professional";
      const data = { id: localStorage.getItem("email") };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.data?.subfield === "Data Science Engineer") {
        // console.log("getProfessionalDetails===> data science");
        setCandidateRole("Data_Science");
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  const getCustomFormat = async () => {
    try {
      const data = await getUserCustomTestFormat();

      if (data?.stages) {
        // Store the stages in the state as well if needed
        setStages(data);
      } else {
        toast.error("Stages are not available");
      }
    } catch (err) {
      console.error("error in getCustomFormat==>", err);
    }
  };

  const getAllData = async () => {
    setViewLoader(true);
    clearAllOldIntervals();
    const stagesData = await getCustomFormat();
    await checkForPassedRound();
    await resumeStatusR2();
    await getProfessionalDetails();
    setViewLoader(false);
  };

  const handleViewHold = async () => {
    setStartTest(false);
    setViewHoldMessage(true);
    // setTimeout(async () => {
    //   setViewHoldMessage(false);
    //   // await saveRatingAndStatus();
    //   // navigate("/assessment/home");
    //   // setStartTest(false);
    //   checkForPassedRound();
    // }, 21000);
  };

  const startRecordings = async () => {
    // setStartTest(true);

    // --------- below code is for sending data streams for

    try {
      const stageData = await getUserCustomTestFormat();
      console.log("startrecording r1=> ", stageData, stageId);

      const userName = localStorage.getItem("email") || "defaultUser";
      const round = "coding";
      const clientId = stageData.clientId;
      const role = stageData.roleId;

      // Initialize the socket connection with the given URL and query parameters
      console.log("socket url is ==>", socketUrl);
      socketRef.current = io(socketUrl, {
        query: { userName, round, clientId, stageId, role },
      });

      // Handle connection errors
      socketRef.current.on("connect_error", (err) => {
        console.error("Connection error:", err);
        setError("Connection error. Please check your network and try again.");
      });
      setError(null);

      // Start screen recording
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: { width: 1280, height: 720, frameRate: 15 },
        });
        const screenTrack = screenStream.getVideoTracks()[0];
        const screenSettings = screenTrack.getSettings();
        console.log(
          `Screen settings: displaySurface=${screenSettings.displaySurface}, width=${screenSettings.width}, height=${screenSettings.height}`
        );

        if (screenSettings.displaySurface !== "monitor") {
          alert("Please select the entire screen for recording.");
          screenTrack.stop();
          startRecordings(); // Re-prompt immediately
          return; // Exit function to prevent further execution
        }

        const screenRecorder = new MediaRecorder(screenStream, {
          mimeType: "video/webm",
          videoBitsPerSecond: 600000,
        });
        screenRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            // Emit screen recording data to the socket
            socketRef.current.emit("screen_data", event.data);
          }
        };
        screenRecorder.start(1000);
        screenRecorderRef.current = screenRecorder;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          console.log("User canceled screen sharing");
          return; // Exit function if user cancels
        } else {
          throw error; // Rethrow if another error occurs
        }
      }

      // Start video and audio recording from camera
      const avStream = await navigator.mediaDevices.getUserMedia({
        video: { width: 640, height: 480, frameRate: 15 },
        audio: true,
      });
      const avRecorder = new MediaRecorder(avStream, {
        mimeType: "video/webm",
        videoBitsPerSecond: 600000,
      });
      avRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // Emit camera video and audio data to the socket
          socketRef.current.emit("video_data", event.data);
        }
      };
      avRecorder.start(1000);
      mediaRecorderRef.current = avRecorder;

      setIsRecording(true);
      if (!startTest) {
        setViewPretest(true);
      }
    } catch (error) {
      console.error("Error starting recordings:", error);
      setError(
        "Error starting recordings. Please check your permissions and try again."
      );
    }
  };

  const stopRecordings = () => {
    if (screenRecorderRef.current) {
      screenRecorderRef.current.stop();
      screenRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      screenRecorderRef.current = null;
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }
    setIsRecording(false);
    setViewProctoringScreen(false);
    socketRef.current?.emit("stop_recording");
  };

  // Extract query parameters on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Parse the query params
    const extractedStageId = queryParams.get("stageId"); // Get the 'stageId'

    if (extractedStageId) {
      setStageId(extractedStageId); // Set stageId in state
    }
  }, [location.search]); // Run the effect whenever the query params change

  useEffect(() => {
    getAllData();
    return () => {
      // stopRecordings();
      // if (socketRef.current) {
      //   socketRef.current.disconnect();
      // }
    };
  }, [stageId]);

  // console.log("view button", viewButton);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      {!startTest ? (
        <>
          {viewSuccess && (
            <SuccessRoundMessage
              message={SuccessAndFailureMessage?.round2?.success}
              gotoRound={"/home"}
              onclose={setViewSuccess}
              next={"Home"}
              isRound5={true}
            />
          )}
          {viewFail && (
            <FailureMessage
              reapply={reapplydate}
              onclose={setViewFail}
              message={SuccessAndFailureMessage?.round2?.failure}
            />
          )}
          <CandidateHeader />
          {viewProctoringScreen ? (
            <PreProctoringScreenRound2
              setViewProctoringScreen={setViewProctoringScreen}
              startRecordings={startRecordings}
              viewPretest={viewPretest}
              setViewPretest={setViewPretest}
              setStartTest={setStartTest}
              resumeStatus={resumeStatus}
              stageId={stageId}
            />
          ) : (
            <>
              {stages && (
                <CandidateSubHeader passedData={passedData} stages={stages} />
              )}
              <RoundStartOrComplete
                isRound2Clear={isRound2Clear}
                setStartTest={setStartTest}
                isRound2Failed={isRound2Failed}
                resumeStatus={resumeStatus}
                viewButton={viewButton}
                isTerminated={isTerminated}
                setViewProctoringScreen={setViewProctoringScreen}
                setViewPretest={setViewPretest}
                viewPretest={viewPretest}
                stageId={stageId}
                stages={stages}
              />
              <RoundInstructions
                roundInstructions={
                  candidateRole === "Data_Science"
                    ? round2InstructionsDataScience
                    : instructions
                }
              />
            </>
          )}
        </>
      ) : (
        <StartTestRound2
          setStartTest={setStartTest}
          startTest={startTest}
          checkForPassedRound={checkForPassedRound}
          resumeStatus={resumeStatus}
          setViewLoader={setViewLoader}
          setmultipleSwitchError={setmultipleSwitchError}
          setViewHoldMessage={setViewHoldMessage}
          handleViewHold={handleViewHold}
          candidateRole={candidateRole}
          stopRecordings={stopRecordings}
          stageId={stageId}
        />
      )}
      {multipleSwitchError && (
        <MultipleTabSwitchError
          setmultipleSwitchError={setmultipleSwitchError}
        />
      )}
      {viewHoldMessage && <HoldOnR2 />}
    </>
  );
};

const RoundStartOrComplete = ({
  isRound2Clear,
  setStartTest,
  isRound2Failed,
  resumeStatus,
  viewButton,
  isTerminated,
  setViewProctoringScreen,
  stageId,
  stages,
  // setViewPretest,
  // viewPretest,
}) => {
  // console.log(isRound1Clear);
  // const [viewPretest, setViewPretest] = useState(false);
  const matchingStage = stages?.stages?.find(
    (stage) => stage?.stageId === stageId
  );
  return (
    <>
      <div className="roundStartOrComplete">
        <div>{resumeStatus?.totalTime} Mins</div>
        {/* <div>Coding Challenge</div> */}
        {matchingStage && <div> {matchingStage?.stageName}</div>}
        <div></div>
        <div>
          Solve a series of coding problems and showcase your coding aptitude.
        </div>
        {isTerminated && (
          <div className="failed">
            Pause alert! Unethical antics crashed the assessment party. Contact
            support for a quick comeback plan.
          </div>
        )}
        {viewButton ? (
          isRound2Failed?.status ? null : isRound2Clear ? (
            <div className="completed">Completed</div>
          ) : (
            <div>
              {/* <button onClick={() => setViewPretest(true)}> */}
              <button onClick={() => setViewProctoringScreen(true)}>
                {/* <button onClick={() => setStartTest(true)}> */}
                {resumeStatus?.numOfQuestionsAttempted > 0
                  ? "Resume"
                  : "Start the Test"}
              </button>
            </div>
          )
        ) : null}
      </div>
      {/* {viewPretest && (
        <BeforeStartInstructions
          setViewPretest={setViewPretest}
          setStartTest={setStartTest}
          resumeStatus={resumeStatus}
        />
      )} */}
    </>
  );
};

const initialQues = {
  averageMark: 0,
  averageTime: null,
  constraints: null,
  description: null,
  evaluation_Function: null,
  example: null,
  function_Signature: null,
  id: null,
  message: null,
  qnNum: null,
  question: null,
  testCases: null,
  testcaseMarks: null,
};

const StartTestRound2 = ({
  setStartTest,
  checkForPassedRound,
  resumeStatus,
  setViewLoader,
  setmultipleSwitchError,
  // setViewHoldMessage,
  handleViewHold,
  candidateRole,
  stopRecordings,
  stageId,
  startTest,
}) => {
  // const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timer, setTimer] = useState(null);
  const timerRef = useRef(timer);
  const [question, setQuestion] = useState({ ...initialQues });
  const [answer, setAnswer] = useState("");
  const [avgTime, setAvgTime] = useState("15 minutes");
  const [questionStartAtTime, setQuestionStartAtTime] = useState(null);
  const [error, setError] = useState({});
  // const [viewHoldMessage, setViewHoldMessage] = useState(false);
  const [viewInstructions, setViewInstructions] = useState(false);
  const [viewUi, setViewUi] = useState(false);
  // const [languagesOptions, setLanguagesOptions] = useState([
  //   // {
  //   //   key: "C",
  //   //   value: "C",
  //   // },
  //   {
  //     key: "C++",
  //     value: "C++",
  //   },
  //   {
  //     key: "Java",
  //     value: "java",
  //   },
  //   {
  //     key: "JavaScript",
  //     value: "javascript",
  //   },
  //   {
  //     key: "Python",
  //     value: "python",
  //   },
  // ]);
  // const [language, setLanguage] = useState({
  //   key: "Java",
  //   value: "java",
  // });
  const defaultLanguages = [
    { key: "C++", value: "C++" },
    { key: "Java", value: "java" },
    { key: "JavaScript", value: "javascript" },
    { key: "Python", value: "python" },
  ];
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [language, setLanguage] = useState({});
  const [questionConfig, setQuestionConfig] = useState({
    ...initialQuestionConfig,
  });
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [switchCount, setSwitchCount] = useState(0);
  const [statusInterval, setStatusInterval] = useState(null);
  const [viewTabSwitch, setViewTabSwitch] = useState(false);
  const [viewHoldForNextQuestion, setViewHoldForNextQuestion] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const clientRef = useRef(null);

  const formatTime = (timer) => {
    const minutes = Math.floor(timer / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (timer % 60).toString().padStart(2, "0");
    return `${minutes}m:${seconds}s`;
  };

  // const formatAvgTime = (timer) => {
  //   const minutes = Math.floor(timer / 60)
  //     .toString()
  //     .padStart(2, "0");
  //   const seconds = (timer % 60).toString().padStart(2, "0");
  //   return `${minutes}:${seconds}`;
  // };
  const saveRatingAndStatus = async () => {
    try {
      // Delay for 15 seconds
      await new Promise((resolve) => setTimeout(resolve, 15000));
      const stageData = await getUserCustomTestFormat();
      const data = {
        name: localStorage.getItem("email"),
        clientId: stageData.clientId,
        stageId: stageId,
        role: stageData.roleId,
      };
      const endpoint = "/candidate/round2/status/rating";
      const res = await axios.post(endpoint, data);

      const data2 = {
        id: localStorage.getItem("email"),
        status: true,
        stageId: stageId,
        clientId: stageData.clientId,
      };
      const endpoint2 = "/candidate/round2/status/save/status";
      const res2 = await axios.post(endpoint2, data2);
      // console.log("response of saving round2", res?.data);
      // console.log("response of saving round2 status", res?.data);
      return;
    } catch (err) {
      console.log("err in saving rating and status==>", err);
    }
  };

  // const handleViewHold = async () => {
  //   setStartTest(false);
  //   setViewHoldMessage(true);
  //   setTimeout(async () => {
  //     setViewHoldMessage(false);
  //     // await saveRatingAndStatus();
  //     // navigate("/assessment/home");
  //     // setStartTest(false);
  //     checkForPassedRound();
  //   }, 21000);
  // };

  const getQuestionForR2 = async (questionConfig = questionConfig) => {
    if (currentQuestion === questionConfig?.totalQuestions) {
      setQuestion({});
      setTimer(null);
      await handleCloseScheduler();
      clearAllOldIntervals();
      handleViewHold();
      const res = await saveRatingAndStatus();
      console.log("after saveRatingAndStatus==>", res);
      stopRecordings();
      checkForPassedRound();
    } else {
      try {
        // current question is 0 based while fetching questions
        console.log("current question==>", currentQuestion);
        console.log("questionConfig==>", questionConfig);
        const endpoint =
          candidateRole === "Data_Science"
            ? "/candidate/round2/datascience/get"
            : "/candidate/round2/question/get";
        const stageData = await getUserCustomTestFormat();

        // Initialize variables for cumulative question count and selected topic
        let cumulativeQuestions = 0;
        let selectedTopic = "";

        // Iterate through the topics to find the correct one
        for (let i = 0; i < questionConfig.topics.length; i++) {
          // Add the totalTopicQuestions for the current topic
          cumulativeQuestions += questionConfig.topics[i].totalTopicQuestions;

          // Check if currentQuestion falls within the current topic's range
          if (currentQuestion < cumulativeQuestions) {
            selectedTopic = questionConfig.topics[i].topic;
            break;
          }
        }

        const data = {
          // name: sessionStorage.getItem("email"),
          name: localStorage.getItem("email"),
          clientId: stageData.clientId,
          stageId: stageId,
          role: stageData.roleId,
          topic: selectedTopic,
          // topic: "Integer Array",
        };
        const res = await axios.post(endpoint, data);
        console.log("res?.data for question first==>", res?.data);
        if (res?.data) {
          if (!("message" in res?.data) || res?.data?.message === null) {
            console.log("res?.data for question second==>", res?.data);
            setQuestion({ ...res.data });
            setCurrentQuestion((prev) => prev + 1);
            setQuestionStartAtTime(timer);
            setAvgTime(res?.data?.averageTime);

            if (!statusInterval) {
              handleStartIntervalForStatus();
              handleApiCallForActiveStatus();
            }
          } else {
            console.log("res?.data for question==>", res?.data);
            setQuestion({});
            setTimer(null);
            // await handleCloseScheduler();
            clearAllOldIntervals();
            handleViewHold();
            const res = await saveRatingAndStatus();
            console.log("after saveRatingAndStatus 2==>", res);
            stopRecordings();
            checkForPassedRound();
          }
        }
      } catch (err) {
        console.log("error in getQuestionForR2", err);
      } finally {
        setDisableNextButton(false);
        setViewLoader(false);
        setViewUi(true);
        setViewHoldForNextQuestion(false);
      }
    }
  };

  const getRound2Config = async () => {
    try {
      const endpoint = "/candidate/round2/getConfig/questionConfig";
      const stageData = await getUserCustomTestFormat();
      // Find the stage with stageType 'mcq'
      const codingStage = stageData.stages.find(
        (stage) => stage.stageId === stageId
      );

      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
        clientId: stageData.clientId,
        stageId: stageId,
        role: stageData.roleId,
      };
      const res = await axios.post(endpoint, data);

      // console.log("config round2=>", res);
      if (res?.data) {
        console.log(
          "resumeStatus?.numOfQuestionsAttempted=>",
          resumeStatus?.numOfQuestionsAttempted
        );
        resumeStatus?.numOfQuestionsAttempted > 0 &&
          setCurrentQuestion(resumeStatus?.numOfQuestionsAttempted - 1);

        setQuestionConfig({ ...res?.data });

        const mappedOptions =
          res.data?.languagesAllowed && Array.isArray(res.data.languagesAllowed)
            ? res.data.languagesAllowed.map((value) => ({
                key: value,
                value: value.toLowerCase(),
              }))
            : defaultLanguages;

        setLanguagesOptions(mappedOptions);
        setLanguage(mappedOptions[0]);

        const totalTime = res?.data?.totalTimeInMins
          ? res?.data?.totalTimeInMins
          : 0;

        const maxTime = 5400;
        const minTime = 0;

        // resumeStatus?.remainingTime > minTime &&
        // resumeStatus?.remainingTime < maxTime
        //   ? setTimer(convertMinutesToSeconds(resumeStatus?.remainingTime))
        //   : setTimer(totalTime * 60);

        resumeStatus?.remainingTime > minTime &&
        resumeStatus?.remainingTime < maxTime
          ? setTimer(resumeStatus?.remainingTime)
          : setTimer(totalTime * 60);

        // setTimer(1 * 60);
        getQuestionForR2(res?.data);
      }
    } catch (err) {
      console.log("rouind2 config error", err);
    }
  };

  function timeToDecimal(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const decimal = remainingSeconds / 60;
    return parseFloat((minutes + decimal).toFixed(2)); // This will convert the string back to a floating point number
  }

  const handleSubmitCode = async () => {
    setError({});
    // console.log(
    //   "time taken to submit",
    //   questionStartAtTime,
    //   timer,
    //   questionStartAtTime - timer
    // );
    try {
      console.log("saving question number=>", currentQuestion);

      // Initialize variables for cumulative question count and selected topic
      let cumulativeQuestions = 0;
      let selectedTopic = "";

      // Iterate through the topics to find the correct one
      for (let i = 0; i < questionConfig.topics.length; i++) {
        // Add the totalTopicQuestions for the current topic
        cumulativeQuestions += questionConfig.topics[i].totalTopicQuestions;

        // Check if currentQuestion falls within the current topic's range
        if (currentQuestion <= cumulativeQuestions) {
          selectedTopic = questionConfig.topics[i].topic;
          break;
        }
      }

      setViewHoldForNextQuestion(true);
      setDisableNextButton(true);
      let timeTaken;
      if (questionStartAtTime === null && timer != null) {
        let startedAt = questionConfig?.totalTimeInMins * 60;
        // console.log("1", startedAt - timer);
        timeTaken = timeToDecimal(startedAt - timer);
      } else {
        // console.log("2", questionStartAtTime - timer);
        timeTaken = timeToDecimal(questionStartAtTime - timer);
      }
      const stageData = await getUserCustomTestFormat();

      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
        clientId: stageData.clientId,
        stageId: stageId,
        role: stageData.roleId,
        topic: selectedTopic,
        code: answer,
        questionId: question?.qnNum,
        language: language.key,
        devTime: timeTaken,
        // devTime: 4,
        skip: false,
      };
      const endpoint =
        candidateRole === "Data_Science"
          ? "/candidate/round2/datascience/submitCode"
          : "/candidate/round2/question/response/submitCode";
      // console.log("body==>", data);
      const res = await axios.post(endpoint, data);

      // console.log("response handleSubmitCode ==>", res);
      // setError({ ...res?.data });
      getQuestionForR2(questionConfig);
    } catch (err) {
      console.log("handleSubmitCode error ==>", err);
    } finally {
    }
  };

  const enterFullScreen = () => {
    document.documentElement.requestFullscreen().catch((e) => {
      console.error("Error attempting to enable full-screen mode:", e);
    });
  };

  const toggleFullScreen = (val) => {
    try {
      if (val === "remove") {
        if (document?.exitFullscreen) {
          document?.exitFullscreen();
        }
      } else if (!document?.fullscreenElement) {
        document?.documentElement?.requestFullscreen();
        setShowPopup(false);
      } else {
        if (document?.exitFullscreen) {
          document?.exitFullscreen();
        }
      }
    } catch (err) {
      console.log("error in toggleFullScreen==>", err);
    }
  };

  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setShowPopup(true);
      setIsFullscreen(false);
    } else {
      setIsFullscreen(true);
    }
  };

  // detecting tab switching
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // Tab switched out or window minimized
      setSwitchCount((prevCount) => prevCount + 1);
    }
  };

  // function to tell backend that the ui is active and session is active
  // this is periodically called in some given time interval
  const handleApiCallForActiveStatus = async (tabSwitched = false, timer) => {
    return;
    try {
      // const email = sessionStorage.getItem("email");
      const email = localStorage.getItem("email");
      const text = tabSwitched ? "tabSwitch" : "normal-ping";
      // console.log("timer left==>", timer);
      const lastAbortTimestamp =
        timer && timer < 5400
          ? timer
          : resumeStatus?.remainingTime
          ? resumeStatus?.remainingTime
          : 5400;
      const body = {
        email,
        text,
        lastAbortTimestamp,
      };
      // console.log("before calling status api ==>", body);
      const endpoint = "/candidate/round2/proctoring/status/active";
      const res = await axios.post(endpoint, body);
      console.log("handleApiCallForActiveStatus success ==>", res);
    } catch (err) {
      console.log("handleApiCallForActiveStatus error ===>", err);
      setViewLoader(true);
      setStartTest();
      await handleCloseScheduler();
      clearAllOldIntervals();
      await checkForPassedRound();
      setViewLoader(false);
    }
  };

  const handleCloseScheduler = async () => {
    return;
    try {
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
      };
      const endpoint = "/candidate/round2/proctoring/status/close";
      const res = await axios.post(endpoint, data);
      console.log("handleCloseScheduler success ==>", res);
    } catch (err) {
      console.log("handleCloseScheduler error ===>", err);
    }
  };

  // Function to stop the test due to multiple tab switches or other criteria
  const stopTest = () => {
    setStartTest(false); // Set state to stop the test
    setmultipleSwitchError(true); // Set error state indicating multiple tab switches
    stopRecordings();
    checkForPassedRound(); // Check if the current round is passed and handle it accordingly
  };

  // Function to initialize the WebSocket connection
  const initializeWebSocket = () => {
    // Create a new STOMP client using SockJS to establish a WebSocket connection
    console.log("socket connection to backend is started");
    clientRef.current = new Client({
      webSocketFactory: () => new SockJS(proctorSocketUrl), // SockJS URL for WebSocket connection
      reconnectDelay: 5000, // Reconnect delay in milliseconds if the connection is lost
      debug: (str) => {
        console.log("web socket connection log=>", str); // Log WebSocket connection events for debugging
      },
    });

    // Define what happens when the WebSocket connection is established
    clientRef.current.onConnect = (frame) => {
      console.log("Connected to WebSocket");
      console.log("websocket connection details:", frame);

      // Subscribe to the '/topic/terminate' endpoint to listen for termination messages from the backend
      clientRef.current.subscribe("/topic/terminate", (message) => {
        if (message.body === "terminate") {
          console.log("Test terminated by the backend");
          stopTest();
          // setExamTerminated(true); // Set the exam as terminated if the message body is 'terminate'
        }
      });
    };

    // Activate the WebSocket connection
    clientRef.current.activate();
  };

  // this will call handleApiCallForActiveStatus function every 50 sec to ping backend that the ui is active
  const handleStartIntervalForStatus = () => {
    // Retrieve and parse the intervals from sessionStorage
    let allIntervalsOld = JSON.parse(
      sessionStorage.getItem("allIntervals") || "[]"
    );

    // Clear all stored intervals
    allIntervalsOld.forEach((item) => {
      clearInterval(item);
    });

    // Set the new interval
    const statusIntervalName = setInterval(() => {
      console.log("interval executed==>", statusIntervalName);
      handleApiCallForActiveStatus(false, timerRef.current);
    }, 25000);

    // Store the new interval ID in sessionStorage
    allIntervalsOld = [statusIntervalName];
    sessionStorage.setItem("allIntervals", JSON.stringify(allIntervalsOld));

    // Optionally set the interval ID in the component's state
    setStatusInterval(statusIntervalName);
  };

  // Function to publish a tab switch message to the server
  const publishTabSwitchMessage = async () => {
    console.log("Publishing tab switch message");
    const data = await getSessionDetails();
    console.log("data for tab switch: ", data);
    if (clientRef.current) {
      clientRef.current.publish({
        destination: "/app/tab-switch", // Endpoint for tab-switch event
        body: JSON.stringify(data),
      });
      console.log("Tab switch message sent to server");
    } else {
      console.error(
        "WebSocket client not initialized for sending message to tab switch"
      );
    }
  };

  const getSessionDetails = async () => {
    // This function returns session details; replace with actual data fetching logic
    const stageData = await getUserCustomTestFormat();
    console.log("got session details", stageData);
    const stageType = stageData.stages.find(
      (stage) => stage.stageId === stageId
    )?.stageType;
    return {
      candidateId: localStorage.getItem("email"), // Candidate email
      clientId: stageData.clientId, // Client ID
      // sessionId: sessionId, // Session ID
      // sessionId: localStorage.getItem("email"), // Session ID
      stageType: stageType, // Type of the exam stage
      stageId: stageId, // stage id is already in the component
    };
  };

  // Function to handle the browser close event
  const handleBeforeUnload = async (event) => {
    // Publish a message to the server indicating that the browser is about to close
    console.log("Browser is about to close event triggered");
    const data = await getSessionDetails();
    console.log("data before unload: ", data);
    clientRef.current.publish({
      destination: "/app/browser-close", // Endpoint for browser-close event
      body: JSON.stringify(data),
    });
  };

  const handleBlur = () => {
    // Check if the document (tab) is hidden
    console.log("Tab lost focus");

    // Increment the tab switch count when the tab is switched
    setSwitchCount((prevCount) => {
      const newCount = prevCount + 1;
      return newCount; // Return the new tab switch count
    });

    // Publish a message to the server indicating a tab switch event
    publishTabSwitchMessage();
  };

  // Generate a UUID for session ID when the component mounts
  useEffect(() => {
    const newSessionId = uuidv4(); // Generate a new UUID
    setSessionId(newSessionId); // Set the generated UUID as session ID
    console.log(`Generated Session ID: ${newSessionId}`);
  }, []);

  useEffect(() => {
    if (sessionId) {
      setViewLoader(true);
      initializeWebSocket();
      enterFullScreen();
      getRound2Config();

      // fullcreen
      document.addEventListener("fullscreenchange", handleFullScreenChange);
      // visibility change ==> tabb switch
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("blur", handleBlur);
      // counter for tab switch
      // !statusInterval && handleStartIntervalForStatus();
      // handleApiCallForActiveStatus();
    }
    return () => {
      toggleFullScreen("remove");
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(statusInterval);
    };
  }, [sessionId]);

  // useEffect(() => {
  //   getQuestionForR2();
  // }, [questionConfig]);

  useEffect(() => {
    timerRef.current = timer;

    // If timer is not zero, set up interval to decrement
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timer === 0) {
      // When timer reaches zero, call the handleSubmit function
      handleSubmitCode();
    }

    // Clear interval on unmount or when timer reaches zero
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    // console.log(language);
    const index = question?.function_Signature?.findIndex(
      (item) => item?.language?.toLowerCase() === language?.key?.toLowerCase()
    );
    // console.log(
    //   index,
    //   question?.function_Signature?.[index]?.functionSignature
    // );
    if (index >= 0) {
      // console.log("changing answer");
      setAnswer(question?.function_Signature?.[index]?.functionSignature);
    } else {
      setAnswer(null);
    }
  }, [question]);

  useEffect(() => {
    if (switchCount > 0) {
      console.log("tab switched", switchCount);
      // handleApiCallForActiveStatus(true, timer);
      /*
      increasong this number as test should not terminate on tab count 3 , 
      will need to reduce this to 3 in future
     */
      if (switchCount >= 300) {
        // handleCloseScheduler();
        clearAllOldIntervals();
        if (startTest) {
          // setStartTest(false);
          // setmultipleSwitchError(true);
          // checkForPassedRound();
          stopTest();
        }
      } else {
        setViewTabSwitch(true);
      }
    }
  }, [switchCount]);

  useEffect(() => {
    if (candidateRole === "Data_Science") {
      setLanguagesOptions([
        {
          key: "Python",
          value: "python",
        },
      ]);
    } else {
      // Reset to the default options if the role is not 'ds'
      setLanguagesOptions([
        {
          key: "C++",
          value: "C++",
        },
        {
          key: "Java",
          value: "java",
        },
        {
          key: "JavaScript",
          value: "javascript",
        },
        {
          key: "Python",
          value: "python",
        },
      ]);
    }

    if (candidateRole === "Data_Science") {
      setLanguage({
        key: "Python",
        value: "python",
      });
    } else {
      setLanguage({
        key: "Java",
        value: "java",
      });
    }
  }, [candidateRole]);

  return (
    <>
      {viewInstructions && (
        <InstructionsUi
          setViewInstructions={setViewInstructions}
          betweenTestInstructions={betweenTestInstructions}
        />
      )}
      {viewUi && (
        <div className="startedRound1Container">
          <div className="generalDetails">
            <div
              className="instructions"
              onClick={() => setViewInstructions(true)}
            >
              {/* <BackToHome /> */}
              Instructions
              <DescriptionOutlinedIcon />
            </div>
            <div className="questionNumber">
              <span>
                {currentQuestion < 10 && currentQuestion !== 0
                  ? `0${currentQuestion}`
                  : currentQuestion}
              </span>
              <span>/</span>
              <span>{questionConfig?.totalQuestions}</span>
            </div>
            <div className="clock">
              <div>
                <img src={blueClock} alt="" />
              </div>
              <div className="time">
                {" "}
                <span>{formatTime(timer)}</span>
              </div>
            </div>
          </div>
          <div className="timeAndLang">
            <div className="avgTime">
              {/* <span> {formatAvgTime(avgTime)}</span> (Average time to solve this problem) */}
              <span> {avgTime}</span> (Average time to solve this problem)
            </div>
            <div className="languageWrapper">
              <div>Language</div>
              <DropDownBox
                placeholder="language"
                options={languagesOptions}
                value={language}
                handleChange={setLanguage}
              />
            </div>
          </div>
          <QuestionAndSolution
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            question={question}
            questionConfig={questionConfig}
            setQuestion={setQuestion}
            answer={answer}
            setAnswer={setAnswer}
            language={language}
            getQuestionForR2={getQuestionForR2}
            error={error}
            setError={setError}
            handleSubmitCode={handleSubmitCode}
            disableNextButton={disableNextButton}
            candidateRole={candidateRole}
            stageId={stageId}
          />
          {/* {viewHoldMessage && <HoldOnR2 />} */}
          {showPopup && (
            <FullScreenExited
              toggleFullScreen={toggleFullScreen}
              fullScreenExit={fullScreenExit}
            />
          )}
          {viewTabSwitch && <TabSwitchUi setViewTabSwitch={setViewTabSwitch} />}
          {viewHoldForNextQuestion && <HoldForNextQuestion />}
        </div>
      )}
    </>
  );
};

const DropDownBox = ({ placeholder, options, value, handleChange }) => {
  const [viewOptions, setViewOptions] = useState(false);
  const [languageOptions, setLanguageOptions] = useState([...options]);
  const componentRef = useRef(null);

  const handleModifyOptions = (item) => {
    let temp = [];
    options.forEach((language) => {
      console.log(`Setting language==>`, language?.value === item?.value);
      if (language?.value !== item?.value) {
        console.log(language);
        temp.push(language);
      }
    });
    console.log("temp===>", temp);
    setLanguageOptions([...temp]);
  };

  const dropDownChange = (item) => {
    handleModifyOptions(item);
    setViewOptions(false);
    handleChange(item);
  };

  useEffect(() => {
    const item = {
      key: "Java",
      value: "java",
    };
    handleModifyOptions(item);
  }, []);

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div className="placeholder" onClick={() => setViewOptions(!viewOptions)}>
        <div className={value === "" ? "" : "value"}>
          {value?.key === "" || value === null ? placeholder : value?.key}
        </div>
        <div>
          <img
            src={arrowDown}
            alt=""
            style={{ rotate: viewOptions ? "180deg" : "0deg" }}
          />
        </div>
      </div>
      {viewOptions && (
        <div className="options-wrapper">
          {languageOptions?.map((item, index) => (
            <div key={index} onClick={() => dropDownChange(item)}>
              {item?.key}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const QuestionAndSolution = ({
  currentQuestion,
  setCurrentQuestion,
  question,
  questionConfig,
  setQuestion,
  answer,
  setAnswer,
  language,
  error,
  setError,
  handleSubmitCode,
  disableNextButton,
  candidateRole,
  stageId,
}) => {
  const [sizes, setSizes] = useState([200, "30%", "auto"]);
  const [viewHoldMessage, setViewHoldMessage] = useState(false);
  const [viewExecuteAllCases, setViewExecuteAllCases] = useState(false);
  const [alltestCasesResult, setAllTestCasesResult] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const layoutCSS = {
    height: "100%",
    overflowY: "auto",
    marginTop: "20px",
  };

  const handleRunCodeTestCase = async (testCase) => {
    setError({});
    setViewHoldMessage(true);

    try {
      const stageData = await getUserCustomTestFormat();
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
        code: answer,
        questionId: question?.qnNum,
        language: language.key,
        input: testCase.input,
        expectedOutput: testCase.expectedOutput,
        clientId: stageData.clientId,
        stageId: stageId,
        role: stageData.roleId,
      };
      // /response/single/testcase
      // const endpoint = "/candidate/round2/question/response/single/testcase";
      const endpoint =
        candidateRole === "Data_Science"
          ? "/candidate/round2/datascience/single-test-case"
          : "/candidate/round2/question/response/single/testcase";
      // console.log("body==>", data);
      const res = await axios.post(endpoint, data);
      // console.log("response handleRunCodeTestCase ==>", res);
      setViewHoldMessage(false);
      setError({ ...res?.data });
    } catch (err) {
      setViewHoldMessage(false);
      console.log("handleRunCodeTestCase error ==>", err);
    }
  };

  const handleRunAllTestCases = async () => {
    try {
      setViewExecuteAllCases(true);
      const stageData = await getUserCustomTestFormat();
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
        code: answer,
        questionId: question?.qnNum,
        language: language.key,
        clientId: stageData.clientId,
        stageId: stageId,
        role: stageData.roleId,
      };
      // const endpoint = "/candidate/round2/question/response/all-test-cases";
      const endpoint =
        candidateRole === "Data_Science"
          ? "/candidate/round2/datascience/all-test-cases"
          : "/candidate/round2/question/response/all-test-cases";
      const res = await axios.post(endpoint, data);
      if (res.data) {
        console.log("response handleRunCodeTestCase ==>", res);
        setViewExecuteAllCases(false);
        setAllTestCasesResult(true);
        setPercentage(res?.data?.testCasePassPercentage);
      }
    } catch (err) {
      console.log("handleRunAllTestCases error ==>", err);
    } finally {
      setViewExecuteAllCases(false);
    }
  };

  return (
    <>
      <div
        style={{ height: "80vh", overflowY: "auto" }}
        // style={{ height: "fit-content", minHeight: "80vh", overflowY: "auto" }}
        className="split-container"
      >
        {question?.question && (
          <SplitPane
            split="vertical"
            sizes={sizes}
            onChange={setSizes}
            style={{
              scrollbarWidth: "none" /* For Firefox */,
              msOverflowStyle: "none" /* For Internet Explorer and Edge */,
            }}
          >
            <Pane
              minSize="30%"
              maxSize="70%"
              style={{
                scrollbarWidth: "none" /* For Firefox */,
                msOverflowStyle: "none" /* For Internet Explorer and Edge */,
              }}
            >
              <div
                style={{
                  ...layoutCSS,
                  background: "inherit",
                  scrollbarWidth: "none" /* For Firefox */,
                  msOverflowStyle: "none" /* For Internet Explorer and Edge */,
                }}
                className="right-container"
              >
                <RightSideData
                  data={question}
                  currentQuestion={currentQuestion}
                  handleRunCodeTestCase={handleRunCodeTestCase}
                  handleRunAllTestCases={handleRunAllTestCases}
                  // candidateRole={candidateRole}
                />
              </div>
            </Pane>
            <div
              style={{
                ...layoutCSS,
              }}
              className="left-container"
            >
              <LeftSideEditor
                data={question}
                answer={answer}
                setAnswer={setAnswer}
                language={language}
                error={error}
                handleSubmitCode={handleSubmitCode}
                questionConfig={questionConfig}
                currentQuestion={currentQuestion}
                viewHoldMessage={viewHoldMessage}
                disableNextButton={disableNextButton}
              />
            </div>
          </SplitPane>
        )}
      </div>
      {viewExecuteAllCases && (
        <ViewAllCasesExecution
          setViewExecuteAllCases={setViewExecuteAllCases}
        />
      )}
      {alltestCasesResult && (
        <AllTestCasesResponse
          percentage={percentage}
          setPercentage={setPercentage}
          setAllTestCasesResult={setAllTestCasesResult}
        />
      )}
    </>
  );
};

const RightSideData = ({
  data,
  currentQuestion,
  handleRunCodeTestCase,
  handleRunAllTestCases,
  // candidateRole,
}) => {
  const [selectedTestCase, setSelectedTestCase] = useState(0);
  const [viewDescription, setViewDescription] = useState(true);

  const handleRunCode = () => {
    let element = document.getElementById("console");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    handleRunCodeTestCase(data?.testCases[selectedTestCase]);
  };

  return (
    <div className="right-wrapper">
      <div className="questionAndDetails">
        <div
          className="question-no"
          onClick={() => setViewDescription(!viewDescription)}
        >
          <div>
            <div>Question</div>
            <div>{currentQuestion}:</div>
          </div>
          <div>
            <img
              src={arrowDown}
              alt=""
              style={{ rotate: viewDescription ? "180deg" : "0deg" }}
            />
          </div>
        </div>
        {viewDescription && (
          <>
            <div className="question">{data?.question}</div>
            <div className="description">Description</div>
            <div className="question">{data?.description}</div>
            <div className="description">Constraints</div>
            <div className="question">{data?.constraints}</div>
            <div className="description">Example</div>
            <div className="question">
              {/* {data?.example?.map((item, index) => (
                <div key={index} className="examples">
                  <div> Input:{item?.input}</div>
                  <div> Output:{item?.output}</div>
                </div>
              ))} */}
              <div className="examples">
                <div> Input:{data?.example?.input}</div>
                <div> Output:{data?.example?.output}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="test-cases-heading">
        <div className="testCase">Test Cases</div>
        <div className="all-test-cases" onClick={() => handleRunAllTestCases()}>
          Execute All Test Cases
        </div>
      </div>
      <div className="textcase-wrapper">
        <div className="each-testcase">
          {data?.testCases?.map(
            (item, index) =>
              item?.visibility !== "Hidden" && (
                <div
                  key={index}
                  onClick={() => setSelectedTestCase(index)}
                  className={`${selectedTestCase === index ? "active" : ""}`}
                >
                  Test case {index + 1}
                </div>
              )
          )}
        </div>
        <div className="inputAndOutput">
          Input : <span> {data?.testCases?.[selectedTestCase]?.input}</span>
        </div>
        <div className="inputAndOutput">
          Expected Output :{" "}
          <span className="output">
            {" "}
            {data?.testCases?.[selectedTestCase]?.expectedOutput}
          </span>
        </div>
        <div className="runCode">
          <button onClick={() => handleRunCode()}>Run Code</button>
        </div>
      </div>
    </div>
  );
};

const LeftSideEditor = ({
  data,
  answer,
  setAnswer,
  language,
  error,
  handleSubmitCode,
  questionConfig,
  currentQuestion,
  viewHoldMessage,
  disableNextButton,
}) => {
  const [viewReset, setViewReset] = useState(false);
  const [viewSubmitCode, setViewSubmitCode] = useState(false);
  const [viewGoToNextQuestion, setViewGoToNextQuestion] = useState(false);

  const languageSignatureFunction = () => {
    // setAnswer(data?.function_Signature?.[0]?.functionSignature);
    // console.log(language);
    const index = data?.function_Signature?.findIndex(
      (item) => item?.language.toLowerCase() === language?.key.toLowerCase()
    );
    // console.log(index, data?.function_Signature?.[index]?.functionSignature);
    if (index >= 0) {
      // console.log("changing answer");
      setAnswer(data?.function_Signature?.[index]?.functionSignature);
    } else {
      setAnswer({});
    }
  };

  useEffect(() => {
    languageSignatureFunction();
  }, [language]);

  return (
    <>
      <div className="left-wrapper">
        <div className="editorAndConsole">
          <div className="editorContainer">
            {language?.value && (
              <AceEditor
                // mode={`ace/mode/${language}`}
                mode={
                  language?.value === "C" || language?.value === "C++"
                    ? "c_cpp"
                    : language?.value
                }
                theme="textmate"
                onChange={(value) => setAnswer(value)}
                name="code-editor"
                editorProps={{ $blockScrolling: true }}
                value={answer}
                className="my-custom-editor"
                style={{ width: "100%", height: "100%", fontSize: "15px" }}
              />
            )}
          </div>
          <div className="consoleOutput" id="console">
            <div>Console</div>
            {!viewHoldMessage ? (
              <div
                style={{
                  border: error?.status?.description
                    ? error?.status?.description === "Accepted"
                      ? ""
                      : "0.6px solid #FF0000"
                    : "",
                }}
              >
                <div>
                  <span>Description:</span>
                  <span
                    className="response"
                    style={{
                      color:
                        error?.status?.description === "Accepted"
                          ? "#0BB762"
                          : "#FF0000",
                    }}
                  >
                    {" "}
                    {error?.status?.description}
                  </span>
                </div>
                <div>
                  <span>Standard Output:</span> <span> {error?.stdout}</span>
                </div>
                <div>
                  <span>Standard Error:</span>
                  <span> {error?.stderr}</span>
                </div>
                <div>
                  <span>Output: </span>
                  <span> {error?.compile_output}</span>
                </div>
              </div>
            ) : (
              <div className="loader-window">
                <div>
                  <CircularProgress />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bottomButtons">
          <div className="submit">
            <div className="submitButton">
              {" "}
              <button onClick={() => setViewSubmitCode(true)}>
                Submit code
              </button>
            </div>
            <div className="reset" onClick={() => setViewReset(true)}>
              <div>
                <img src={resetSvg} alt="" />
              </div>
              <div>Reset code</div>
            </div>
          </div>
          {currentQuestion < questionConfig?.totalQuestions && (
            <div className="nextQuestion">
              <button
                style={{ opacity: disableNextButton ? 0.7 : 1 }}
                disabled={disableNextButton}
                // onClick={() => handleSubmitCode()}
                onClick={() => setViewGoToNextQuestion(true)}
              >
                Skip To Next Question
              </button>
            </div>
          )}
        </div>
      </div>
      {viewReset && (
        <ResetCode
          setViewReset={setViewReset}
          languageSignatureFunction={languageSignatureFunction}
        />
      )}
      {viewSubmitCode && (
        <SubmitCode
          setViewSubmitCode={setViewSubmitCode}
          handleSubmitCode={handleSubmitCode}
        />
      )}
      {viewGoToNextQuestion && (
        <GoNextQuestion
          setViewGoToNextQuestion={setViewGoToNextQuestion}
          handleSubmitCode={handleSubmitCode}
        />
      )}
    </>
  );
};

const ResetCode = ({ setViewReset, languageSignatureFunction }) => {
  return (
    <Popup>
      <div className="resetMain">
        <div className="close" onClick={() => setViewReset(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="title1">Reset Code</div>
        <div className="title2">
          Your code will be discarded and reset to the default code! Do you want
          to reset the code?
        </div>
        <div className="last">
          <button onClick={() => setViewReset(false)}>Cancel</button>
          <button
            onClick={() => {
              setViewReset(false);
              languageSignatureFunction();
            }}
          >
            Reset Code
          </button>
        </div>
      </div>
    </Popup>
  );
};

const SubmitCode = ({ setViewSubmitCode, handleSubmitCode }) => {
  return (
    <Popup>
      <div className="resetMain">
        <div className="close" onClick={() => setViewSubmitCode(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="title1">Submit Code</div>
        <div className="title2">
          Are you sure you want to submit the code? Once submitted, you can’t
          undo this action.
        </div>
        <div className="last">
          <button onClick={() => setViewSubmitCode(false)}>Cancel</button>
          <button
            onClick={() => {
              setViewSubmitCode(false);
              handleSubmitCode();
            }}
          >
            Submit Code
          </button>
        </div>
      </div>
    </Popup>
  );
};

const GoNextQuestion = ({ setViewGoToNextQuestion, handleSubmitCode }) => {
  return (
    <Popup>
      <div className="resetMain">
        <div className="close" onClick={() => setViewGoToNextQuestion(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="title1">Skip To Next Question </div>
        <div className="title2">
          Are you sure you would like to skip this question & move to next
          question.
        </div>
        <div className="last">
          <button onClick={() => setViewGoToNextQuestion(false)}>Cancel</button>
          <button
            onClick={() => {
              setViewGoToNextQuestion(false);
              handleSubmitCode();
            }}
          >
            Skip To Next Question
          </button>
        </div>
      </div>
    </Popup>
  );
};

const ViewAllCasesExecution = () => {
  return (
    <Popup style={{ marginTop: "30%" }}>
      <div>
        <img src={executeAllCasesImage} alt="" />
      </div>
    </Popup>
  );
};
const HoldForNextQuestion = () => {
  return (
    <Popup style={{ marginTop: "30%" }}>
      <div>
        <img src={holdToNextQuestion} alt="" />
      </div>
    </Popup>
  );
};

const AllTestCasesResponse = ({
  percentage = 80,
  setPercentage,
  setAllTestCasesResult,
}) => {
  const [rotationStyle, setRotationStyle] = useState({
    transform: " rotate(0deg);",
    transition: "transform 1s ease", // Animation duration and easing
  });

  let imageIndex = 0;
  let textColor = "#212121";

  if (percentage >= 0 && percentage < 50) {
    imageIndex = 0; // Show image1
    textColor = "#DE3A3B";
  } else if (percentage >= 50 && percentage < 100) {
    imageIndex = 2; // Show image3
    textColor = "#C79E0D";
  } else if (percentage === 100) {
    imageIndex = 4; // Show image5
    textColor = "#0BB762";
  }

  const handleClose = () => {
    setPercentage(0);
    setAllTestCasesResult(false);
  };

  useEffect(() => {
    // Calculate the rotation degree based on the percentage
    const rotationDegree = percentage * 2.31;

    // Update the rotation style with the new rotation degree
    setRotationStyle({
      transform: `rotate(${rotationDegree}deg)`,
      transition: "transform 1s ease",
    });
  }, []);

  return (
    <Popup style={{ marginTop: "30%" }}>
      <div className="all-cases-wrapper">
        <div className="close" onClick={() => handleClose()}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="meter">
          <div className="clock">
            <img
              src={percentageImages[imageIndex]}
              alt={`Image ${imageIndex + 1}`}
            />
          </div>
          <div className="needle" style={rotationStyle}>
            {imageIndex + 1 < percentageImages.length && (
              <img
                src={percentageImages[imageIndex + 1]}
                alt={`Image ${imageIndex + 2}`}
              />
            )}
          </div>
        </div>
        <div className="text">
          <span style={{ color: textColor }}>
            {`${percentage + "%" + " "}`}{" "}
          </span>
          <span style={{ color: textColor }}> Test case Pass!</span>
        </div>
        <div className="okay">
          <button onClick={() => handleClose()}>okay</button>
        </div>
      </div>
    </Popup>
  );
};
