import React, { useState, useEffect } from "react";
import {
  Button,
  InputBase,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { BackButton } from "../../Common/BackButton/BackButton";
import styles from "./HrInterviewConfig.module.scss";
import { toast } from "react-toastify";
import { GlobalLoader } from "../../Common/GlobalLoader/GlobalLoader";
import axios from "axios";

const HrInterviewConfigComponent = ({
  roundName,
  setFormData,
  formData,
  roundIndex,
  setCurrentConfig,
  allConfigsData,
  setAllConfigsData,
  stageId,
  isEditable,
}) => {
  const [manualScheduling, setManualScheduling] = useState(false);
  const [interviewerName, setInterviewerName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [meetingDuration, setMeetingDuration] = useState("");
  const [errors, setErrors] = useState({});
  const [configRecieved, setConfigRecieved] = useState();
  const [loading, setLoading] = useState(false);

  const prefillData = (response) => {
    console.log("data received: ", response);
    // Set manualScheduling based on whether the interview type is not "behavioral"
    setManualScheduling(
      // response?.interviewType?.toLowerCase() !== "softSkillsInterview"
      response?.manualScheduling || false
    );
    setInterviewerName(response?.interviewerName);
    setEmailId(response?.interviewerEmail);
    setMeetingDuration(response?.meetingDuration);
  };
  // API function to fetch stage data based on stageId
  const fetchStageData = async (stageId) => {
    try {
      setLoading(true);
      // console.log("formData", formData);
      // Extract clientId and role from local storage and state
      const clientId = localStorage.getItem("clientId");
      const role = formData?.roleName; // Set role as per backend request

      // Construct the URL with query parameters
      const url = `/erp/client/role/softSkills/config/get?clientId=${clientId}&stageId=${stageId}&role=${encodeURIComponent(
        role
      )}`;

      // Make the GET request
      const response = await axios.get(url);

      // Set the received configuration data
      setConfigRecieved(response.data);
      prefillData(response.data);
    } catch (error) {
      console.error("Error fetching stage data:", error);
      throw error; // Rethrow if you want to handle it in the component
    } finally {
      setLoading(false);
    }
  };

  // Prefill data if any config exists
  useEffect(() => {
    if (stageId) {
      fetchStageData(stageId);
    } else {
      const roundConfig = formData?.rounds?.[roundIndex]?.config;
      if (roundConfig) {
        setManualScheduling(roundConfig.manualScheduling || false);
        setInterviewerName(roundConfig.interviewerName || "");
        setEmailId(roundConfig.emailId || "");
        setMeetingDuration(roundConfig.meetingDuration || "");
      }
    }
  }, [formData, roundIndex, stageId]);

  // Handle manual scheduling radio change
  const handleSchedulingChange = (event) => {
    setManualScheduling(event.target.value === "manual");
    if (event.target.value === "manual") {
      // Clear interviewer details if manual scheduling is selected
      setInterviewerName("");
      setEmailId("");
      setMeetingDuration("");
    }
  };

  // Handle form submission (save the config and call API)
  const handleSave = async () => {
    // Validation
    if (
      !manualScheduling &&
      (!interviewerName || !emailId || !meetingDuration)
    ) {
      setErrors({
        interviewerName: !interviewerName ? "Interviewer Name is required" : "",
        emailId: !emailId ? "Email ID is required" : "",
        meetingDuration: !meetingDuration ? "Meeting duration is required" : "",
      });
      return;
    }

    // Reset errors
    setErrors({});

    // Prepare the HR interview config object
    const hrConfig = {
      manualScheduling,
      interviewerName,
      emailId,
      meetingDuration,
    };

    // Save the HR interview configuration to the parent component's formData
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        rounds: prev.rounds.map((round, index) =>
          index === roundIndex ? { ...round, config: hrConfig } : round
        ),
      };

      // Set all configs data (optional for further processing)
      setAllConfigsData((prevData) => ({
        ...prevData,
        hrConfig: updatedFormData,
      }));

      return updatedFormData;
    });

    // toast.success("HR Interview Config saved successfully!");

    // Optionally, reset currentConfig or handle UI updates
    setCurrentConfig(null);
  };

  const handleBackButton = () => {
    setCurrentConfig(null);
  };

  return (
    <>
      {loading && <GlobalLoader />}
      <div className={styles.hrInterviewConfigContainer}>
        <BackButton handleBackButton={handleBackButton} name={"Back"} />
        {/* Header */}
        <div className={styles.headerRow}>
          <h2 className={styles.configTitle}>Set Configuration</h2>
          <div className={styles.roundDetails}>
            <div>
              Role Name:<span> {formData?.roleName}</span>
            </div>
            <div>
              Round Name: <span>{roundName}</span>
            </div>
            <div>
              Round Type:{" "}
              <span>
                {" "}
                {formData?.rounds?.[roundIndex]?.type?.toLowerCase() ===
                "softskillsinterview"
                  ? "Softskills Interview"
                  : ""}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.detailsContains}>
          {/* Radio Buttons for Scheduling Option */}
          <RadioGroup
            className={styles.radioGroup}
            value={manualScheduling ? "manual" : "auto"}
            onChange={handleSchedulingChange}
            disabled={!isEditable}
          >
            <FormControlLabel
              disabled={!isEditable}
              value="auto"
              control={<Radio />}
              label="Allow candidate to schedule the slots"
              className={styles.radioOption}
            />
            <FormControlLabel
              disabled={!isEditable}
              value="manual"
              control={<Radio />}
              label="Allow manual scheduling"
              className={styles.radioOption}
            />
          </RadioGroup>

          {/* Conditionally show interviewer details based on scheduling option */}
          {!manualScheduling && (
            <div className={styles.formRow}>
              {/* Interviewer Name */}
              <FieldWrapper
                label="Interviewer Name"
                error={errors.interviewerName}
              >
                <InputBase
                  disabled={!isEditable}
                  placeholder="Interviewer Name"
                  value={interviewerName}
                  onChange={(e) => setInterviewerName(e.target.value)}
                  className={styles.inputField}
                />
              </FieldWrapper>

              {/* Email ID */}
              <FieldWrapper label="Email ID" error={errors.emailId}>
                <InputBase
                  disabled={!isEditable}
                  placeholder="Email ID"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  className={styles.inputField}
                />
              </FieldWrapper>

              {/* Meeting Duration */}
              <FieldWrapper
                label="Meeting duration (in mins)"
                error={errors.meetingDuration}
              >
                <InputBase
                  disabled={!isEditable}
                  placeholder="Enter meeting duration"
                  value={meetingDuration}
                  onChange={(e) => setMeetingDuration(e.target.value)}
                  className={styles.inputField}
                />
              </FieldWrapper>
            </div>
          )}

          {/* Note for manual scheduling */}
          {!manualScheduling && (
            <p className={styles.note}>
              Note: In this case, interviewer has to set availability in their
              calendar.
            </p>
          )}

          {/* Save Button */}
          {isEditable && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className={styles.saveButton}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

// FieldWrapper Component for input label and error handling
const FieldWrapper = ({ label, error, children }) => {
  return (
    <div className={styles.fieldWrapper}>
      <label className={styles.fieldLabel}>{label}</label>
      {children}
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default HrInterviewConfigComponent;
