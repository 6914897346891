import { AllRoutes } from "./AllRoutes/AllRoutes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Override console.log and console.error
// only uncommenting for qa - do not push in prod
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

// Create a theme instance
const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AllRoutes />
      </ThemeProvider>
    </>
  );
}

export default App;
