import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Menu,
  InputAdornment,
  Pagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import styles from "./AllRolesList.module.scss";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem } from "@mui/material"; // Import MenuItem from Material-UI

// Main Component
export const AllRolesList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");

  // Extract page number from URL query parameters
  const getPageFromQuery = () => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    return page ? parseInt(page, 10) : 1; // Default to page 1 if not present
  };

  // State for current page, roles data, and total items
  const [page, setPage] = useState(getPageFromQuery);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(); // Total items (this can come from an API call)
  const itemsPerPage = 10; // Fixed items per page

  // Calculate total pages based on total items and items per page
  const [totalPages, setTotalPages] = useState(null);

  function convertData(inputData) {
    return inputData.map((item) => {
      // Convert the created_at to the desired format
      const creationDate = new Date(item.created_at)
        .toISOString()
        .split("T")[0];

      // Return the new format along with the original key-value pairs
      return {
        roleName: item.roleName,
        businessUnit: item.businessUnit,
        creationDate: creationDate,
        rounds: item.numberOfRounds,
        // Append extra data with original key-value pairs
        id: item.id,
        clientId: item.clientId,
        hiringProcessId: item.hiringProcessId,
        description: item.description,
        created_at: item.created_at, // Keeping the original field as well
        uniqueRoleId: item?.uniqueRoleId || "",
      };
    });
  }

  // Simulate an API call to fetch roles data
  const fetchRoles = async (page) => {
    setLoading(true);
    try {
      const data = {
        clientId: localStorage.getItem("clientId"), // make this dynamic when actual ID is received
        ...(searchValue && { searchRole: searchValue }), // Add searchRole only if searchValue has data
      };

      const urlEndpoint = `/erp/client/role/list?pageNumber=${
        page - 1
      }&pageSize=${itemsPerPage}`;
      const response = await axios.post(urlEndpoint, data);

      const convertedData = convertData(response.data?.content);
      setRoles([...convertedData]);
      setTotalItems(response?.data?.totalElements);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch roles whenever the page changes
  useEffect(() => {
    fetchRoles(page);
  }, [page]);

  useEffect(() => {
    fetchRoles(1);
  }, [searchValue]);

  // Handle page change and update URL query parameters and state
  const handlePageChange = (newPage) => {
    setPage(newPage);
    navigate(`?page=${newPage}`);
  };

  return (
    <PageLayout>
      <RolesPage
        roles={roles}
        loading={loading}
        page={page}
        onPageChange={handlePageChange}
        totalPages={totalPages} // Pass total pages to Pagination
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </PageLayout>
  );
};

// RolesPage Component
const RolesPage = ({
  roles,
  loading,
  page,
  onPageChange,
  totalPages,
  setSearchValue,
  searchValue,
}) => {
  return (
    <div className={styles.rolesPage}>
      <h1 className={styles.title}>Roles</h1>
      <SearchAndCreateRow
        setSearchValue={setSearchValue}
        searchValue={searchValue}
      />
      <RolesTable roles={roles} loading={loading} />
      <PaginationComponent
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </div>
  );
};

// SearchAndCreateRow Component
const SearchAndCreateRow = ({ searchValue, setSearchValue }) => {
  const navigate = useNavigate();
  const handleNewRole = () => {
    navigate("/roles/new");
  };

  return (
    <div className={styles.actionRow}>
      <TextField
        variant="outlined"
        placeholder="Search by name"
        value={searchValue} // Set value to state
        onChange={(e) => setSearchValue(e.target.value)} // Update state on input change
        className={styles.searchField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        className={styles.createRoleButton}
        onClick={() => handleNewRole()}
      >
        Create New Role
      </Button>
    </div>
  );
};

// RolesTable Component
const RolesTable = ({ roles, loading }) => {
  // Table headers stored in state
  const [headers] = useState([
    { label: "Role Name", key: "roleName" },
    { label: "Business Unit", key: "businessUnit" },
    { label: "Creation Date", key: "creationDate" },
    { label: "No. of Rounds", key: "rounds" },
    { label: "", key: "view" }, // For View Candidates (Empty Header)
    { label: "", key: "edit" }, // For Edit (Empty Header)
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = React.useState(null);

  const handleDropdownToggle = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdownIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenDropdownIndex(null);
  };

  const navigate = useNavigate();

  // Show loading spinner while data is fetching
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleEditRole = (role) => {
    // console.log("role: ", role);
    // return;
    navigate("/edit/role/", {
      state: {
        name: role.roleName,
        businessUnit: role.businessUnit,
        clientId: role.clientId,
        uniqueRoleId: role?.uniqueRoleId || "",
        edit: true,
      },
    });
  };
  // Ensure you have this function defined in your code
  const handleViewCandidates = (url, role) => {
    // console.log("handleViewCandidates", url, role);
    navigate(url, {
      state: {
        hiringProcessId: role.hiringProcessId,
        businessUnit: role.businessUnit,
        roleName: role.roleName,
        idOfRole: role.id,
      },
    });
  };

  return (
    <TableContainer className={styles.tableContainer}>
      <Table>
        <TableHead className={styles.tableHead}>
          <TableRow>
            {/* Mapping headers from state */}
            {headers.map((header, index) => (
              <TableCell key={index}>{header.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {/* Mapping roles with conditional checks */}
          {roles.map((role, index) => (
            <TableRow
              key={index}
              className={index % 2 === 0 ? styles.evenRow : styles.oddRow}
            >
              {/* Role Name */}
              <TableCell>{role?.roleName || "-"}</TableCell>

              {/* Business Unit */}
              <TableCell>{role?.businessUnit || "-"}</TableCell>

              {/* Creation Date */}
              <TableCell>{role?.creationDate || "-"}</TableCell>

              {/* No. of Rounds */}
              <TableCell>{role?.rounds || "-"}</TableCell>

              {/* View Invited Candidates */}
              <TableCell>
                <IconButton
                  className={styles.dropdownContainer}
                  onClick={(event) => handleDropdownToggle(event, index)}
                >
                  View Candidates <KeyboardArrowDownIcon />
                </IconButton>
                <Menu
                  anchorEl={openDropdownIndex === index ? anchorEl : null}
                  open={openDropdownIndex === index}
                  onClose={handleMenuClose}
                  className={styles.dropdownMenu}
                >
                  <MenuItem
                    onClick={() =>
                      handleViewCandidates("/roles/list/invited", role)
                    }
                  >
                    Invited Candidates
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleViewCandidates("/roles/list/signup", role)
                    }
                  >
                    Signed Up Candidates
                  </MenuItem>
                </Menu>
              </TableCell>

              {/* Edit Role */}
              <TableCell>
                <IconButton
                  color="secondary"
                  className={styles.editIcon}
                  onClick={() => handleEditRole(role)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// PaginationComponent Component
const PaginationComponent = ({ page, onPageChange, totalPages }) => {
  // console.log("totalPages=>", totalPages);
  return (
    <Pagination
      count={totalPages} // Set the max number of pages
      page={page}
      onChange={(e, value) => onPageChange(value)}
      color="primary"
      className={styles.pagination}
      sx={paginationStyles}
    />
  );
};

const paginationStyles = {
  "& .MuiPaginationItem-root": {
    border: "1px solid #DFE3E8", // Grey border for all numbers
    color: "#AEAEAE", // Grey color for all numbers
    margin: "0 5px", // Spacing between pagination items
    borderRadius: "5px",
  },
  "& .Mui-selected": {
    backgroundColor: "#fff", // Blue background for selected number
    color: "#062B6E", // White text for selected number
    border: "1px solid #062B6E", // Blue border for selected number
    borderRadius: "5px",
  },
  "& .MuiPaginationItem-previousNext": {
    color: "#212121", // Blue arrow color for previous/next buttons
    borderRadius: "5px",
    padding: "6px",
  },
};
