export const candidateSubHeaderDataExported = {
  topHeader: [
    { key: "Round1", value: "Multiple Choice Questions", stageKey: "mcq" },
    {
      key: "Round2",
      value: "Coding",
      stageKey: "coding",
    },
    {
      key: "Round3",
      value: "HR Discussion",
      stageKey: "hrInterview",
    },
    {
      key: "Round4",
      value: "Practical Assignment",
      value2: "Case Study",
      stageKey: "assignment",
    },
    {
      key: "Round5",
      value: "Expert Discussion",
      stageKey: "discussion",
    },
  ],
};
