import styles from "./SubHeaderRoutes.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import screen1 from "../../../Assets/dev-recording/screen1.svg";
import screen2 from "../../../Assets/dev-recording/screen2.svg";
import recording1 from "../../../Assets/dev-recording/video1.svg";
import recording2 from "../../../Assets/dev-recording/video2.svg";

export const SubHeaderRoutes = ({ selectedIndex, setSelectedIndex }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const screen_path = `/dev-recording/screen?user=${queryParams.get("user")}`;

  const video_path = `/dev-recording/video?user=${queryParams.get("user")}`;

  return (
    <div className={styles["sub-header-routes"]}>
      <SubHeaderRouteButton
        path={"/dev-recording/screen"}
        setSelectedIndex={setSelectedIndex}
        fullPath={screen_path}
        title={"Screen Recording"}
        imgs={[screen1, screen2]}
      />
      <SubHeaderRouteButton
        path={"/dev-recording/video"}
        setSelectedIndex={setSelectedIndex}
        fullPath={video_path}
        title={"Video Recording"}
        imgs={[recording1, recording2]}
      />
    </div>
  );
};

const SubHeaderRouteButton = ({
  path,
  setSelectedIndex,
  fullPath,
  title,
  imgs,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubHeaderButton = (path, index) => {
    setSelectedIndex(index);
    navigate(path, { state: location.state });
  };
  return (
    <span
      className={`${styles["nav-link"]} ${
        location.pathname === path ? styles["active"] : ""
      }`}
      onClick={() => handleSubHeaderButton(fullPath, 1)}
    >
      <img src={location.pathname === path ? imgs[0] : imgs[1]} alt={title} />
      <span>{title}</span>
    </span>
  );
};
