import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Collapse,
  Tooltip,
} from "@mui/material";
import {
  addDays,
  subDays,
  format,
  startOfDay,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import "./TimeLineMetrix.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { CircularProgress } from "@mui/material";


export const TimeLineMetrix = () => {
  const [loading, setLoading] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(() => {
    // Calculate start and end of the current week dynamically
    const today = new Date();
    return {
      startDate: startOfWeek(today, { weekStartsOn: 1 }), // Monday as the start of the week
      endDate: endOfWeek(today, { weekStartsOn: 1 }), // Sunday as the end of the week
    };
  });
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);

  const handlePrevWeek = () => {
    setIsCurrentWeek(false);
    setCurrentWeek((prev) => ({
      startDate: subDays(prev.startDate, 7),
      endDate: subDays(prev.endDate, 7),
    }));
  };

  const handleNextWeek = () => {
    setCurrentWeek((prev) => {
      const newStartDate = addDays(prev.startDate, 7);
      const newEndDate = addDays(prev.endDate, 7);

      // Check if the newEndDate is greater than or equal to today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const isCurrent = newEndDate >= today;

      // Update the state to indicate whether it's the current week
      setIsCurrentWeek(isCurrent);

      return {
        startDate: newStartDate,
        endDate: newEndDate,
      };
    });
  };

  const [data, setCandidateData] = useState([
    {
      title: "Java Developer",
      startDay: "Mon",
      endDay: "Fri",
      candidates: 50,
      completedCandidates: 30,
      recentDate:"",
      firstDate:"",
      subData: [
        {
          title: "TFT",
          startDay: "Mon",
          endDay: "Tue",
          candidates: 40,
          completedCandidates: 25,
          recentDate:"",
          firstDate:"",
        },
        {
          title: "Coding Challenge",
          startDay: "Tue",
          endDay: "Thu",
          candidates: 12,
          completedCandidates: 8,
          recentDate:"",
        firstDate:"",
        },
        {
          title: "HR Assessment",
          startDay: "Wed",
          endDay: "Fri",
          candidates: 8,
          completedCandidates: 6,
        },
      ],
    },
    {
      title: "Data Scientists",
      startDay: "Tue",
      endDay: "Fri",
      candidates: 15,
      completedCandidates: 5,
      subData: [
        {
          title: "Data Cleaning",
          startDay: "Tue",
          endDay: "Wed",
          candidates: 10,
          completedCandidates: 3,
        },
        {
          title: "Model Evaluation",
          startDay: "Wed",
          endDay: "Fri",
          candidates: 5,
          completedCandidates: 2,
        },
      ],
    },
    {
      title: "Backend Developer",
      startDay: "Mon",
      endDay: "Wed",
      candidates: 20,
      completedCandidates: 12,
      subData: [
        {
          title: "API Development",
          startDay: "Mon",
          endDay: "Tue",
          candidates: 10,
          completedCandidates: 8,
        },
        {
          title: "Database Design",
          startDay: "Tue",
          endDay: "Wed",
          candidates: 10,
          completedCandidates: 4,
        },
      ],
    },
    {
      title: "Python Developer",
      startDay: "Wed",
      endDay: "Fri",
      candidates: 10,
      completedCandidates: 4,
      subData: [
        {
          title: "Scripting",
          startDay: "Wed",
          endDay: "Thu",
          candidates: 5,
          completedCandidates: 3,
        },
        {
          title: "Data Analysis",
          startDay: "Thu",
          endDay: "Fri",
          candidates: 5,
          completedCandidates: 1,
        },
      ],
    },
    {
      title: "Frontend Developer",
      startDay: "Thu",
      endDay: "Sat",
      candidates: 10,
      completedCandidates: 6,
      subData: [
        {
          title: "UI Design",
          startDay: "Thu",
          endDay: "Fri",
          candidates: 6,
          completedCandidates: 4,
        },
        {
          title: "Responsive Testing",
          startDay: "Fri",
          endDay: "Sat",
          candidates: 4,
          completedCandidates: 2,
        },
      ],
    },
    {
      title: "DevOps Engineer",
      startDay: "Mon",
      endDay: "Thu",
      candidates: 25,
      completedCandidates: 15,
      subData: [
        {
          title: "CI/CD Pipeline",
          startDay: "Mon",
          endDay: "Tue",
          candidates: 15,
          completedCandidates: 10,
        },
        {
          title: "Infrastructure Setup",
          startDay: "Tue",
          endDay: "Thu",
          candidates: 10,
          completedCandidates: 5,
        },
      ],
    },
    {
      title: "AI Engineer",
      startDay: "Wed",
      endDay: "Fri",
      candidates: 18,
      completedCandidates: 9,
      subData: [
        {
          title: "Model Training",
          startDay: "Wed",
          endDay: "Thu",
          candidates: 10,
          completedCandidates: 6,
        },
        {
          title: "Hyperparameter Tuning",
          startDay: "Thu",
          endDay: "Fri",
          candidates: 8,
          completedCandidates: 3,
        },
      ],
    },
    {
      title: "Full Stack Developer",
      startDay: "Mon",
      endDay: "Fri",
      candidates: 30,
      completedCandidates: 20,
      subData: [
        {
          title: "Frontend Integration",
          startDay: "Mon",
          endDay: "Tue",
          candidates: 15,
          completedCandidates: 10,
        },
        {
          title: "Backend Setup",
          startDay: "Tue",
          endDay: "Thu",
          candidates: 15,
          completedCandidates: 10,
        },
      ],
    },
  ]);

  // Generate weekdays dynamically based on the current week
  const weekdays = [];
  const weekDaysDate=[];
  for (let i = 0; i < 7; i++) {
    const date = addDays(currentWeek.startDate, i);
    weekdays.push(format(date, "EEE d")); // Example: "Mon 2"
  }

  const convertDataToFormat = (data) => {
    console.log("weekdays,", currentWeek);
    const daysMapping = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    return data.jobDetails.map((job) => {
      const createdDate = new Date(job.createdAt);
      const startDay = daysMapping[createdDate.getDay()]; // Get the day from createdAt
      const endDay = "Fri"; // Fixed end day for now

      // Calculate completedCandidates as the sum of all passedCandidates
      const completedCandidates = job.roundDetails.reduce(
        (sum, round) => sum + round.passedCandidates,
        0
      );

      // Transform roundDetails into subData
      const subData = job.roundDetails.map((round) => {
        return {
          title: round.roundName,
          startDay: startDay, // Use parent's startDay
          endDay: endDay, // Fixed end day
          candidates: round.totalCandidates,
          completedCandidates: round.passedCandidates,
          ...round, // Keep other data as it is
        };
      });

      return {
        title: job.roleName, // Use the roleName for title
        startDay: startDay, // Day of createdAt
        endDay: endDay, // Fixed end day
        candidates: job.totalInvitedCandidates,
        completedCandidates: completedCandidates,
        subData: subData, // Transformed subData
      };
    });
  };

  const prefillTimeLineData = (data) => {
    const modifiedData = convertDataToFormat(data);
    // console.log("modifiedData", modifiedData);
    // console.log("originalData", data);
    setCandidateData([...modifiedData]);
  };

  const formatWeekDates = (week) => {
    if (!week?.startDate || !week?.endDate) return [];

    // Get today's date at 12:00:00
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure it's set to midnight for comparison

    // Adjust startDate to the Monday of the week
    const adjustedStartDate = startOfWeek(new Date(week.startDate), {
      weekStartsOn: 1, // Week starts on Monday
    });

    // Adjust endDate to Sunday of the same week, but cap it at today's date
    let adjustedEndDate = endOfWeek(adjustedStartDate, { weekStartsOn: 1 });
    if (adjustedEndDate > today) {
      adjustedEndDate = today; // Cap the end date at today
    }

    // Helper function to format date as "YYYY-MM-DDTHH:mm:ss"
    const formatDate = (date, time) => {
      const datePart = date.toISOString().split("T")[0]; // Extract YYYY-MM-DD
      return `${datePart}T${time}`; // Concatenate with time
    };

    const startDate = formatDate(adjustedStartDate, "00:00:00"); // Start of the day
    const endDate = formatDate(adjustedEndDate, "00:00:00"); // End of the day or capped to today

    return [startDate, endDate];
  };

  const result = formatWeekDates(currentWeek);
  console.log(result);

  // Output Example (if today is "2024-12-18"):
  // [
  //   "2024-12-16T00:00:00", // Monday start date
  //   "2024-12-18T00:00:00"  // Capped at today
  // ]

  const getTimelineData = async () => {
    const url = "/erp/client/dashboard/timeline";
    const [startDate, endDate] = formatWeekDates(currentWeek);

    if (!startDate || !endDate) {
      console.error("Invalid date range.");
      return;
    }

    const data = {
      clientId: localStorage.getItem("clientId"),
      startDate,
      endDate,
    };

    try {
      setLoading(true);
      const response = await axios.post(url, data);

      console.log("API Response:", response.data);
     // prefillTimeLineData(response.data);
    } catch (error) {
      console.error("Error fetching timeline data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentWeek?.startDate) getTimelineData();
  }, [currentWeek]);

  return (
    <Box className="time-line-metrix">
      {/* Week Selection Bar */}
      <WeekNavigator
        currentWeek={currentWeek}
        onPrevWeek={handlePrevWeek}
        onNextWeek={handleNextWeek}
        isCurrentWeek={isCurrentWeek}
      />

      {/* Header Row */}
      <HeaderRow weekdays={weekdays} />

      {/* Data Rows */}
      {loading ? (
        <div className="circular-loader">
          <CircularProgress />
        </div>
      ) : (
        data.map((item, index) => (
          <ExpandableRow key={index} item={item} weekdays={weekdays} />
        ))
      )}
    </Box>
  );
};

const WeekNavigator = ({
  currentWeek,
  onPrevWeek,
  onNextWeek,
  isCurrentWeek,
}) => (
  <Box className="week-navigator-container">
    {/* Timeline on the left with fixed width */}
    <Box
      sx={{
        width: "200px", // Fixed width for the timeline
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start", // Align content to the left within the timeline box
      }}
    >
      <Typography variant="body1" className="timeline-container">
        Timeline
      </Typography>
    </Box>

    {/* Week Navigator Buttons */}
    <Box className="week-navigator-buttons">
      <Button onClick={onPrevWeek} className="arrow-button">
        <ArrowBackIosIcon />
      </Button>
      <Typography variant="body1" className="button-content">
        {`${format(currentWeek.startDate, "MMM d")} - ${format(
          currentWeek.endDate,
          "MMM d, yyyy"
        )}`}
      </Typography>
      <Button
        disabled={isCurrentWeek}
        onClick={onNextWeek}
        className="arrow-button"
        sx={{
          opacity: isCurrentWeek ? ".5" : "1", // Text color
        }}
      >
        <ArrowForwardIosIcon />
      </Button>
    </Box>
  </Box>
);

const HeaderRow = ({ weekdays }) => (
  <Grid container alignItems="center" className="header-row-container">
    {/* Job Roles Section */}
    <Grid item xs={3} className="header-job-roles">
      <Typography variant="body1" className="job-roles-text">
        Job Roles
      </Typography>
    </Grid>

    {/* Candidates Section */}
    <Grid item xs={1} className="header-candidates">
      <Typography
        sx={{
          textAlign: "left", // Ensure text aligns to the left
        }}
        variant="body1"
        className="candidates-text"
      >
        Candidates
      </Typography>
    </Grid>

    <Grid
      item
      xs={8}
      container
      justifyContent="space-between"
      className="header-weekdays"
    >
      {weekdays.map((day, index) => (
        <Typography
          key={index}
          variant="body1"
          className="weekday-text"
          sx={{
            flex: 1, // Equal width for all days
            textAlign: "center",
            // borderRight:
            //   index !== weekdays.length - 1 ? "1px solid grey" : "none", // Border-right except for the last day
          }}
        >
          {day}
        </Typography>
      ))}
    </Grid>
  </Grid>
);

const ExpandableRow = ({ item, weekdays }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <Box className="expandable-row-container">
      {/* Main Row */}
      <Grid
        container
        className="main-row"
        style={{ borderBottom: isExpanded ? "1px solid #e0e0e0" : "" }}
      >
        <Grid item xs={3} className="main-row-title">
          <Button onClick={toggleExpand} className="expand-button">
            {item.subData.length > 0 && (
              <Typography className="expand-icon">
                {isExpanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </Typography>
            )}
            {item.title}
          </Button>
        </Grid>
        <Grid item xs={1} className="main-row-candidates">
          <Typography variant="body2" className="candidates-text">
            {item.candidates}
          </Typography>
        </Grid>
        <Grid item xs={8} className="main-row-progress-bar">
          <ProgressBar
            startDay={item.startDay}
            endDay={item.endDay}
            candidates={item.candidates}
            completedCandidates={item.completedCandidates}
            weekdays={weekdays}
          />
        </Grid>
      </Grid>

      {/* Sub Rows (if expanded) */}
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        className="sub-rows-container"
      >
        {item.subData.map((subItem, index) => (
          <Grid
            container
            alignItems="center"
            key={index}
            className="sub-row"
            style={{
              borderBottom:
                isExpanded && index !== item?.subData?.length - 1
                  ? "1px solid #E0E0E0"
                  : "none",
            }}
          >
            <Grid item xs={3} className="sub-row-title">
              <Typography variant="body2" className="sub-row-title-text">
                {subItem.title}
              </Typography>
            </Grid>
            <Grid item xs={1} className="sub-row-candidates">
              <Typography variant="body2" className="sub-row-candidates-text">
                {subItem.candidates}
              </Typography>
            </Grid>
            <Grid item xs={8} className="sub-row-progress-bar">
              <ProgressBar
                startDay={subItem.startDay}
                endDay={subItem.endDay}
                candidates={subItem.candidates}
                completedCandidates={subItem.completedCandidates}
                weekdays={weekdays}
              />
            </Grid>
          </Grid>
        ))}
      </Collapse>
    </Box>
  );
};

const ProgressBar = ({
  startDay,
  endDay,
  candidates,
  completedCandidates=40,
  rejectedCandidates=50,
  unattemptedCanddates = 10,
  weekdays,
}) => {
  // Start and End positions for the grid
  const startIndex = weekdays.findIndex((day) => day.includes(startDay));
  const endIndex = weekdays.findIndex((day) => day.includes(endDay));

  // Ensure valid indices
  const gridStart = 2//startIndex >= 0 ? startIndex + 1 : 1; // Dynamic grid start
  const gridEnd = 4//endIndex >= 0 ? endIndex + 2 : 8; // Dynamic grid end (exclusive)

  // Calculate percentages
  const completionPercentage = (completedCandidates / candidates) * 100 || 0;
  const rejectedPercentage = (rejectedCandidates / candidates) * 100 || 0;
  const unAttemptedPercentage = (unattemptedCanddates / candidates) * 100 || 0;

  return (
    <Box className="progress-bar-container">
      {/* Border Grid for reference */}
     
      <div className="border-container">
        {Array.from({ length: 7 }).map((_, index) => (
          <div key={index}></div>
        ))}
      </div>

      {/* Second Div spans dynamically */}
      <div
        className="second-div"
        // style={{
        //   gridColumn: `${gridStart} / ${gridEnd}`, // Dynamic grid span
        // }}
      >
        {/* Nested Divs */}
        <div
          className="inner-div dark-blue"
          style={{ flex: completionPercentage }}
        ></div>
        <div
          className="inner-div light-blue"
          style={{ flex: rejectedPercentage }}
        ></div>
        <div
          className="inner-div grey"
          style={{ flex: unAttemptedPercentage }}
        ></div>
      </div>
    </Box>
  );
};



const TooltipContent = ({
  candidates = 0,
  completed = 0,
  passedCandidates = 0,
  rejectedCandidates = 0,
  scheduledCandidates = 0,
  startDate = "Today",
}) => {
  const completionPercentage =
    candidates > 0 ? ((completed / candidates) * 100).toFixed(0) : 0;

  return (
    <Box>
      {/* Completion Percentage */}
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {completionPercentage}% Completion
      </Typography>

      {/* Start Date */}
      <Typography variant="body2">Started: {startDate}</Typography>

      {/* Candidates and Decisions */}
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        <Typography
          variant="body2"
          sx={{ display: "flex", alignItems: "center", mr: 2 }}
        >
          ➡ {candidates} Candidates
        </Typography>
        <Typography
          variant="body2"
          sx={{ display: "flex", alignItems: "center" }}
        >
          ✔ {completed} Decisions Made
        </Typography>
      </Box>

      {/* Additional Info */}
      <Typography variant="body2">
        Moved to next stage: {passedCandidates}
      </Typography>
      <Typography variant="body2">Rejected: {rejectedCandidates}</Typography>
      <Typography variant="body2">Scheduled: {scheduledCandidates}</Typography>
    </Box>
  );
};
