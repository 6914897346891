import { BackButton } from "../../Components/Common/BackButton/BackButton";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import styles from "./InvitedCandidateForRole.module.scss";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const InvitedCandidateForRole = () => {
  return (
    <PageLayout>
      <InvitedCandidateList />
    </PageLayout>
  );
};

const InvitedCandidateList = () => {
  // Retrieve the state passed during navigation
  const location = useLocation();
  const { hiringProcessId, businessUnit, roleName, idOfRole } =
    location.state || {};
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(40); // Total items (this can come from an API call)
  const itemsPerPage = 10; // Fixed items per page
  // Calculate total pages based on total items and items per page
  const [totalPages, setTotalPages] = useState(0);
  const [candidates, setCandidates] = useState([]);

  // Implement back button functionality
  const handleBackButton = () => {
    // Example: navigate to the previous page
    window.history.back();
  };

  // Handle page change and update URL query parameters and state
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Separate function to fetch candidates
  const fetchCandidates = async (pageNumber, pageSize) => {
    try {
      const urlEndpoint = `/erp/client/role/get/list/invited?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const data = {
        invitedByUserId: localStorage.getItem("clientId"),
        roleId: roleName,
      };
      const response = await axios.post(urlEndpoint, data);

      // Extract data from response
      // const candidatesData = response.data?.candidates || [];
      // const totalItemsCount = response.data?.totalItems || 0;

      // Update state with response data
      setCandidates(response.data?.content);
      // setTotalItems(totalItemsCount);

      // Calculate total pages based on total items and items per page
      // const calculatedTotalPages = Math.ceil(totalItemsCount / itemsPerPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  };

  // Fetch candidates whenever the page changes
  useEffect(() => {
    // Call the separate fetch function with updated page number
    fetchCandidates(page - 1, itemsPerPage);
  }, [page]);

  return (
    <div className={styles.invitedCandidateListWrapper}>
      <BackButton handleBackButton={handleBackButton} name={"Back"} />
      <h1 className={styles.title}>Invited candidates</h1>
      <UserTable tableData={candidates} />
      <PaginationComponent
        page={page}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  );
};

const UserTable = ({ tableData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  return (
    <div className={styles.userTableWrapper}>
      {" "}
      {/* Wrapper added here */}
      <TableContainer className={styles.userTableContainer}>
        <Table className={styles.userTable}>
          <TableHead>
            <TableRow className={styles.tableHeadRow}>
              {[
                "Email ID",
                "User Name",
                "Creation Date",
                "User Role",
                "Contact",
                "Location",
                "Status",
                "Progress",
                "",
              ].map((header, index) => (
                <TableCell key={index} className={styles.tableHeader}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} className={styles.tableBodyRow}>
                <TableCell>{row.emailId || "-"}</TableCell>
                <TableCell>{row.userName || "-"}</TableCell>
                <TableCell>{row.creationDate || "-"}</TableCell>
                <TableCell>{row.userRole || "-"}</TableCell>
                <TableCell>{row.contact || "-"}</TableCell>
                <TableCell>{row.location || "-"}</TableCell>
                <TableCell>{row.userStatus || "-"}</TableCell>
                <TableCell>{row.progress || "-"}</TableCell>
                <TableCell>
                  <IconButton
                    className={styles.moreIcon}
                    onClick={(event) => handleMenuOpen(event, row)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    className={styles.menu}
                  >
                    {/* <MenuItem onClick={handleMenuClose}>View Details</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Delete</MenuItem> */}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

// PaginationComponent Component
const PaginationComponent = ({ page, onPageChange, totalPages }) => {
  return (
    <Pagination
      count={totalPages} // Set the max number of pages
      page={page}
      onChange={(e, value) => onPageChange(value)}
      color="primary"
      className={styles.pagination}
      sx={paginationStyles}
    />
  );
};

const paginationStyles = {
  "& .MuiPaginationItem-root": {
    border: "1px solid #DFE3E8", // Grey border for all numbers
    color: "#AEAEAE", // Grey color for all numbers
    margin: "0 5px", // Spacing between pagination items
    borderRadius: "5px",
  },
  "& .Mui-selected": {
    backgroundColor: "#fff", // Blue background for selected number
    color: "#062B6E", // White text for selected number
    border: "1px solid #062B6E", // Blue border for selected number
    borderRadius: "5px",
  },
  "& .MuiPaginationItem-previousNext": {
    color: "#212121", // Blue arrow color for previous/next buttons
    borderRadius: "5px",
    padding: "6px",
  },
};
