import { useEffect, useState } from "react";
import "./ViewRecordings.scss";
import { SubHeaderRoutes } from "../../Components/ViewRecordings/SubHeaderRoutes/SubHeaderRoutes";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { VideoRecording } from "../../Components/ViewRecordings/VideoRecording/VideoRecording";
import { ScreenRecording } from "../../Components/ViewRecordings/ScreenRecording/ScreenRecording";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { BackButton } from "../../Components/Common/BackButton/BackButton";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";
import { getUserCustomTestFormat } from "../../Common/flowAfterLogin";

export const ViewRecordings = () => {
  const [loading, setLoading] = useState(false);
  const [selecetdIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userEmail = queryParams?.get("user") || "";
  const { progress, back, candidateName } = location.state || {};
  const [customStages, setCustomStages] = useState({});
  const [selectedValue, setSelectedValue] = useState("");

  const navigate = useNavigate();
  console.log("progress", progress);

  const handleBackButton = () => {
    if (back) navigate(-1);
    else navigate("/roles/list", { state: location.state });
  };

  const handleChange = (event) => {
    // console.log("selected=>", event.target.value);
    setSelectedValue(event.target.value);
  };

  const handleGetUserCustomTestFormat = async () => {
    setLoading(true);
    // console.log("step2=>", userEmail);
    const data = await getUserCustomTestFormat(userEmail, true);
    // console.log(data);
    setCustomStages(data);
    setLoading(false);
  };

  useEffect(() => {
    if (userEmail) {
      // console.log("step1=>", userEmail);
      handleGetUserCustomTestFormat();
    }
  }, [userEmail]);

  return (
    <PageLayout>
      {loading && <GlobalLoader />}
      <div className="view-recordings-Container">
        <div className="view-recording-dropdown">
          <BackButton name="Back" handleBackButton={handleBackButton} />
          <FormControl>
            <InputLabel id="dropdown-label">Select an Option</InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              value={selectedValue}
              onChange={handleChange}
              label="Select an Option"
              className="dropdown-wrapper"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {customStages?.stages &&
                customStages?.stages?.map((item, index) => (
                  <MenuItem key={index} value={item.stageType}>
                    {item.stageName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <SubHeaderRoutes
          selecetdIndex={selecetdIndex}
          setSelectedIndex={setSelectedIndex}
        />
        {selectedValue && (
          <Routes>
            <Route
              path="/video"
              element={
                <VideoRecording
                  setLoading={setLoading}
                  selectedValue={selectedValue}
                />
              }
            />
            <Route
              path="/screen"
              element={
                <ScreenRecording
                  setLoading={setLoading}
                  selectedValue={selectedValue}
                />
              }
            />
            <Route path="/" element={<Navigate to="video" />} />
          </Routes>
        )}
      </div>
    </PageLayout>
  );
};
