// src/hooks/useStickyElements.js

import { useState, useEffect } from "react";

const useStickyElements = (elementRef, classNames) => {
  const [stickyElements, setStickyElements] = useState([]);

  useEffect(() => {
    if (elementRef.current) {
      const elements = [];
      classNames.forEach((className) => {
        elements.push(...elementRef.current.getElementsByClassName(className));
      });
      setStickyElements(elements);
    }
  }, [elementRef]);

  const resetStickyPositions = () => {
    stickyElements.forEach((el) => {
      el.dataset.originalPosition = el.style.position;
      el.style.position = "static";
    });
  };

  const restoreStickyPositions = () => {
    stickyElements.forEach((el) => {
      el.style.position = el.dataset.originalPosition;
    });
  };

  return { resetStickyPositions, restoreStickyPositions };
};

export default useStickyElements;
