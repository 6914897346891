import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import "./CandidateMetrixWeekly.scss";
import axios from "axios";

export const CandidateMetrixWeekly = () => {
  const [selectedSkill, setSelectedSkill] = useState("Java");
  const [selectedRound, setSelectedRound] = useState("TFT");
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([
    {
      name: "John Doe",
      currentRound: "TFT",
      status: "In Progress",
      ratings: 4.5,
      progress: 60,
    },
    {
      name: "Jane Smith",
      currentRound: "Coding Challenge",
      status: "Completed",
      ratings: 4.8,
      progress: 100,
    },
    {
      name: "David Lee",
      currentRound: "MCQ",
      status: "In Progress",
      ratings: 4.0,
      progress: 40,
    },
    {
      name: "Emily Clark",
      currentRound: "HR Assessment",
      status: "Pending",
      ratings: 3.8,
      progress: 90,
    },
  ]);

  const handleSkillChange = (event) => {
    setSelectedSkill(event.target.value);
  };

  const handleRoundChange = (event) => {
    setSelectedRound(event.target.value);
  };
  function getCurrentWeekFormattedDates() {
    const now = new Date();

    // Calculate the start date (Monday of the current week)
    const dayOfWeek = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust for Sunday as the start of the week
    const monday = new Date(now);
    monday.setDate(now.getDate() + daysToMonday);
    monday.setHours(0, 0, 0, 0); // Set time to start of the day (00:00:00)

    // Calculate the end date (today's date)
    const today = new Date(now);
    today.setHours(0, 0, 0, 0); // Set time to start of the day (00:00:00)

    // Helper function to format date as "yyyy-MM-ddTHH:mm:ss"
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}T00:00:00`;
    };

    // Format the start and end dates
    const formattedStartDate = formatDate(monday);
    const formattedEndDate = formatDate(today);

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  }
  const getWeeklyStats = async () => {
    // Define the URL and the data payload
    const url = "/erp/client/dashboard/candidate/data"; // Replace with your API URL
    const weekDates = getCurrentWeekFormattedDates();
    const data = {
      clientId: localStorage.getItem("clientId"),
      startDate: weekDates.startDate,
      endDate: weekDates.endDate,
      roleName: null,
      // roleName: selectedSkill,
      roundName: null,
      // roundName: selectedRound,
    };
    setLoading(true);

    try {
      // Make the POST request using axios
      const response = await axios.post(url, data);

      // Handle the response
      // console.log("API Response:", response.data);
      // prefillStatsData(response.data);
      return response.data; // Return the response for further use
    } catch (error) {
      // Handle errors
      console.error("Error fetching stats:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSkill && selectedRound) getWeeklyStats();
  }, [selectedRound, selectedSkill]);

  return (
    <Box
      sx={{ p: 4, fontFamily: "Arial, sans-serif" }}
      className="CandidateMetrixWeekly-wrapper"
    >
      {/* Row 1: Header and Dropdowns */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 4 }}
      >
        <Grid item xs={6}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Candidates This Week
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <FormControl
              variant="outlined"
              size="small"
              sx={{ minWidth: 150 }}
              disabled={loading}
            >
              <InputLabel>Skills</InputLabel>
              <Select
                value={selectedSkill}
                onChange={handleSkillChange}
                label="Skills"
              >
                <MenuItem value="Java">Java</MenuItem>
                <MenuItem value="Python">Python</MenuItem>
                <MenuItem value="mcq-lang">Mcq-Lang</MenuItem>
                <MenuItem value="Data Science">Data Science</MenuItem>
                <MenuItem value="DevOps">DevOps</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              size="small"
              sx={{ minWidth: 150 }}
              disabled={loading}
            >
              <InputLabel>Rounds</InputLabel>
              <Select
                value={selectedRound}
                onChange={handleRoundChange}
                label="Rounds"
              >
                <MenuItem value="TFT">TFT</MenuItem>
                <MenuItem value="MCQ">Mcq</MenuItem>
                <MenuItem value="MCQ">MCQ</MenuItem>
                <MenuItem value="Coding Challenge">Coding Challenge</MenuItem>
                <MenuItem value="HR Assessment">HR Assessment</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {/* Row 2: Table */}
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Table sx={{ border: "1px solid #ddd" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Current Round</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Ratings</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                Application Progress
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates.map((candidate, index) => (
              <TableRow key={index}>
                <TableCell>{candidate.name}</TableCell>
                <TableCell>{candidate.currentRound}</TableCell>
                <TableCell>{candidate.status}</TableCell>
                <TableCell>{candidate.ratings}</TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LinearProgress
                      variant="determinate"
                      value={candidate.progress}
                      sx={{
                        flex: 1,
                        height: 10,
                        borderRadius: 5,
                        mr: 1,
                        backgroundColor: "#e0e0e0",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor:
                            //candidate.progress >= 80 ? "darkblue" : "blue",
                            "blue",
                        },
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ minWidth: 40, fontWeight: "bold" }}
                    >
                      {candidate.progress}%
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
