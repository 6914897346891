import { useEffect, useRef, useState } from "react";
import {
  getPassedData,
  getUserCustomTestFormat,
} from "../../../Common/flowAfterLogin";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import { CandidateSubHeader } from "../../../Components/CandidateSubHeader/CandidateSubHeader";
import "./AssessmentRound4.scss";
import { RoundInstructions } from "../../../Components/RoundInstructions/RoundInstructions";
import {
  round4Instructions,
  round4InstructionsDataScience,
} from "../../../mockData/roundInstructions/round4Instructions";
import { FailureMessage } from "../../../Components/FailureMessage/FailureMessage";
import { SuccessRoundMessage } from "../../../Components/SuccessRoundMessage/SuccessRoundMessage";
import axios from "axios";
import Popup from "../../../Components/Common/popUp/popUp";
import closeIcon from "../../../Assets/SVG/Close.svg";
import blueClockIcon from "../../../Assets/SVG/blueClock.svg";
// import { toast } from "react-toastify";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import { FilesError } from "../../../Components/Common/FileUploadError/FileUploadError";
import { fileUploadErrorsProject } from "../../../mockData/fileUploadErrors";
import { UploadSuccessFile } from "../../../Components/Common/UploadSuccess/UploadSuccess";
import crossFile from "../../../Assets/SVG/crossFile.svg";
import crosssIcon from "../../../Assets/SVG/Close.svg";
import { toast } from "react-toastify";

const developerDesc =
  "Show case your coding proficiency through a hands-on project assessment that assesses your problem-solving skills and coding capabilities.";

const dataScienceDesc =
  "Exhibit your data science proficiency with practical case studies, evaluating problem-solving and coding competencies.";

export const AssessmentRound4 = () => {
  const [passedData, setPassedData] = useState([]);
  const [isRound4Clear, setIsRoundClear] = useState(false);
  const [viewSuccess, setViewSuccess] = useState(false);
  const [isRound4Failed, setIsRound4Failed] = useState({
    status: false,
    reApply: "",
  });
  const [viewFail, setViewFail] = useState(false);
  const [reapplydate, setreApplydate] = useState("");
  const [viewButton, setViewButton] = useState(true);
  const [typeOfProject, setTypeOfProject] = useState("");
  const [viewLoader, setViewLoader] = useState(false);
  const [candidateRole, setCandidateRole] = useState("");
  // stages for test to determine sub header and pass failed redirection flow
  const [stages, setStages] = useState({});

  const getCustomFormat = async () => {
    try {
      const data = await getUserCustomTestFormat();

      if (data?.stages) {
        // Store the stages in the state as well if needed
        setStages(data);
      } else {
        toast.error("Stages are not available");
      }
    } catch (err) {
      console.error("error in getCustomFormat==>", err);
    }
  };

  // const handleGetRound4Status = () => {};

  const getProfessionalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/professional";
      const data = { id: localStorage.getItem("email") };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.data?.subfield === "Data Science Engineer") {
        // console.log("getProfessionalDetails===> data science");
        setCandidateRole("Data_Science");
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  const checkForPassedRound = async () => {
    try {
      const res = await getPassedData();
      const passedArray = res?.passed ? res.passed : [];
      setPassedData([...passedArray]);
      console.log("checkForPassedRound4==>", passedArray);
      if (
        passedArray?.includes("Round1") &&
        passedArray?.includes("Round2") &&
        passedArray?.includes("Round3")
      ) {
        if (passedArray && passedArray?.includes("Round4")) {
          setIsRoundClear(true);
          setViewSuccess(true);
        } else if (res?.failed?.includes("Round4")) {
          setIsRound4Failed({
            status: true,
            reApply: res?.retestAcceptance,
          });
          setViewFail(true);
          setreApplydate(res?.retestAcceptance);
        } else {
          // handleGetRound4Status();
        }
      } else {
        setViewButton(false);
      }
    } catch (err) {
      console.log("checkForPassedRound error==>", err);
    }
  };

  const getTypeOfProject = async () => {
    try {
      const endpoint = "/candidate/round4/assignment/type";
      const data = {
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      // console.log(res?.data);
      setTypeOfProject(res?.data?.typeOfProject);
    } catch (err) {
      console.log("getTypeOfProject error==>", err);
    }
  };

  const getNecessaryDetails = async () => {
    setViewLoader(true);
    await getCustomFormat();
    await checkForPassedRound();
    await getTypeOfProject();
    await getProfessionalDetails();
    setViewLoader(false);
  };

  useEffect(() => {
    getNecessaryDetails();
  }, []);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      {viewSuccess && (
        <SuccessRoundMessage
          message={"You have successfully completed Round 4."}
          gotoRound={"/discussion"}
          onclose={setViewSuccess}
          next={"Round 5"}
        />
      )}

      {viewFail && (
        <FailureMessage reapply={reapplydate} onclose={setViewFail} />
      )}

      <CandidateHeader />
      {stages && <CandidateSubHeader passedData={passedData} stages={stages} />}

      <RoundStartOrComplete
        viewButton={viewButton}
        isRound4Failed={isRound4Failed}
        isRound4Clear={isRound4Clear}
        checkForPassedRound={checkForPassedRound}
        typeOfProject={typeOfProject}
        setViewLoader={setViewLoader}
        candidateRole={candidateRole}
      />

      <RoundInstructions
        roundInstructions={
          candidateRole === "Data_Science"
            ? round4InstructionsDataScience
            : round4Instructions
        }
      />
    </>
  );
};

const RoundStartOrComplete = ({
  viewButton,
  isRound4Clear,
  isRound4Failed,
  checkForPassedRound,
  typeOfProject,
  setViewLoader,
  candidateRole,
}) => {
  return (
    <>
      <div className="round4StartOrComplete ">
        <div className="status-main">
          <div>
            {typeOfProject && <div className="text-1">{typeOfProject} hrs</div>}
            <div className="text-2">
              {candidateRole === "Data_Science"
                ? "Case Study"
                : "Practical Assignment"}
            </div>
            <div className="text-3">
              {" "}
              {candidateRole === "Data_Science"
                ? dataScienceDesc
                : developerDesc}
            </div>
          </div>
        </div>
        {viewButton ? (
          isRound4Failed?.status ? null : isRound4Clear ? (
            <div className="completed">Completed</div>
          ) : (
            <AssessmentUi
              checkForPassedRound={checkForPassedRound}
              setViewLoader={setViewLoader}
            />
          )
        ) : null}
      </div>
    </>
  );
};

const AssessmentUi = ({ checkForPassedRound, setViewLoader }) => {
  const [loading, setLoading] = useState(true);
  const [started, setStarted] = useState(false);
  const [viewPreStart, setViewPreStart] = useState(false);
  const [startDateStr, setStartDateStr] = useState(null);
  const [typeOfProject, setTypeOfProject] = useState(null);

  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState(0);
  const [expired, setExpired] = useState(false);
  const [timeOffset, setTimeOffset] = useState(null);
  const timerRef = useRef(null);
  const [projectSubmitted, setProjectSubmitted] = useState(null);

  function addHoursToDate(date, hours) {
    var newDate = new Date(date.getTime()); // Ensure we are not modifying the original date
    var millisecondsToAdd = hours * 3600000; // Convert hours to milliseconds
    newDate.setTime(newDate.getTime() + millisecondsToAdd);
    return newDate;
  }

  const formatTime = (startDateStr, typeOfProject, serverTime) => {
    // Parsing the date string into a Date object
    var startDate = new Date(startDateStr);

    // Calculating endtime
    let endtime;
    // endtime = addHoursToDate(startDate, 0.03);
    if (typeOfProject === "48hrs") endtime = addHoursToDate(startDate, 48);
    else if (typeOfProject === "2hrs") endtime = addHoursToDate(startDate, 2);
    else if (typeOfProject === "24hrs") endtime = addHoursToDate(startDate, 24);

    // Calculating the remaining time in seconds using server time
    const currentTime = new Date(serverTime);
    const remainingTimeInSeconds = Math.floor(
      (endtime.getTime() - currentTime.getTime()) / 1000
    );
    return remainingTimeInSeconds;
    // return 5;
  };

  const handleR4Status = async () => {
    try {
      const endpoint = "/candidate/round4/assignment/status";
      const data = {
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      console.log("handleR4Status==>", res?.data);
      if (res?.data?.message === "User has not started the project") {
        setLoading(false);
      } else if (res?.data?.message === null) {
        setStarted(true);
        // formatTime(res?.data?.startDate, res?.data?.typeOfProject);
        setProjectSubmitted(res?.data?.projectName);
        setStartDateStr(res?.data?.startDate);
        setTypeOfProject(res?.data?.typeOfProject);

        // Parse server time and calculate offset
        const serverTimeReceived = new Date(res.data.currentServerTime);
        const localTime = new Date();
        const timeOffset = serverTimeReceived.getTime() - localTime.getTime();
        setTimeOffset(timeOffset); // Save this offset
      }
    } catch (err) {
      console.error("handleR4Status error==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleStartTest = async () => {
    setLoading(true);
    setViewPreStart(false);
    try {
      const endpoint = "/candidate/round4/assignment/start-test";
      const data = {
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      if (res?.data) {
        handleR4Status();
      }
      console.log("handleStartTest==>", res?.data);
    } catch (err) {
      console.error("handleStartTest error==>", err);
    } finally {
      setLoading(true);
    }
  };

  const handleCompleteR4 = async () => {
    try {
      setViewLoader(true);
      const endpoint = "/candidate/round4/submission/complete";
      const data = {
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      // return res?.data;
    } catch (err) {
      console.log("handleCompleteR4===>", err);
    } finally {
      setViewLoader(false);
    }
  };

  const handleRound4PassStatus = async () => {
    try {
      setViewLoader(true);
      const endpoint = "/candidate/round4/submission/status";
      const data = {
        id: localStorage.getItem("email"),
        status: projectSubmitted ? true : false,
      };
      const res = await axios.post(endpoint, data);
      // return res?.data;
      if (res) {
        checkForPassedRound();
      }
    } catch (err) {
      console.log("handleRound4PassStatus===>", err);
    } finally {
      setViewLoader(false);
    }
  };

  const round4CompleteProcess = () => {
    clearInterval(timerRef.current);
    setExpired(true);
    handleCompleteR4();
    handleRound4PassStatus();
  };

  useEffect(() => {
    handleR4Status();
  }, []);

  useEffect(() => {
    if (startDateStr && typeOfProject && timeOffset !== null) {
      const calculateCurrentServerTime = () => {
        const currentLocalTime = new Date().getTime();
        return new Date(currentLocalTime + timeOffset);
      };

      const updateRemainingTime = () => {
        const currentServerTime = calculateCurrentServerTime();
        const newRemainingTime = formatTime(
          startDateStr,
          typeOfProject,
          currentServerTime
        );
        setRemainingTimeInSeconds(newRemainingTime);

        if (newRemainingTime <= 0) {
          // complete r4
          // if submitted make it true else false
          // clearInterval(timerRef.current);
          // setExpired(true);
          // handleCompleteR4();
          // handleRound4PassStatus();
          round4CompleteProcess();
        }
      };

      // Initial update
      updateRemainingTime();

      // Set interval
      timerRef.current = setInterval(updateRemainingTime, 1000);

      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [startDateStr, typeOfProject, timeOffset]);

  return (
    <>
      <div className="assessmentUI">
        {loading ? (
          <div>Loading...</div>
        ) : started ? (
          expired ? (
            <div>Time Expired</div>
          ) : (
            <UploadAssignment
              remainingTimeInSeconds={remainingTimeInSeconds}
              projectSubmitted={projectSubmitted}
              handleR4Status={handleR4Status}
              setViewLoader={setViewLoader}
              handleCompleteR4={round4CompleteProcess}
            />
          )
        ) : (
          <div className="start-test">
            <button onClick={() => setViewPreStart(true)}>
              Start the Test
            </button>
          </div>
        )}
      </div>
      {viewPreStart && (
        <PreStartR4
          setViewPreStart={setViewPreStart}
          handleStartTest={handleStartTest}
        />
      )}
    </>
  );
};

const UploadAssignment = ({
  remainingTimeInSeconds,
  projectSubmitted,
  handleR4Status,
  setViewLoader,
  handleCompleteR4,
}) => {
  const hiddenFileInput = useRef(null);
  const [viewError, setViewError] = useState(false);
  const [fileError, setFileFileError] = useState({});
  const [viewUploadSuccess, setViewUploadSuccess] = useState(false);
  const [viewSubmitProject, setViewSubmitProject] = useState(false);

  const handleCloseError = () => {
    setViewError(false);
    setFileFileError({});
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileSubmit = async (file, sendFile = true) => {
    const formData = new FormData();
    const name = localStorage.getItem("email");

    if (sendFile) formData.append("file", file);
    formData.append("name", name);

    console.log(formData, file);
    try {
      setViewLoader(true);
      const endpoint = "/candidate/round4/file/submit";

      const response = await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        handleR4Status();
        if (sendFile) setViewUploadSuccess(true);
      }
    } catch (error) {
      console.error("Error uploading the file", error);
    } finally {
      setViewLoader(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const type = file?.name?.split(".");
    const typeOfFile = type[type.length - 1];

    const maxFileSize = 10 * 1024 * 1024; // 10 MB

    if (file && file.size === 0) {
      setFileFileError({ ...fileUploadErrorsProject?.fileEmpty });
      setViewError(true);
      return;
    }

    if (file.size > maxFileSize) {
      setFileFileError({ ...fileUploadErrorsProject?.fileSize });
      setViewError(true);
      return;
    }

    if (typeOfFile === "zip") {
      handleFileSubmit(file);
    } else {
      setFileFileError({ ...fileUploadErrorsProject?.fileFormat });
      setViewError(true);
      return;
    }
  };

  // Utility function to convert seconds to hours, minutes, and seconds
  const formatTimeInSeconds = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");

    return `${hours} : ${minutes} : ${seconds}`;
  };

  const handleViewAssessment = async () => {
    try {
      setViewLoader(true);
      const endpoint = "/candidate/round4/file/view";
      const data = {
        name: localStorage.getItem("email"),
      };
      const response = await axios.post(endpoint, data, {
        // responseType: "blob", // Important for handling binary data like files
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      // Create a URL for the blob
      const pdfBlobUrl = window.URL.createObjectURL(blob);
      // Open the PDF in a new tab
      window.open(pdfBlobUrl, "_blank");
    } catch (err) {
      console.log("handleViewAssessment error==>", err);
    } finally {
      setViewLoader(false);
    }
  };

  return (
    <>
      <div className="uploadUi">
        <div className="main-item">
          <div className="buttons">
            <div className="start-test">
              <button onClick={handleClick}>Upload</button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
                accept=".zip"
              />
            </div>
            <div className="view" onClick={() => handleViewAssessment()}>
              {" "}
              View Assignment
            </div>
          </div>
          <div className="time">
            <div>
              <img src={blueClockIcon} alt="" />
            </div>
            <div>{formatTimeInSeconds(remainingTimeInSeconds)}</div>
          </div>
        </div>
        <div className="files">File format: ZIP, below 10 MB</div>
        {projectSubmitted && (
          <>
            <div className="uploaded-file">
              <span>Uploaded : {projectSubmitted} </span>{" "}
              <span
                className="cross"
                onClick={() => handleFileSubmit("", false)}
              >
                <img src={crossFile} alt="" />
              </span>{" "}
            </div>
            <button
              className="submit-round"
              onClick={() => setViewSubmitProject(true)}
            >
              {" "}
              Submit{" "}
            </button>
          </>
        )}
      </div>
      {viewError && (
        <FilesError setviewFileError={handleCloseError} fileError={fileError} />
      )}
      {viewUploadSuccess && (
        <UploadSuccessFile closeSuccessPopUp={setViewUploadSuccess} />
      )}
      {viewSubmitProject && (
        <SubmitProject
          setViewSubmitProject={setViewSubmitProject}
          handleSubmitRound4={handleCompleteR4}
        />
      )}
    </>
  );
};

const PreStartR4 = ({ setViewPreStart, handleStartTest }) => {
  const [instructions, setInstructions] = useState([
    "Please make sure you have all required software are installed before you start the test.",
    // "Once you start the project, it must be completed in one session. There is no option to abort the test and resume at a later time.",
    "Project should be completed within 24 hrs.",
    "You can upload multiple files. Latest/last file will be considered for the evaluation.",
    "As soon as you begin the test, you will have access to the questions, and the timer will start.",
  ]);

  return (
    <Popup>
      <div className="preStartInstructions">
        <div className="close">
          <img src={closeIcon} onClick={() => setViewPreStart(false)} />
        </div>
        <div className="text-1">Assignment</div>
        <div className="text-2">Note</div>
        <div className="text-3">
          {instructions?.map((item, index) => (
            <div key={index}>
              <span>{index + 1}. </span> <span> {item}</span>
            </div>
          ))}
        </div>
        <div className="start-test">
          <button onClick={() => handleStartTest()}> Start the Test</button>
        </div>
      </div>
    </Popup>
  );
};

const SubmitProject = ({ setViewSubmitProject, handleSubmitRound4 }) => {
  return (
    <Popup>
      <div className="resetMain">
        <div className="close" onClick={() => setViewSubmitProject(false)}>
          <img src={crosssIcon} alt="" />
        </div>
        <div className="title1">Submit Project</div>
        <div className="title2">
          Are you sure you want to submit the project? Once submitted, you can’t
          undo this action.
        </div>
        <div className="last">
          <button onClick={() => setViewSubmitProject(false)}>Cancel</button>
          <button
            onClick={() => {
              setViewSubmitProject(false);
              handleSubmitRound4();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Popup>
  );
};
