import { BackButton } from "../../Components/Common/BackButton/BackButton";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import styles from "./SignupedCandidateRole.module.scss";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

export const SignupCandidateForRole = () => {
  return (
    <PageLayout>
      <SignupCandidateList />
    </PageLayout>
  );
};

const SignupCandidateList = () => {
  const location = useLocation();
  const { hiringProcessId, businessUnit, roleName, idOfRole } =
    location.state || {};
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(40); // Total items (this can come from an API call)
  const itemsPerPage = 10; // Fixed items per page
  // Calculate total pages based on total items and items per page
  const [totalPages, setTotalPages] = useState(0);
  const [candidates, setCandidates] = useState([]);

  // Implement back button functionality
  const handleBackButton = () => {
    // Example: navigate to the previous page
    window.history.back();
  };

  // Handle page change and update URL query parameters and state
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Separate function to fetch candidates
  const fetchCandidates = async (pageNumber, pageSize) => {
    try {
      const urlEndpoint = `/erp/client/role/get/list/signedup?pageNumber=${pageNumber}&pageSize=${pageSize}`;
      const data = {
        invitedByUserId: localStorage.getItem("clientId"),
        roleId: roleName,
      };
      const response = await axios.post(urlEndpoint, data);

      // Extract data from response
      // const candidatesData = response.data?.candidates || [];
      // const totalItemsCount = response.data?.totalItems || 0;

      // Update state with response data
      setCandidates(response.data?.content);
      // setTotalItems(totalItemsCount);

      // Calculate total pages based on total items and items per page
      // const calculatedTotalPages = Math.ceil(totalItemsCount / itemsPerPage);
      // setTotalPages(response?.data?.totalPages);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  };

  const handleGetCandidates = () => {
    // Call the separate fetch function with updated page number
    fetchCandidates(page - 1, itemsPerPage);
  };

  // Fetch candidates whenever the page changes
  useEffect(() => {
    handleGetCandidates();
  }, [page]);

  return (
    <div className={styles.signupCandidateListWrapper}>
      <BackButton handleBackButton={handleBackButton} name={"Back"} />
      <h1 className={styles.title}>Signed up candidates</h1>
      <UserTable
        tableData={candidates}
        handleGetCandidates={handleGetCandidates}
      />
      <PaginationComponent
        page={page}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  );
};

const UserTable = ({ tableData, handleGetCandidates }) => {
  const [menuAnchor, setMenuAnchor] = useState(null); // For anchorEl and selected row
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();

  // Open menu
  const handleMenuOpen = (event, row) => {
    setMenuAnchor({ anchorEl: event.currentTarget, row });
  };

  // Close menu
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  // Function to handle navigation
  const handleViewDetails = () => {
    if (menuAnchor?.row) {
      const { emailId, userName, userRole, stageName } = menuAnchor.row;
      navigate("/candidate-details", {
        state: { emailId, userName, userRole, stageName },
      });
    }
    handleMenuClose();
  };

  // Report view
  const handleViewReport = () => {
    if (menuAnchor?.row) {
      const { emailId, userName, userRole, stageName } = menuAnchor.row;
      navigate("/candidate-report", {
        state: { emailId, userName, userRole, stageName },
      });
    }
    handleMenuClose();
  };

  // Open delete dialog
  const handleDeleteDialogOpen = () => {
    setOpenDeleteDialog(true);
    handleMenuClose();
  };

  // Close delete dialog
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  // Handle edit user details
  const handleEditUserDetails = () => {
    if (menuAnchor?.row) {
      const row = menuAnchor.row;
      navigate("/edit-progress", {
        state: {
          emailId: row.emailId,
          userName: row.userName,
          userRole: row.userRole,
          contact: row.contact,
          location: row.location,
          progress: row.progress,
          stageName: row.stageName,
          creationDate: row.creationDate,
          userStatus: row.userStatus,
        },
      });
    }
    handleMenuClose();
  };

  const handleViewRecording = () => {
    if (menuAnchor?.row) {
      const row = menuAnchor.row;
      navigate(`/dev-recording/screen?user=${row.emailId}`, {
        state: {
          progress: row.progress,
          back: true,
          candidateName: row.userName,
        }, // Added progress to the state
      });
    }
  };

  // Handle delete action
  const handleDelete = async () => {
    if (menuAnchor?.row) {
      const { emailId } = menuAnchor.row;
      const data = {
        email: emailId,
        clientId: localStorage.getItem("clientId"),
      };

      try {
        await axios.delete("/erp/client/role/user/delete", { data });
        setOpenDeleteDialog(false);
        handleGetCandidates(); // Refresh data
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <div className={styles.userTableWrapper}>
      <TableContainer className={styles.userTableContainer}>
        <Table className={styles.userTable}>
          <TableHead>
            <TableRow className={styles.tableHeadRow}>
              {[
                "Email ID",
                "User Name",
                "Creation Date",
                "User Role",
                "Contact",
                "Location",
                "Status",
                "Progress",
                "",
              ].map((header, index) => (
                <TableCell key={index} className={styles.tableHeader}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index} className={styles.tableBodyRow}>
                <TableCell>{row.emailId || "-"}</TableCell>
                <TableCell>{row.userName || "-"}</TableCell>
                <TableCell>{row.creationDate || "-"}</TableCell>
                <TableCell>{row.userRole || "-"}</TableCell>
                <TableCell>{row.contact || "-"}</TableCell>
                <TableCell>{row.location || "-"}</TableCell>
                <TableCell>{row.userStatus || "-"}</TableCell>
                <TableCell>{row.progress || "-"}</TableCell>
                <TableCell>
                  <IconButton
                    className={styles.moreIcon}
                    onClick={(event) => handleMenuOpen(event, row)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  {menuAnchor?.row === row && (
                    <Menu
                      anchorEl={menuAnchor?.anchorEl}
                      open={Boolean(menuAnchor)}
                      onClose={handleMenuClose}
                      className={styles.menu}
                    >
                      <MenuItem onClick={handleDeleteDialogOpen}>
                        Delete
                      </MenuItem>
                      <MenuItem onClick={handleViewDetails}>
                        View Details
                      </MenuItem>
                      <MenuItem onClick={handleEditUserDetails}>
                        Edit Progress
                      </MenuItem>
                      <MenuItem onClick={handleViewRecording}>
                        View Recording
                      </MenuItem>
                      <MenuItem
                        onClick={handleViewReport}
                        disabled={
                          row?.progress?.toLowerCase() === "personal details"
                        } // Disable if progress is "personal details"
                      >
                        View Report
                      </MenuItem>
                    </Menu>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        className={styles.deleteDialogSignUped}
      >
        <DialogTitle className={styles.dialogTitle}>
          <DeleteIcon className={styles.trashIcon} /> {/* Added Trash Icon */}
          <span>Are you sure you want to delete this user?</span>
          <IconButton
            aria-label="close"
            onClick={handleDeleteDialogClose}
            className={styles.closeIcon}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogActions className={styles.dialogActions}>
          <Button
            className={styles.cancelButton}
            onClick={handleDeleteDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} className={styles.deleteButton}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// PaginationComponent Component
const PaginationComponent = ({ page, onPageChange, totalPages }) => {
  return (
    <Pagination
      count={totalPages} // Set the max number of pages
      page={page}
      onChange={(e, value) => onPageChange(value)}
      color="primary"
      className={styles.pagination}
      sx={paginationStyles}
    />
  );
};

const paginationStyles = {
  "& .MuiPaginationItem-root": {
    border: "1px solid #DFE3E8", // Grey border for all numbers
    color: "#AEAEAE", // Grey color for all numbers
    margin: "0 5px", // Spacing between pagination items
    borderRadius: "5px",
  },
  "& .Mui-selected": {
    backgroundColor: "#fff", // Blue background for selected number
    color: "#062B6E", // White text for selected number
    border: "1px solid #062B6E", // Blue border for selected number
    borderRadius: "5px",
  },
  "& .MuiPaginationItem-previousNext": {
    color: "#212121", // Blue arrow color for previous/next buttons
    borderRadius: "5px",
    padding: "6px",
  },
};
