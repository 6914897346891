import React, { useState } from "react";
import {
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import { toast } from "react-toastify";

const ScheduleMeeting = () => {
  const timeOptions = [
    "12:00 AM", "12:15 AM", "12:30 AM", "12:45 AM",
    "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM",
    "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM",
    "03:00 AM", "03:15 AM", "03:30 AM", "03:45 AM",
    "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM",
    "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM",
    "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM",
    "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM",
    "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM",
    "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM",
    "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM",
    "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
    "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM",
    "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM",
    "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM",
    "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM",
    "04:00 PM", "04:15 PM", "04:30 PM", "04:45 PM",
    "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM",
    "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM",
    "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM",
    "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM",
    "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM",
    "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM",
    "11:00 PM", "11:15 PM", "11:30 PM", "11:45 PM"
  ];
  
  

  const timeZones = [
    "Asia/Kolkata",
    "America/New_York",
    "Europe/London",
    "Australia/Sydney",
  ];
  const clientId = localStorage.getItem("clientId") || ""; // Fetch clientId from localStorage
  const [developerEmails, setDeveloperEmails] = useState([]);
  const [developerEmail, setDeveloperEmail] = useState("");
  const [hrEmail, setHrEmail] = useState("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState(timeOptions[0]);
  const [endTime, setEndTime] = useState(timeOptions[2]);
  const [timeZone, setTimeZone] = useState(timeZones[0]);
  const [role, setRole] = useState("");
  const [stageId, setStageId] = useState("");
  const [interviewType, setInterviewType] = useState("softSkillsInterview");
  const [errors, setErrors] = useState({});

  function convertTo24HourFormat(time) {
    console.log(time,"check1")
      const [hourMin, modifier] = time?.split(" ");
      let [hours, minutes] = hourMin.split(":").map(Number);
  
      if (modifier === "PM" && hours !== 12) {
          hours += 12;
      } else if (modifier === "AM" && hours === 12) {
          hours = 0;
      }
  
      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  }

  function checkTime() {
    // Parse the startTime and endTime into Date objects
    console.log(startTime,"check0")
    const start = new Date(`1970-01-01T${convertTo24HourFormat(startTime)}:00`);
    const end = new Date(`1970-01-01T${convertTo24HourFormat(endTime)}:00`);

    // Calculate the difference in minutes
    const duration = (end - start) / (1000 * 60); // Difference in milliseconds converted to minutes

    // Validate if the duration is at least 30 minutes
    if (duration  >=30 && duration <= 60) {
        return false;
    } else {
        return true;
    }
}

// Helper function to convert 12-hour time to 24-hour format


  const validateFields = () => {
    const newErrors = {};
    if(checkTime()){
      toast.error("Scheduled Meeting should be more than 30 minutes");
      return;
    }

    // Validate required fields
    if (!date) newErrors.date = "Date is required";
    if (!startTime) newErrors.startTime = "Start time is required";
    if (!endTime) newErrors.endTime = "End time is required";
    if (!timeZone) newErrors.timeZone = "Time zone is required";
    if (developerEmails.length === 0)
      newErrors.developerEmails = "Add at least one developer email";
    if (!hrEmail) newErrors.hrEmail = "HR email is required";
    // if (!role) newErrors.role = "Role is required";
    // if (!stageId) newErrors.stageId = "Stage ID is required";
    if (!interviewType) newErrors.interviewType = "Interview type is required";

    // Validate that end time is after start time
    if (startTime && endTime) {
      const startTimeDate = new Date(`1970-01-01T${startTime}:00`);
      const endTimeDate = new Date(`1970-01-01T${endTime}:00`);
      if (endTimeDate <= startTimeDate) {
        newErrors.endTime = "End time must be after start time";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddDeveloperEmail = () => {
    if (developerEmail.trim()) {
      setDeveloperEmails([...developerEmails, developerEmail.trim()]);
      setDeveloperEmail("");
    }
  };

  const handleRemoveDeveloperEmail = (email) => {
    setDeveloperEmails(developerEmails.filter((e) => e !== email));
  };
  function convertDateFormat(inputDate) {
    // Parse the input date string (assumes format: YYYY-MM-DD)
    const [year, month, day] = inputDate.split("-");
    
    // Rearrange to DD-MM-YYYY format
    const formattedDate = `${day}-${month}-${year}`;
    
    return formattedDate;
  }

  const handleScheduleMeeting = async () => {
    if (!validateFields()) return;

    const requestData = {
      emailIds: [
        ...developerEmails.map((email) => ({
          user: "developer",
          email,
        })),
        { user: "hr", email: hrEmail },
      ],
      date: convertDateFormat(date),
      startTime,
      endTime,
      timeZone,
      clientId,
      // role,
      // stageId,
      interviewType,
    };
    
    console.log(requestData);
    const apiUrl = "/erp/client/schedule/softskillsInterview/schedule-meeting";

    try {
      //const response = await axios.post("https://erp-be.wissda.cloud/softskillsInterview/schedule-meeting", requestData);
      const response = await axios.post(apiUrl, requestData);
      toast.success("Meeting scheduled successfully")
      setDeveloperEmails([]);
      setDeveloperEmail([]);
      setHrEmail("")
      setDate("")
      setStartTime(timeOptions[0]);
      setEndTime(timeOptions[1]);
      setTimeZone(timeZones[0])
      //alert("Meeting scheduled successfully");
    } catch (error) {
      console.error("Error scheduling meeting:", error);
      toast.error(error?.response?.data)
      //alert("Failed to schedule meeting");
    }
  };

  return (
    <PageLayout>
      <Box sx={{ p: 3, maxWidth: 600, margin: "0 auto" }}>
        <Typography variant="h5" mb={3}>
          Manual Schedule Meeting
        </Typography>

        <Grid container spacing={2}>
          {/* Date Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="date"
              label="Date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              error={!!errors.date}
              helperText={errors.date}
              InputLabelProps={{
                shrink: true, // Keeps label above input
              }}
              variant="outlined"
              sx={{ mb: 2 }}
            />
          </Grid>

          {/* Start Time Field */}
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Start Time</InputLabel>
              <Select
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                label="Start Time"
                displayEmpty
                renderValue={(selected) =>
                  selected ? selected : <em>Select Start Time</em>
                }
              >
                <MenuItem value="" disabled>
                  Select Start Time
                </MenuItem>
                {timeOptions.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
              {errors.startTime && (
                <Typography variant="caption" color="error">
                  {errors.startTime}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* End Time Field */}
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>End Time</InputLabel>
              <Select
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                label="End Time"
                displayEmpty
                renderValue={(selected) =>
                  selected ? selected : <em>Select End Time</em>
                }
              >
                <MenuItem value="" disabled>
                  Select End Time
                </MenuItem>
                {timeOptions.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
              {errors.endTime && (
                <Typography variant="caption" color="error">
                  {errors.endTime}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Time Zone Field */}
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>Time Zone</InputLabel>
              <Select
                value={timeZone}
                onChange={(e) => setTimeZone(e.target.value)}
                label="Time Zone"
                displayEmpty
                renderValue={(selected) =>
                  selected ? selected : <em>Select Time Zone</em>
                }
              >
                <MenuItem value="" disabled>
                  Select Time Zone
                </MenuItem>
                {timeZones.map((zone) => (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
              {errors.timeZone && (
                <Typography variant="caption" color="error">
                  {errors.timeZone}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Developer Email Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Developer Email"
              value={developerEmail}
              onChange={(e) => setDeveloperEmail(e.target.value)}
              variant="outlined"
              sx={{ mb: 1 }}
            />
            <Button variant="outlined" onClick={handleAddDeveloperEmail}>
              Add Developer Email
            </Button>
            {errors.developerEmails && (
              <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                {errors.developerEmails}
              </Typography>
            )}
          </Grid>

          {/* Developer Emails List */}
          <Grid item xs={12}>
            {developerEmails.map((email) => (
              <Box key={email} display="flex" alignItems="center" mb={1}>
                <Typography sx={{ flexGrow: 1 }}>{email}</Typography>
                <IconButton onClick={() => handleRemoveDeveloperEmail(email)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Grid>

          {/* HR Email Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="HR Email"
              value={hrEmail}
              onChange={(e) => setHrEmail(e.target.value)}
              error={!!errors.hrEmail}
              helperText={errors.hrEmail}
              variant="outlined"
            />
          </Grid>

          {/* Role Field */}
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              error={!!errors.role}
              helperText={errors.role}
              variant="outlined"
            />
          </Grid> */}

          {/* Stage ID Field */}
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Stage ID"
              value={stageId}
              onChange={(e) => setStageId(e.target.value)}
              error={!!errors.stageId}
              helperText={errors.stageId}
              variant="outlined"
            />
          </Grid> */}

          {/* Interview Type Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Interview Type"
              value={interviewType}
              onChange={(e) => setInterviewType(e.target.value)}
              error={!!errors.interviewType}
              helperText={errors.interviewType}
              variant="outlined"
              disabled
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleScheduleMeeting}
            >
              Schedule Meeting
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};

export default ScheduleMeeting;
