import React, { useState, useEffect } from "react";
import { Button, InputBase, Select, MenuItem, IconButton } from "@mui/material";
import { Box, Autocomplete, TextField, Chip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./CodingConfigComponent.module.scss";
import { BackButton } from "../../Common/BackButton/BackButton";
import { GlobalLoader } from "../../Common/GlobalLoader/GlobalLoader";
import axios from "axios";
import { toast } from "react-toastify";

// CodingConfigComponent
const CodingConfigComponent = ({
  roundName,
  setFormData,
  formData,
  roundIndex,
  setCurrentConfig,
  allConfigsData,
  setAllConfigsData,
  stageId,
  isEditable,
}) => {
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalTime, setTotalTime] = useState(0); // For total time in minutes
  const [topics, setTopics] = useState([{ topic: "", numOfQuestions: 0 }]);
  const [errors, setErrors] = useState({});
  const [thresholdQuestions, setThresholdQuestions] = useState(0);
  const [availableTopics, setAvailableTopics] = useState([
    // "Characters Array",
    // "Array",
    // "Queue",
    // "Integer Array",
    // "Tree",
    // "Linked List",
    // "Recursion and Backtracking",
    // "String",
    // "Stack",
    // "Binary Search",
    // "Dynamic Programming",
    // "Priority Queue",
    // "Graph",
    // "ServiceNow",
  ]);
  const [allowedLanguages, setAllowedLanguages] = useState([
    "Java",
    "Python",
    "Javascript",
    "C++",
  ]);
  const [configRecieved, setConfigRecieved] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const prefillData = (response) => {
    // console.log("data received: ", response);
    setTotalQuestions(response.totalQuestions); // Set total questions
    setTotalTime(response.totalTimeInMins); // Set total time in minutes
    setThresholdQuestions(response.thresholdQuestions); // Set threshold questions

    // Map topics to match the state format and set the topics state
    const formattedTopics = response.topics.map((topic) => ({
      topic: topic.topic || "", // Set topic name or empty string if null
      numOfQuestions: topic.totalTopicQuestions || 0, // Set number of questions
    }));

    setTopics(formattedTopics); // Set topics state with formatted topics
    setSelectedLanguages([...response?.languagesAllowed]);
  };

  // API function to fetch stage data based on stageId
  const fetchStageData = async (stageId) => {
    try {
      setLoading(true);
      // console.log(formData);
      const data = {
        clientId: localStorage.getItem("clientId"),
        stageId,
        // role: roundName,
        role: formData.roleName,
      };
      const response = await axios.post(
        `/erp/client/role/coding/config/get`,
        data
      );
      // return response.data?.config;
      setConfigRecieved(response.data);
      prefillData(response.data);
    } catch (error) {
      console.error("Error fetching stage data:", error);
      throw error; // Rethrow if you want to handle it in the component
    } finally {
      setLoading(false);
    }
  };

  const getAvailableTopics = (index) => {
    const selectedTopics = topics.map((t) => t.topic.toLowerCase());
    return availableTopics.filter(
      (option) =>
        !selectedTopics.includes(option.toLowerCase()) ||
        selectedTopics[index] === option.toLowerCase()
    );
  };

  const getAllAvailableTopicsData = async () => {
    const urlEndpoint = "/erp/client/role/available/coding/languages/get";
    setLoading(true);
    try {
      const response = await axios.get(urlEndpoint);
      // console.log("Topics:", response.data); // Log or process the response data
      setAvailableTopics((prevOptions) => [...prevOptions, ...response?.data]);
    } catch (error) {
      console.error("Error fetching topics:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Prefill data if formData exists
  useEffect(() => {
    getAllAvailableTopicsData();
    if (stageId) {
      fetchStageData(stageId);
    } else {
      const roundConfig = formData?.rounds?.[roundIndex]?.config;
      if (roundConfig) {
        setTotalQuestions(roundConfig.totalQuestions || "");
        setTotalTime(roundConfig.totalTime || "");
        setTopics(roundConfig.topics || [{ topic: "", numOfQuestions: 0 }]);
        setThresholdQuestions(roundConfig.thresholdQuestions);
      }
    }
  }, [formData, roundIndex, stageId]);

  // Handle adding a new topic
  const addTopic = () => {
    setTopics([...topics, { topic: "", numOfQuestions: 0 }]);
  };

  // Handle removing a topic
  const removeTopic = (index) => {
    const updatedTopics = topics.filter((_, i) => i !== index);
    setTopics(updatedTopics);
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedTopics = topics.map((topic, i) =>
      i === index ? { ...topic, [field]: value } : topic
    );
    setTopics(updatedTopics);
  };

  // Handle form submission (save the config)
  const handleSave = () => {
    const totalAssignedQuestions = topics.reduce(
      (acc, curr) => acc + parseInt(curr.numOfQuestions || 0),
      0
    );

    if (selectedLanguages.length <= 0) {
      toast.error("Please selected languages");
      return;
    }

    // Validate total number of questions
    if (totalAssignedQuestions !== parseInt(totalQuestions)) {
      setErrors({
        totalQuestions:
          "Total questions must match the sum of all topics' question distributions.",
      });
      return;
    } else if (parseInt(thresholdQuestions) > parseInt(totalQuestions)) {
      setErrors({
        thresholdQuestions:
          "Threshold questions cannot be more than total questions.",
      });
      return;
    } else if (parseInt(totalTime) <= 0) {
      setErrors({
        totalTime: "total time must be greater than 0",
      });
      return;
    }
    // Reset errors
    setErrors({});

    // Save the coding configuration to the parent component's formData
    const codingConfig = {
      totalQuestions,
      totalTime,
      topics,
      thresholdQuestions,
      selectedLanguages,
    };

    setFormData((prev) => ({
      ...prev,
      rounds: prev.rounds.map((round, index) =>
        index === roundIndex ? { ...round, config: codingConfig } : round
      ),
    }));

    setAllConfigsData((prevData) => ({
      ...prevData,
      codingConfig: codingConfig,
    }));
    setCurrentConfig(null);
  };

  const handleBackButton = () => {
    setCurrentConfig(null);
  };

  return (
    <>
      {loading && <GlobalLoader />}
      <div className={styles.codingConfigContainer}>
        <BackButton handleBackButton={handleBackButton} name={"Back"} />
        {/* Header */}
        {/* Header */}
        <div className={styles.headerRow}>
          <h2 className={styles.configTitle}>Set Configuration</h2>
          <div className={styles.roundDetails}>
            <div>
              Role Name:<span> {formData?.roleName}</span>
            </div>
            <div>
              Round Name: <span>{roundName}</span>
            </div>
            <div>
              Round Type: <span> {formData?.rounds?.[roundIndex]?.type}</span>
            </div>
          </div>
        </div>

        <div className={styles.detailsContains}>
          {/* Total Questions and Total Time */}
          <div className={styles.row}>
            <FieldWrapper label="Total Questions" error={errors.totalQuestions}>
              <InputBase
                disabled={!isEditable}
                type="number"
                placeholder="Enter total number of questions"
                value={totalQuestions}
                onChange={(e) => setTotalQuestions(e.target.value)}
                className={styles.inputField}
              />
            </FieldWrapper>

            <FieldWrapper label="Total Time (mins)" error={errors.totalTime}>
              <InputBase
                disabled={!isEditable}
                type="number"
                placeholder="Enter total time in minutes"
                value={totalTime}
                onChange={(e) => setTotalTime(e.target.value)}
                className={styles.inputField}
              />
            </FieldWrapper>

            <FieldWrapper
              label="Threshold Questions"
              error={errors.thresholdQuestions}
            >
              <InputBase
                disabled={!isEditable}
                type="number"
                placeholder="Enter total threshold questions"
                value={thresholdQuestions}
                onChange={(e) => setThresholdQuestions(e.target.value)}
                className={styles.inputField}
              />
            </FieldWrapper>
            <AllowedLanguages
              isEditable={isEditable}
              allLanguages={allowedLanguages}
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
            />
          </div>

          {/* Topic Rows */}
          {topics.map((topic, index) => (
            <div key={index} className={styles.topicRow}>
              {/* Topic */}
              <FieldWrapper label="Topic" error={errors.topic}>
                <Select
                  disabled={!isEditable}
                  value={topic.topic}
                  onChange={(e) =>
                    handleInputChange(index, "topic", e.target.value)
                  }
                  displayEmpty
                  className={styles.dropdown}
                >
                  <MenuItem value="" disabled>
                    Select Topic
                  </MenuItem>
                  {getAvailableTopics(index).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FieldWrapper>

              {/* No. of Questions */}
              <FieldWrapper label="No. of Questions">
                <InputBase
                  disabled={!isEditable}
                  type="number"
                  placeholder="No. of Questions"
                  value={topic.numOfQuestions}
                  onChange={(e) =>
                    handleInputChange(index, "numOfQuestions", e.target.value)
                  }
                  className={styles.inputField}
                />
              </FieldWrapper>

              {/* Delete Button */}
              <IconButton color="secondary" onClick={() => removeTopic(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}

          {/* Add Topic Button */}
          {isEditable && (
            <Button
              variant="outlined"
              color="primary"
              onClick={addTopic}
              className={styles.addTopicButton}
            >
              <AddIcon /> Add Topic
            </Button>
          )}

          {/* Save Button */}
          {isEditable && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className={styles.saveButton}
            >
              Save Configuration
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

// FieldWrapper Component for input label and error handling
const FieldWrapper = ({ label, error, children }) => {
  return (
    <div className={styles.fieldWrapper}>
      <label className={styles.fieldLabel}>{label}</label>
      {children}
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

const AllowedLanguages = ({
  allLanguages,
  selectedLanguages,
  setSelectedLanguages,
  isEditable,
}) => {
  const handleAddLanguage = (event, newValue) => {
    if (newValue && !selectedLanguages.includes(newValue)) {
      setSelectedLanguages([...selectedLanguages, newValue]);
    }
  };

  const handleDeleteLanguage = (languageToDelete) => {
    setSelectedLanguages((prev) =>
      prev.filter((language) => language !== languageToDelete)
    );
  };

  return (
    <Box className={styles.allowedLanguagesContainer}>
      {/* Title */}
      <Typography
        variant="subtitle1"
        className={styles.allowedLanguagesTitle}
        sx={{ mb: 1, fontWeight: "bold" }}
      >
        Allowed Language
      </Typography>

      {/* Input Dropdown (Empty Input) */}
      <Autocomplete
        disabled={!isEditable}
        className={styles.allowedLanguagesDropdown}
        options={allLanguages}
        onChange={(event, value) => handleAddLanguage(event, value)}
        freeSolo
        renderInput={(params) => (
          <TextField
            disabled={!isEditable}
            {...params}
            className={styles.allowedLanguagesInput}
            variant="outlined"
            size="small"
            placeholder="Select"
            InputProps={{
              ...params.InputProps,
              value: "", // Keep input always empty
            }}
          />
        )}
      />

      {/* Selected Values Below */}
      <Box
        className={styles.allowedLanguagesSelectedValues}
        sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}
      >
        {selectedLanguages.map((language, index) => (
          <Chip
            key={index}
            className={styles.allowedLanguagesChip}
            label={language}
            onDelete={() => handleDeleteLanguage(language)}
            sx={{
              backgroundColor: "#f0f4f7",
              color: "#333",
              "& .MuiChip-deleteIcon": {
                color: "#aaa",
                "&:hover": { color: "black" },
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CodingConfigComponent;
