import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import "./ClientDashboard.scss";
import { DashboardRole } from "./DashboardRole/DashboardRole";
import { DashboardCandidates } from "./DashboardCandidates/DashboardCandidates";
import { DashBoardOverview } from "./DashBoardOverview/DashBoardOverview";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

export const ClientDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clientName, setClientName] = useState("Karunesh");
  const [selectedTimeRange, setSelectedTimeRange] = useState("this-week"); // Default value for dropdown
  const [dateRange, setDateRange] = useState(""); // Start and End date
  const timeRangeOptions = [
    { value: "this-week", label: "This Week" },
    // { value: "this-month", label: "This Month" },
    // { value: "last-3-months", label: "Last 3 Months" },
  ];

  const handleTabClick = (route) => {
    navigate(route);
  };

  // Extract the sub-route name from the current path
  const getSubRouteName = () => {
    const parts = location.pathname.split("/").filter(Boolean);
    return parts.length > 1 ? parts[1] : "overview"; // Default to "overview" if no sub-route is provided
  };

  const subRouteName = getSubRouteName(); // Determine the sub-route name

  const handleTimeRangeChange = (event) => {
    setSelectedTimeRange(event.target.value);
  };

  useEffect(() => {
    const today = new Date(); // Today's date
    let startDate,
      endDate = today; // Fixed endDate as today's date

    switch (selectedTimeRange) {
      case "this-week":
        // Start of this week (Monday)
        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay() + 1); // Monday of the current week
        break;

      case "this-month":
        // Start of the current month
        startDate = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
        break;

      case "last-3-months":
        // Start of 3 months ago
        startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1); // First day of 3 months ago
        break;

      default:
        startDate = today; // Default to today's date
    }

    // Format dates as "2 Nov - 4 Nov"
    const formatDate = (date) =>
      `${date.getDate()} ${date.toLocaleString("default", { month: "short" })}`;
    setDateRange(`${formatDate(startDate)} - ${formatDate(endDate)}`);
  }, [selectedTimeRange]);

  // Render the component based on subRouteName
  const renderComponent = () => {
    switch (subRouteName) {
      case "overview":
        return <DashBoardOverview timeRange={selectedTimeRange} />;
      case "role":
        return <DashboardRole />;
      case "candidates":
        return <DashboardCandidates />;
      default:
        return <h1>Page Not Found</h1>;
    }
  };

  return (
    <PageLayout>
      <div className="dashboard-header-row">
        <DashboardHeader clientName={clientName} />
        <div className="dashboard-controls">
          <DashboardTabs onTabSelect={handleTabClick} />
          {subRouteName === "overview" && (
            <div className="dropdown-and-dates">
              <div className="date-range">{dateRange}</div>
              <FormControl size="small" className="time-range-dropdown">
                <InputLabel id="time-range-label">{""}</InputLabel>
                <Select
                  labelId="time-range-label"
                  value={selectedTimeRange}
                  onChange={handleTimeRangeChange}
                >
                  {timeRangeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-content">{renderComponent()}</div>
    </PageLayout>
  );
};

const DashboardHeader = ({ clientName }) => {
  return (
    <div className="dashboard-header">
      <div className="client-name">{"Hi " + clientName + ","}</div>
      <div className="dashboard-desc">
        Here’s how your hiring is going at Zeero!
      </div>
    </div>
  );
};

const DashboardTabs = ({ onTabSelect }) => {
  const location = useLocation();

  const tabs = [
    { route: "/dashboard/overview", label: "Overview" },
    { route: "/dashboard/role", label: "Job Role" },
    { route: "/dashboard/candidates", label: "Candidates" },
  ];

  const getTabClass = (route) => {
    return location.pathname === route ? "tab active" : "tab";
  };

  return (
    <div className="dashboard-tabs">
      {tabs.map((tab) => (
        <div
          key={tab.route}
          className={getTabClass(tab.route)}
          onClick={() => onTabSelect(tab.route)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};
