// Navbar.js
import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./Navbar.module.scss";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Menu items array
  const menuItems = [
    { label: "Profile", action: () => console.log("Profile clicked") },
    { label: "Settings", action: () => console.log("Settings clicked") },
    {
      label: "Logout",
      action: () => {
        console.log("Logout clicked");
        onClickLogout();
      },
    },
  ];

  async function onClickLogout() {
    try {
      const endpoint = "/erp/client/authentication/logout";
      const data = {
        // email: sessionStorage.getItem("email"),
        email: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      if (res) {
        // sessionStorage.clear();
        localStorage.clear();
        navigate("/client-login");
      }
    } catch (err) {
      console.error("error in logout", err);
      // sessionStorage.clear();
      localStorage.clear();
      navigate("/client-login");
    }
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.rightItems}>
        <div className={styles.customerSupport}>
          <IconButton color="inherit">
            <HeadsetMicOutlinedIcon />
          </IconButton>
          <span>Customer Support</span>
        </div>
        <div className={styles.userIcon}>
          <IconButton
            color="inherit"
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top", // Aligns the top of the menu with the bottom of the anchor (user icon)
              horizontal: "right", // Aligns the menu with the right edge of the anchor
            }}
            transformOrigin={{
              vertical: "top", // The top of the menu will be positioned at the anchor's bottom
              horizontal: "right", // The menu's right side will align with the anchor's right side
            }}
            onMouseEnter={handleMenuOpen}
            onMouseLeave={handleMenuClose}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  item.action();
                  handleMenuClose();
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
