import React from "react";
import { Box, Typography, Button, Grid, Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

export const DashboardRole = () => {
  const navigate=useNavigate()
  const data = {
    title: "Job Role",
    count: 30,
    description: "View all job roles for Zeero!",
    buttonText: "New Job Role",
    onButtonClick: () => {
      navigate("/roles/list")
      console.log("New Job Role Button Clicked!");
    },
  };

  const jobData = {
    title: "Python Developer",
    status: "Open",
    stats: [
      { label: "Applied", value: 121 },
      { label: "TFT", value: 80 },
      { label: "Coding Challenge", value: 55 },
      { label: "HR Assessment", value: 20 },
      { label: "Code Master", value: 9 },
      { label: "Expert Discussion", value: 2 },
    ],
    postedDate: "14 days ago",
    lastActiveCandidateTime: "25 minutes ago",
  };

  const jobDataClose = {
    title: "Python Developer",
    status: "Completed",
    stats: [
      { label: "Applied", value: 121 },
      { label: "TFT", value: 80 },
      { label: "Coding Challenge", value: 55 },
      { label: "HR Assessment", value: 20 },
      { label: "Code Master", value: 9 },
      { label: "Expert Discussion", value: 2 },
    ],
    postedDate: "14 days ago",
    lastActiveCandidateTime: "25 minutes ago",
  };
  return (
    <div>
      <JobRoleHeader data={data} />
      <JobCard jobData={jobData} />
      <JobCard jobData={jobDataClose} />
    </div>
  );
};
const JobRoleHeader = ({ data }) => {
  const { title, count, description, buttonText, onButtonClick } = data;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "16px",
      }}
    >
      {/* Left Section */}
      <Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#175BD6", display: "inline-block" }}
        >
          {title}
        </Typography>
        {count !== undefined && (
          <Typography
            variant="subtitle1"
            sx={{
              color: "#175BD6",
              display: "inline-block",
              marginLeft: "8px",
            }}
          >
            ({count})
          </Typography>
        )}
        <Typography variant="body2" sx={{ color: "#999999", marginTop: "4px" }}>
          {description}
        </Typography>
      </Box>

      {/* Right Section */}
      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{
          backgroundColor: "#062B6E",
          color: "#fff",
          fontWeight: "bold",
          textTransform: "none",
          padding: "8px 16px",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#1565c0",
          },
        }}
        startIcon={<AddIcon />}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

const JobCard = ({ jobData }) => {
  const { title, status, stats, postedDate, lastActiveCandidateTime } = jobData;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        marginTop: "32px",
      }}
    >
      {/* Job Title and Status */}
      <Box
        sx={{
          display: "flex",
          //   justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
          gap: "12px",
          borderBottom: "1px solid #ddd",
          paddingBottom: "10px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Chip
          label={status}
          sx={{
            backgroundColor: status === "Completed" ? "#E6E8EB" : "#CCDEFF", // Grey for "Completed"
            color: status === "Completed" ? "#828282" : "#0B52D4", // Grey text for "Completed"
            fontWeight: "inter",
            fontSize: "13px",
            borderRadius:'4px'
          }}
        />
      </Box>

      {/* Statistics */}
      <Grid container spacing={2} sx={{ margin: "16px 0" }}>
        {stats.map((stat, index) => (
          <Grid
            item
            xs={2}
            key={index}
            sx={{
              textAlign: "center",
              borderRight:
                index !== stats.length - 1 ? "1px solid #ddd" : "none",
            }}
          >
            <Typography variant="body2" sx={{ color: "#6b6b6b" }}>
              {stat.label}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {stat.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #ddd",
          paddingTop: "8px",
          gap: "10px", // Spacing between items
        }}
      >
        <Typography variant="body2" sx={{ color: "#6b6b6b" }}>
          Posted {postedDate}
        </Typography>
        {/* Grey Dot */}
        <Box
          sx={{
            width: "5px",
            height: "5px",
            backgroundColor: "#6b6b6b",
            borderRadius: "50%",
          }}
        />
        <Typography variant="body2" sx={{ color: "#6b6b6b" }}>
          Last active candidate {lastActiveCandidateTime}
        </Typography>
      </Box>
    </Box>
  );
};
