import { useEffect, useState } from "react";
import styles from "./SoftSkillsReport.module.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { ReportHeader } from "../ReportHeader/ReportHeader";
import GradeIcon from "@mui/icons-material/Grade";

export const SoftSkillsReport = ({
  selectedRound,
  setSelectedRound,
  roundValue,
  userDetails,
  setLoader,
  pageInput,
}) => {
  const [r3Details, setR3Details] = useState({});

  const fetchHRInterviewData = async () => {
    setLoader(true);
    const apiUrl = "/erp/client/report/softSkills";
    const requestData = {
      name: userDetails?.emailId,
      clientId: localStorage.getItem("clientId"),
      role: selectedRound.role,
      stageId: selectedRound.stageId,
    };

    try {
      const response = await axios.post(apiUrl, requestData);
      //   console.log("HR Interview Data:", response.data);
      setR3Details(response?.data);
      // Process the data as needed
    } catch (error) {
      console.error("Error fetching HR Interview data:", error);
      toast.error("Failed to fetch HR Interview data.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchHRInterviewData();
    // Add cleanup logic here if needed
    return () => {
      // Clean up code here
    };
  }, []);

  return (
    <div>
      <ReportHeader
        selectedRound={selectedRound}
        setSelectedRound={setSelectedRound}
        roundValue={roundValue}
        userDetails={userDetails}
        setLoader={setLoader}
        pageInput={pageInput}
        submissionDate={r3Details?.softSkillsRoundSubmissionDate?.split("T")[0]}
      />
      {r3Details && (
        <div className={styles.r3container}>
          <Summary data={r3Details?.summaryOfComments} />
          <TotalScore
            rating={r3Details?.totalScore}
            comments={r3Details?.comments}
          />
          {r3Details?.sections?.map((item, index) => (
            <EachCompetency key={index} data={item} />
          ))}
        </div>
      )}
    </div>
  );
};

const Summary = ({ data }) => {
  // console.log("r3 summary=>", data);
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>Round 3 : </div>
        <div className={styles.title}>Summary</div>
      </div>
      <div className={styles.row}>{data}</div>
    </div>
  );
};

const TotalScore = ({ rating, comments }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>Total Score : </div>
        <div className={`${styles.flex} ${styles.colorBlue}`}>
          <div>{rating}</div>
          <GradeIcon />
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.title2}`}>
          Overall Comment :
        </div>
        <div className={styles.comments}>{comments}</div>
      </div>
    </div>
  );
};

const EachCompetency = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.title}>{data?.section} : </div>
        <div>
          {Array(5)
            .fill(0)
            ?.map((item, index) => (
              <GradeIcon
                key={index}
                style={{
                  color: index + 1 <= data?.rating ? "#062B6E" : "#e0e0e0",
                }}
              />
            ))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.title2}`}>
          HR Observation :
        </div>
        <div className={styles.comments}>{data?.comments}</div>
      </div>
    </div>
  );
};
