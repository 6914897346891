import axios from "axios";
const VIEW_SCREEN_RECORDING_LIST = `/erp/client/role/view/screen/recording`;
const VIEW_VIDEO_RECORDING_LIST = `/erp/client/role/view/video/recording`;
//fetching videos
const VIEW_SCREEN_RECORDING = `https://prod-erp-be.zeero.us/softskillsInterview/api/v1/softskillsround/view/screen/recording`;
const VIEW_VIDEO_RECORDING = `https://prod-erp-be.zeero.us/softskillsInterview/api/v1/softskillsround/view/video/recording`;

export const getScreenRecordingList = async (data) => {
  const URL = VIEW_SCREEN_RECORDING_LIST;
  let Config = {};
  return await axios.post(URL, data, Config);
};

export const getScreenRecording = async (data) => {
  const URL = VIEW_SCREEN_RECORDING;
  const Config = {
    responseType: "blob",
  };
  return await axios.post(URL, data, Config);
};

export const getVideoRecordingList = async (data) => {
  const URL = VIEW_VIDEO_RECORDING_LIST;
  let Config = {};

  return await axios.post(URL, data, Config);
};

export const getVideoRecording = async (data) => {
  const URL = VIEW_VIDEO_RECORDING;
  const Config = {
    responseType: "blob",
  };

  return await axios.post(URL, data, Config);
};
