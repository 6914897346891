import { useEffect, useState } from "react";
import "./ScreenRecording.scss";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getScreenRecording,
  getScreenRecordingList,
} from "../../../Common/viewRecordings";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import dummyScreen from "../../../Assets/dev-recording/screen-dummy.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const ScreenRecording = ({ selectedValue }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [videoUrlList, setVideoUrlList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [videoBlobUrl, setVideoBlobUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFetchUrls = async () => {
    setLoading(true);
    try {
      if (queryParams?.get("user")) {
        const data = {
          name: queryParams.get("user"),
          round: selectedValue,
          clientId: localStorage.getItem("clientId"),
        };
        const res = await getScreenRecordingList(data);
        setVideoUrlList([...res?.data]);
      } else {
        throw new Error("User email or round missing");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = async (url, index) => {
    setSelectedIndex(index + 1);
    setOpen(true);
    await fetchApiData(url);
  };

  const handleClose = () => {
    setOpen(false);
    setVideoBlobUrl("");
  };

  const fetchApiData = async (url) => {
    setLoading(true);
    try {
      const data = {
        name: queryParams.get("user"),
        round: selectedValue,
        recordingUrl: url,
        clientId: localStorage.getItem("clientId"),
      };
      const res = await getScreenRecording(data);
      const blob = new Blob([res.data], { type: "video/webm" });
      const blobUrl = URL.createObjectURL(blob);
      setVideoBlobUrl(blobUrl);
    } catch (error) {
      console.error("Failed to fetch API data:", error);
      toast.error("Failed to fetch video.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedValue) handleFetchUrls();
  }, [selectedValue]);

  return (
    <div className="video-list">
      {videoUrlList.length > 0 ? (
        videoUrlList.map((url, index) => (
          <div
            key={index}
            className="video-item"
            onClick={() => handleOpen(url, index)}
          >
            <div className="dummy-image">
              <img src={dummyScreen} alt="" />
            </div>
            <div className="text">Screen Recording {index + 1}</div>
          </div>
        ))
      ) : (
        <div>No data found!</div>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          Screen Recording {selectedIndex}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className="loading-container">
              <CircularProgress />
            </div>
          ) : (
            videoBlobUrl && (
              <video controls width="100%">
                <source src={videoBlobUrl} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            )
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
