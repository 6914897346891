import "./CandidateSubHeader.scss";
import { candidateSubHeaderDataExported } from "../../mockData/candidateSubHeader";
import greyDot from "../../Assets/SVG/greyDot.svg";
import halfBlueDot from "../../Assets/SVG/halfBlueDot.svg";
import greenCheck from "../../Assets/SVG/roundCompleteCheck.svg";
import { useEffect, useState } from "react";
import { BackToHome } from "../BackToHome/BackToHome";
import axios from "axios";

export const CandidateSubHeader = ({ passedData, stages = {} }) => {
  const [candidateRole, setCandidateRole] = useState("");
  const [candidateSubHeaderData, setCandidateSubHeaderData] = useState({
    topHeader: [],
  }); // Initialize with empty topHeader array

  const getProfessionalDetails = async () => {
    try {
      const endpoint = "/candidate/details/get/professional";
      const data = { id: localStorage.getItem("email") };
      const res = await axios.post(endpoint, data);
      if (res?.data && res?.data?.subfield === "Data Science Engineer") {
        setCandidateRole("Data_Science");
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  const getCustomFormat = () => {
    console.log("stages received are =>", stages);

    if (stages?.stages && candidateSubHeaderDataExported.topHeader.length > 0) {
      // Create a map for candidateSubHeaderData topHeader by stageKey
      const topHeaderMap = candidateSubHeaderDataExported.topHeader.reduce(
        (map, item) => {
          map[item.stageKey] = item;
          return map;
        },
        {}
      );

      // Map over the stages to create the final array in the desired order
      const finalTopHeaderFormat = stages.stages
        .map((stage) => topHeaderMap[stage.stageType])
        .filter(Boolean); // Filter out undefined values

      // Update candidateSubHeaderData using setCandidateSubHeaderData to avoid direct mutation
      setCandidateSubHeaderData((prevData) => ({
        ...prevData,
        topHeader: finalTopHeaderFormat,
      }));
    } else {
      console.log("stages not found getCustomFormat==>");
      setCandidateSubHeaderData((prevData) => ({
        ...prevData,
        topHeader: [],
      }));
    }
  };

  useEffect(() => {
    getProfessionalDetails();
  }, []); // Only call this once when the component mounts

  useEffect(() => {
    if (candidateSubHeaderDataExported.topHeader.length > 0) {
      getCustomFormat(); // Call getCustomFormat only after topHeader is initialized
    }
  }, [stages, candidateSubHeaderDataExported.topHeader]); // Depend on stages and candidateSubHeaderData

  return (
    <div className="subeader-container">
      <BackToHome />
      <RoundTitles
        roundTitles={candidateSubHeaderData?.topHeader}
        passedData={passedData}
        candidateRole={candidateRole}
        stages={stages}
      />
    </div>
  );
};

const RoundTitles = ({ roundTitles, passedData, candidateRole, stages }) => {
  // Find the index of the first title whose key is not in passedData
  const [firstNotFoundIndex, setfirstNotFoundIndex] = useState(-1); // Initialize with -1

  useEffect(() => {
    const notFoundIndex = roundTitles?.findIndex(
      (item) => !passedData?.includes(item?.key)
    );
    setfirstNotFoundIndex(notFoundIndex);
  }, [roundTitles, passedData]);

  return (
    <>
      {stages && (
        <div className="titles-container">
          {roundTitles?.map((item, index) => {
            const isCompleted = passedData?.includes(item?.key);
            const isFirstNotFound =
              index === firstNotFoundIndex && firstNotFoundIndex !== -1;

            const className = isCompleted
              ? "title-wrapper-completed"
              : isFirstNotFound
              ? "title-wrapper-first-not-found"
              : "title-wrapper-normal";

            const imageSrc = isCompleted
              ? greenCheck
              : isFirstNotFound
              ? halfBlueDot
              : greyDot;

            return (
              <div key={index} className={className}>
                <div>
                  <img src={imageSrc} alt="" />
                </div>
                <div>
                  {candidateRole === "Data_Science" && item?.value2
                    ? item?.value2
                    : item?.value}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
