import React, { useState, useEffect } from "react";
import { Button, InputBase, Select, MenuItem, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios"; // Import axios for API call
import styles from "./MCQConfigComponent.module.scss";
import { BackButton } from "../../Common/BackButton/BackButton";
import { toast } from "react-toastify";
import { GlobalLoader } from "../../Common/GlobalLoader/GlobalLoader";

const MCQConfigComponent = ({
  roundName,
  setFormData,
  formData,
  roundIndex,
  setCurrentConfig,
  allConfigsData,
  setAllConfigsData,
  stageId,
  isEditable,
}) => {
  const [totalQuestions, setTotalQuestions] = useState("");
  const [thresholdQuestions, setThresholdQuestions] = useState("");
  const [questionTypes, setQuestionTypes] = useState([
    {
      questionType: "",
      questions: "",
      threshold: "",
      easy: 0,
      medium: 0,
      hard: 0,
    },
  ]);
  const [errors, setErrors] = useState({});
  const [configRecieved, setConfigRecieved] = useState();
  const [loading, setLoading] = useState(false);

  const [allOptions, setAllOptions] = useState([
    "Aptitude",
    "DSA",
    // "database",
    "Mysql",
    "Mongodb",
    // "python",
    // "java",
    // "javascript",
    // "servicenow",
  ]);

  const prefillData = (response) => {
    setTotalQuestions(response?.totalQuestions);
    setThresholdQuestions(response?.overallThreshold);
    const mappedQuestionTypes = [
      {
        questionType: "aptitude",
        questions: response.aptiQuestions,
        easy: response.aptiEasyQuestionInPercent,
        medium: response.aptiMediumQuestionInPercent,
        hard: response.aptiDifficultQuestionInPercent,
        threshold: response?.aptiThreshold,
      },
      ...response.languageQuestions.map((language) => ({
        questionType: language?.language,
        questions: language?.totalQuestions,
        easy: language?.languageQuestionInPercent?.Easy,
        medium: language?.languageQuestionInPercent?.Medium,
        hard: language?.languageQuestionInPercent?.Difficult,
        threshold: language?.languageThreshold,
      })),
      {
        questionType: response?.databaseQuestions?.database,
        questions: response?.databaseQuestions?.totalQuestions,
        easy: response?.databaseQuestions?.databaseQuestionInPercent?.Easy,
        medium: response?.databaseQuestions?.databaseQuestionInPercent?.Medium,
        hard: response?.databaseQuestions?.databaseQuestionInPercent?.Difficult,
        threshold: response?.databaseQuestions?.databaseThreshold,
      },

      {
        questionType: "dsa",
        questions: response.dsaQuestions,
        easy: response.dsaEasyQuestionInPercent,
        medium: response.dsaMediumQuestionInPercent,
        hard: response.dsaDifficultQuestionInPercent,
        threshold: response?.dsaThreshold,
      },
      {
        questionType: "dataScience",
        questions: response.dataScienceQuestions,
        easy: response.dataScienceEasyQuestionInPercent,
        medium: response.dataScienceMediumQuestionInPercent,
        hard: response.dataScienceDifficultQuestionInPercent,
        threshold: response?.dataScienceThreshold,
      },
    ];

    // Filter out entries where questions are 0
    // return mappedQuestionTypes.filter(item => item.questions > 0);
    // console.log("mappedQuestionTypes=>", mappedQuestionTypes);
    // Filter out entries where questions are 0 and update state directly
    setQuestionTypes(mappedQuestionTypes.filter((item) => item.questions > 0));
  };

  // API function to fetch stage data based on stageId
  const fetchStageData = async (stageId) => {
    try {
      setLoading(true);
      const data = {
        clientId: localStorage.getItem("clientId"),
        stageId,
        role: roundName,
      };
      const response = await axios.post(
        `/erp/client/role/mcq/config/get`,
        data
      );
      // return response.data?.config;
      setConfigRecieved(response.data);
      prefillData(response.data);
    } catch (error) {
      console.error("Error fetching stage data:", error);
      throw error; // Rethrow if you want to handle it in the component
    } finally {
      setLoading(false);
    }
  };

  const getAllAvailableTopics = async () => {
    const urlEndpoint = "/erp/client/role/available/mcq/languages/get";
    setLoading(true);
    try {
      const response = await axios.get(urlEndpoint);
      // console.log("Topics:", response.data); // Log or process the response data
      setAllOptions((prevOptions) => [...prevOptions, ...response?.data]);
    } catch (error) {
      console.error("Error fetching topics:", error.message);
    } finally {
      setLoading(false);
    }
  };

  // Prefill the data from formData if it exists
  useEffect(() => {
    getAllAvailableTopics();
    if (stageId) {
      fetchStageData(stageId);
    } else {
      const roundConfig = formData?.rounds?.[roundIndex]?.config;
      if (roundConfig) {
        setTotalQuestions(roundConfig.totalQuestions || "");
        setQuestionTypes(
          roundConfig.questionTypes || [
            { questionType: "", questions: "", easy: 0, medium: 0, hard: 0 },
          ]
        );
        setThresholdQuestions(roundConfig.thresholdQuestions || "");
      }
    }
  }, [formData, roundIndex, stageId]);

  // Track selected options
  const selectedOptions = questionTypes.map((type) => type.questionType);

  // Filter available options for each dropdown
  const getAvailableOptions = (index) => {
    // Create a copy of the selected options to avoid mutating the original array
    const unavailableOptions = [
      ...selectedOptions.map((option) => option?.toLowerCase()),
    ];

    // Get the currently selected option at the provided index
    const currentSelection = selectedOptions[index]?.toLowerCase();

    // Remove the current selection from the unavailable options to allow re-selection
    if (currentSelection) {
      const currentIndex = unavailableOptions.indexOf(currentSelection);
      if (currentIndex > -1) {
        unavailableOptions.splice(currentIndex, 1);
      }
    }

    // Additional logic to handle the MySQL and MongoDB exclusivity
    if (
      selectedOptions.map((option) => option?.toLowerCase()).includes("mysql")
    ) {
      // If 'mysql' is selected, add 'mongodb' to the unavailable options
      unavailableOptions.push("mongodb");
    }

    if (
      selectedOptions.map((option) => option?.toLowerCase()).includes("mongodb")
    ) {
      // If 'mongodb' is selected, add 'mysql' to the unavailable options
      unavailableOptions.push("mysql");
    }

    // Filter out the unavailable options from all options (case insensitive)
    return allOptions.filter(
      (option) => !unavailableOptions.includes(option.toLowerCase())
    );
  };

  // Handle adding a new question type
  const addQuestionType = () => {
    setQuestionTypes([
      ...questionTypes,
      { questionType: "", questions: "", easy: 0, medium: 0, hard: 0 },
    ]);
  };

  // Handle removing a question type
  const removeQuestionType = (index) => {
    const updatedQuestionTypes = questionTypes.filter((_, i) => i !== index);
    setQuestionTypes(updatedQuestionTypes);
  };

  // Handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedQuestionTypes = questionTypes.map((type, i) =>
      i === index ? { ...type, [field]: value } : type
    );
    setQuestionTypes(updatedQuestionTypes);
  };

  // Function to prepare and send API data
  const prepareAndSendApiData = async (formData) => {
    try {
      setCurrentConfig(null);
    } catch (error) {
      // console.error("Error while sending API data:", error);
      console.error("Error while creating API data:", error);
    }
  };

  // Handle form submission (save the config and call API)
  const handleSave = async () => {
    // Calculate total questions for all question types
    const totalAssignedQuestions = questionTypes.reduce(
      (acc, curr) => acc + parseInt(curr.questions || 0),
      0
    );

    // Validate total number of questions
    if (totalAssignedQuestions !== parseInt(totalQuestions)) {
      setErrors({
        totalQuestions:
          "Total questions must match the sum of all question types.",
      });
      return;
    }

    // Validate percentage sum (Easy, Medium, Hard must sum to 100)
    const invalidDistribution = questionTypes.some((type) => {
      const totalPercentage =
        parseInt(type.easy || 0) +
        parseInt(type.medium || 0) +
        parseInt(type.hard || 0);
      return totalPercentage !== 100;
    });

    if (invalidDistribution) {
      setErrors({
        distribution:
          "Easy, Medium, and Hard percentages must sum up to 100 for each question type.",
      });
      return;
    }

    // Validate thresholdQuestions
    if (
      !thresholdQuestions ||
      parseInt(thresholdQuestions) < 0 ||
      thresholdQuestions.trim() === ""
    ) {
      setErrors({
        thresholdQuestions:
          "Threshold questions must be present, cannot be less than 0, and cannot be empty.",
      });
      return;
    }

    for (const questionType of questionTypes) {
      if (!questionType.threshold || questionType.threshold.trim() === "") {
        setErrors({
          distribution: "Threshold questions must be present, for all rows",
        });
        return;
      }
    }

    // Calculate total questions for all question types
    let invalidThresholdDetected = false;

    const totalAssignedThresholds = questionTypes.reduce((acc, curr) => {
      // Check if any threshold is invalid (less than 0)
      if (curr.threshold < 0) {
        setErrors({
          distribution: `Threshold for ${curr.questionType} is invalid`,
        });
        invalidThresholdDetected = true;
        return acc; // Early return for this iteration
      }
      return acc + parseInt(curr.threshold || 0);
    }, 0);

    // Return early if an invalid threshold was detected
    if (invalidThresholdDetected) {
      return;
    }

    // Validate total number of questions
    if (totalAssignedThresholds !== parseInt(thresholdQuestions)) {
      setErrors({
        thresholdQuestions:
          "Total threshold must match the sum of all category thresholds.",
      });
      return;
    }

    // Reset errors
    setErrors({});

    // Prepare the MCQ config object
    const mcqConfig = {
      totalQuestions,
      questionTypes,
      thresholdQuestions,
    };

    // Call prepareAndSendApiData with the new data directly
    try {
      // Save the MCQ configuration to the parent component's formData
      setFormData((prev) => {
        const updatedFormData = {
          ...prev,
          rounds: prev.rounds.map((round, index) =>
            index === roundIndex ? { ...round, config: mcqConfig } : round
          ),
        };

        // Call prepareAndSendApiData with the updated formData
        prepareAndSendApiData(updatedFormData); // Pass the updated data here

        return updatedFormData;
      });

      // toast.success("MCQ Config and API data saved successfully!");
    } catch (error) {
      console.error("Error saving MCQ config and sending API data", error);
      toast.error("Failed to save MCQ config and send data to API");
    }

    // Optionally, reset currentConfig or handle UI updates
    // setCurrentConfig(null);
  };

  const handleBackButton = () => {
    setCurrentConfig(null);
  };

  return (
    <>
      {loading && <GlobalLoader />}
      <div className={styles.mcqConfigContainer}>
        <BackButton handleBackButton={handleBackButton} name={"Back"} />
        {/* Header */}
        <div className={styles.headerRow}>
          <h2 className={styles.configTitle}>Set Configuration</h2>
          <div className={styles.roundDetails}>
            <div>
              Role Name:<span> {formData?.roleName}</span>
            </div>
            <div>
              Round Name: <span>{roundName}</span>
            </div>
            <div>
              Round Type:{" "}
              <span style={{ textTransform: "uppercase" }}>
                {" "}
                {formData?.rounds?.[roundIndex]?.type}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.detailsContains}>
          {/* Total Questions */}
          <div className={styles.firstRow}>
            <FieldWrapper
              label="Total Questions"
              error={errors.totalQuestions || ""}
            >
              <InputBase
                type="number"
                placeholder="Enter total number of questions"
                value={totalQuestions}
                onChange={(e) => setTotalQuestions(e.target.value)}
                className={styles.inputField}
                disabled={!isEditable}
              />
            </FieldWrapper>
            <FieldWrapper
              label="Overall Threshold"
              error={errors.thresholdQuestions || ""}
            >
              <InputBase
                disabled={!isEditable}
                type="number"
                placeholder="Enter overall threshold"
                value={thresholdQuestions}
                onChange={(e) => setThresholdQuestions(e.target.value)}
                className={styles.inputField}
              />
            </FieldWrapper>
          </div>

          {/* Question Type Rows */}
          {questionTypes.map((type, index) => (
            <div key={index} className={styles.questionTypeRow}>
              {/* Question Type */}
              <FieldWrapper label="Question Type">
                <Select
                  disabled={!isEditable}
                  value={type.questionType}
                  onChange={(e) =>
                    handleInputChange(index, "questionType", e.target.value)
                  }
                  displayEmpty
                  className={styles.dropdown}
                >
                  <MenuItem value="" disabled>
                    Select Question Type
                  </MenuItem>
                  {getAvailableOptions(index).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FieldWrapper>

              {/* Number of Questions for Selected Type */}
              <FieldWrapper label="No. of Questions">
                <InputBase
                  disabled={!isEditable}
                  type="number"
                  placeholder="Questions"
                  value={type.questions}
                  onChange={(e) =>
                    handleInputChange(index, "questions", e.target.value)
                  }
                  className={styles.inputField}
                />
              </FieldWrapper>

              {/* thrsholdQuestions for Selected Type */}
              <FieldWrapper label="Min Threshold">
                <InputBase
                  disabled={!isEditable}
                  type="number"
                  placeholder="Min Threshold"
                  value={type.threshold}
                  onChange={(e) =>
                    handleInputChange(index, "threshold", e.target.value)
                  }
                  className={styles.inputField}
                />
              </FieldWrapper>

              {/* Easy, Medium, Hard */}
              <FieldWrapper
                label="Easy (%)"
                className={styles.smallFieldWrapper}
              >
                <InputBase
                  disabled={!isEditable}
                  type="number"
                  placeholder="Easy"
                  value={type.easy}
                  onChange={(e) =>
                    handleInputChange(index, "easy", e.target.value)
                  }
                  className={`${styles.inputField} ${styles.small}`}
                />
              </FieldWrapper>

              <FieldWrapper label="Medium (%)">
                <InputBase
                  disabled={!isEditable}
                  type="number"
                  placeholder="Medium"
                  value={type.medium}
                  onChange={(e) =>
                    handleInputChange(index, "medium", e.target.value)
                  }
                  className={`${styles.inputField} ${styles.small}`}
                />
              </FieldWrapper>

              <FieldWrapper label="Hard (%)">
                <InputBase
                  disabled={!isEditable}
                  type="number"
                  placeholder="Hard"
                  value={type.hard}
                  onChange={(e) =>
                    handleInputChange(index, "hard", e.target.value)
                  }
                  className={`${styles.inputField} ${styles.small}`}
                />
              </FieldWrapper>

              {/* Delete Button */}
              <IconButton
                color="secondary"
                onClick={() => removeQuestionType(index)}
                className={styles.deleteIcon}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}

          {/* Add Question Type Button */}
          {isEditable && (
            <Button
              variant="outlined"
              color="primary"
              onClick={addQuestionType}
              className={styles.addQuestionTypeButton}
            >
              <AddIcon /> Add Question Type
            </Button>
          )}

          {/* Save Button */}
          {isEditable && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className={styles.saveButton}
            >
              Save
            </Button>
          )}

          {/* Display Errors */}
          {errors.distribution && (
            <div className={styles.errorText}>{errors.distribution}</div>
          )}
        </div>
      </div>
    </>
  );
};

// FieldWrapper Component for input label and error handling
const FieldWrapper = ({ label, error, children }) => {
  return (
    <div className={styles.fieldWrapper}>
      <label className={styles.fieldLabel}>{label}</label>
      {children}
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default MCQConfigComponent;
