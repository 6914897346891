import "./DashboardCandidates.scss";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

export const DashboardCandidates = () => {
  const roles = ["Java", "Python", "Frontend Developer", "Backend Developer"];
  const jobData = {
    count: 200,
    description: "Candidates available @ Zeero!",
  };

  return (
    <div className="dashboard-candidates">
      <CandidatesOverviewWithFilter roles={roles} {...jobData} />
      <ApplicationProgressTable />
    </div>
  );
};

const CandidatesOverviewWithFilter = ({ roles, count, description }) => {
  const [role, setRole] = useState(""); // Role dropdown state
  const [openFilterDialog, setOpenFilterDialog] = useState(false); // Filter dialog state
  const [selectedFilter, setSelectedFilter] = useState(""); // Filter dropdown state

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleFilterOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterClose = () => {
    setOpenFilterDialog(false);
  };

  const handleSave = () => {
    console.log("Filter saved with value:", selectedFilter);
    setOpenFilterDialog(false);
  };

  const handleReset = () => {
    setSelectedFilter("");
    setOpenFilterDialog(false);
  };

  return (
    <Box
      sx={{
        padding: "16px",
        marginTop: "30px",
      }}
    >
      {/* Row 1: Candidates Count */}
      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#175BD6", }}>
        Candidates ({count})
      </Typography>

      {/* Row 2: Description */}
      <Typography variant="body2" sx={{ color: "#999999", marginTop: "8px" }}>
        {description}
      </Typography>

      {/* Row 3: Role Dropdown and Filter Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
        }}
      >
        {/* Left Side: Role Dropdown */}
        <Select
          value={role}
          onChange={handleRoleChange}
          displayEmpty
          inputProps={{ "aria-label": "Role" }}
          sx={{ width: "200px", height: "40px" }}
        >
          <MenuItem value="" disabled>
            Select Role
          </MenuItem>
          {roles.map((roleItem, index) => (
            <MenuItem key={index} value={roleItem}>
              {roleItem}
            </MenuItem>
          ))}
        </Select>

        {/* Right Side: Filter Button */}
        <IconButton
          onClick={handleFilterOpen}
          sx={{ display: "flex", alignItems: "center", padding: "8px",borderRadius:'4px',backgroundColor:'#062B6E',color:'#fff' }}
        >
          <Typography variant="body2" sx={{ marginRight: "8px",fontSize:'12px' }}>
            Filter
          </Typography>
          <FilterListIcon sx={{height:'20px',width:'20px'}} />
        </IconButton>
      </Box>

      {/* Filter Dialog (Pop-up) */}
      <Dialog
        open={openFilterDialog}
        onClose={handleFilterClose}
        sx={{
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "64px",
            right: "16px",
            width: "300px",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Filter Options</DialogTitle>
        <DialogContent>
          <Select
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ marginBottom: "16px" }}
          >
            <MenuItem value="" disabled>
              Select Filter
            </MenuItem>
            <MenuItem value="TFT">TFT</MenuItem>
            <MenuItem value="MCQ">MCQ</MenuItem>
            <MenuItem value="Coding Challenge">Coding Challenge</MenuItem>
            <MenuItem value="HR Assessment">HR Assessment</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} color="secondary">
            Reset
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
const ApplicationProgressTable = () => {
  // Dummy data
  const candidates = [
    {
      name: "John Doe",
      email: "john.doe@example.com",
      appliedFor: "Python Developer",
      currentRound: "TFT",
      roundStatus: "In Progress",
      ratings: 4.5,
      applicationProgress: 60,
    },
    {
      name: "Jane Smith",
      email: "jane.smith@example.com",
      appliedFor: "Java Developer",
      currentRound: "Coding Challenge",
      roundStatus: "Completed",
      ratings: 4.8,
      applicationProgress: 100,
    },
    {
      name: "David Lee",
      email: "david.lee@example.com",
      appliedFor: "Data Scientist",
      currentRound: "MCQ",
      roundStatus: "In Progress",
      ratings: 4.0,
      applicationProgress: 40,
    },
    {
      name: "Emily Clark",
      email: "emily.clark@example.com",
      appliedFor: "Frontend Developer",
      currentRound: "HR Assessment",
      roundStatus: "-",
      ratings: "-",
      applicationProgress: 100,
    },
  ];

  return (
    <Box
      sx={{ fontFamily: "Arial, sans-serif" }}
      className="CandidateMetrixWeekly-wrapper"
    >
      {/* Row 1: Header */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <Typography
            variant="h5"
            padding={"20px 10px"}
            sx={{ fontWeight: "bold" }}
          >
            Candidates This Week
          </Typography>
        </Grid>
      </Grid>

      {/* Row 2: Table */}
      <Table sx={{ tableLayout: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
                padding: "10px 0",
                backgroundColor: "#e0f7fa", // Light blue background for header
                borderBottom: "1px solid #ddd", // Border bottom for header
                paddingLeft: "10px", // Apply padding-left to the first header cell
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                padding: "10px 0",
                backgroundColor: "#e0f7fa", // Light blue background for header
                borderBottom: "1px solid #ddd", // Border bottom for header
              }}
            >
              Applied For
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                padding: "10px 0",
                backgroundColor: "#e0f7fa", // Light blue background for header
                borderBottom: "1px solid #ddd", // Border bottom for header
              }}
            >
              Current Round
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                padding: "10px 0",
                backgroundColor: "#e0f7fa", // Light blue background for header
                borderBottom: "1px solid #ddd", // Border bottom for header
              }}
            >
              Round Status
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                padding: "10px 0",
                backgroundColor: "#e0f7fa", // Light blue background for header
                borderBottom: "1px solid #ddd", // Border bottom for header
              }}
            >
              Ratings
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                padding: "10px 0",
                backgroundColor: "#e0f7fa", // Light blue background for header
                borderBottom: "1px solid #ddd", // Border bottom for header
                paddingRight: "10px", // Apply padding-right to the last header cell
              }}
            >
              Application Progress
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {candidates.map((candidate, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  padding: "10px 0",
                  borderBottom: "1px solid #ddd",
                  paddingLeft: "10px", // Apply padding-left to the first cell in each row
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {candidate.name || "-"}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#6b6b6b" }}>
                    {candidate.email || "-"}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
              >
                {candidate.appliedFor || "-"}
              </TableCell>
              <TableCell
                sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
              >
                {candidate.currentRound || "-"}
              </TableCell>
              <TableCell
                sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
              >
                {candidate.roundStatus || "-"}
              </TableCell>
              <TableCell
                sx={{ padding: "10px 0", borderBottom: "1px solid #ddd" }}
              >
                {candidate.ratings || "-"}
              </TableCell>
              <TableCell
                sx={{
                  padding: "10px 0",
                  borderBottom: "1px solid #ddd",
                  paddingRight: "10px", // Apply padding-right to the last cell in each row
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item sx={{ flex: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={candidate.applicationProgress}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        mr: 1,
                        backgroundColor: "#e0e0e0",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor:
                            candidate.applicationProgress >= 80
                              ? "darkblue"
                              : "blue",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ paddingLeft: 0, paddingRight: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {candidate.applicationProgress}%
                    </Typography>
                  </Grid>
                  {candidate.applicationProgress === 100 && (
                    <Grid item sx={{ paddingLeft: 2 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          fontSize: "12px",
                          textTransform: "none",
                        }}
                      >
                        View Profile
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
