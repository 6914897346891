import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { BackButton } from "../../Components/Common/BackButton/BackButton";
import PageLayout from "../../Components/Common/PageLayout/PageLayout";
import "./ErpCandidateReport.scss";
import { GlobalLoader } from "../../Components/Common/GlobalLoader/GlobalLoader";
import { toast } from "react-toastify";
import { SoftSkillsReport } from "../../Components/ErpDevReport/SoftSkillsReport/SoftSkillsReport";
import { McqRoundReport } from "../../Components/ErpDevReport/McqRoundReport/McqRoundReport";
import { CodingRoundReport } from "../../Components/ErpDevReport/CodingRoundReport/CodingRoundReport";

// Component for Candidate Report with Back Button
export const ErpCandidateReport = () => {
  const handleBackButton = () => {
    window.history.back();
  };

  return (
    <PageLayout>
      <BackButton name={"Back"} handleBackButton={handleBackButton} />
      <DevReport />
    </PageLayout>
  );
};

// Component for Developer Report
const DevReport = () => {
  const componentRef = useRef();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(location.state || {});
  const [roundValue, setRoundValue] = useState([]);
  const [selectedRound, setSelectedRound] = useState({});

  const fetchRoundData = async () => {
    try {
      const apiUrl = "/erp/client/report/getStages";
      const requestData = {
        clientId: localStorage.getItem("clientId"),
        roleId: userDetails.userRole,
      };
      const response = await axios.post(apiUrl, requestData);

      setRoundValue([...response?.data]);
    } catch (error) {
      console.error("Error fetching round data:", error);
    }
  };

  useEffect(() => {
    if (userDetails.emailId) {
      fetchRoundData();
    }
  }, [userDetails]);

  useEffect(() => {
    if (roundValue.length > 0) {
      setSelectedRound(roundValue[0]);
    }
  }, [roundValue]);

  // Mapping stage types to components
  const stageTypeComponents = {
    softSkillsInterview: SoftSkillsReport,
    mcq: McqRoundReport,
    coding: CodingRoundReport,
  };

  const ComponentToRender = selectedRound
    ? stageTypeComponents[selectedRound.stageType]
    : null;

  return (
    <div ref={componentRef}>
      {loader && <GlobalLoader />}
      <div>
        {ComponentToRender ? (
          <ComponentToRender
            selectedRound={selectedRound}
            setSelectedRound={setSelectedRound}
            roundValue={roundValue}
            userDetails={userDetails}
            setLoader={setLoader}
            pageInput={componentRef}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default DevReport;
