// PageLayout.js
import React, { useState } from "react";
import styles from "./PageLayout.module.scss";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

const PageLayout = ({ children }) => {
  const [isCollapsible, setIsCollapsible] = useState(false);

  return (
    <div className={styles.layout}>
      <Navbar />
      <Sidebar />
      <main className={styles.content}>{children}</main>
    </div>
  );
};

export default PageLayout;
