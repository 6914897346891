import React, { useEffect, useState } from "react";
import "./DashBoardOverview.scss";
import upGraph from "../../../Assets/SVG/Dashboard/upGraph.svg";
import downGraph from "../../../Assets/SVG/Dashboard/downGraph.svg";
import { Upgrade } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { TimeLineMetrix } from "../../../Components/DashboardClient/TimeLineMetrix/TimeLineMetrix";
import { CandidateMetrixWeekly } from "../../../Components/DashboardClient/CandidateMetrixWeekly/CandidateMetrixWeekly";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import axios from "axios";

export const DashBoardOverview = ({ timeRange }) => {
  const [timerangeMetrix, setTimeRangeMetrix] = useState([
    {
      title: "Active Job Roles",
      num: 0,
      up: true,
      difference: 40,
      desc: "Vs last week",
    },
    {
      title: "Invited Candidates",
      num: 0,
      up: false,
      difference: 10,
      desc: "Vs last week",
    },
    {
      title: "Candidates cleared all rounds",
      num: 0,
      up: true,
      difference: 20,
      desc: "Vs last week",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const prefillStatsData = (data) => {
    const temp = timerangeMetrix;
    temp[0].num = data?.activeJobRoles;
    temp[1].num = data?.invitedCandidates;
    temp[2].num = data?.candidatesClearedAllRound;
    setTimeRangeMetrix([...temp]);
  };

  function getCurrentWeekFormattedDates() {
    const now = new Date();

    // Calculate the start date (Monday of the current week)
    const dayOfWeek = now.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust for Sunday as the start of the week
    const monday = new Date(now);
    monday.setDate(now.getDate() + daysToMonday);
    monday.setHours(0, 0, 0, 0); // Set time to start of the day (00:00:00)

    // Calculate the end date (today's date)
    const today = new Date(now);
    today.setHours(0, 0, 0, 0); // Set time to start of the day (00:00:00)

    // Helper function to format date as "yyyy-MM-ddTHH:mm:ss"
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}T00:00:00`;
    };

    // Format the start and end dates
    const formattedStartDate = formatDate(monday);
    const formattedEndDate = formatDate(today);

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  }

  const getAllTheStats = async () => {
    // Define the URL and the data payload
    const url = "/erp/client/dashboard/stats"; // Replace with your API URL
    const weekDates = getCurrentWeekFormattedDates();
    const data = {
      clientId: localStorage.getItem("clientId"),
      startDate: weekDates.startDate,
      // startDate: "2024-12-15T00:00:00",
      endDate: weekDates.endDate,
      // endDate: "2024-12-16T00:00:00",
    };
    setLoading(true);

    try {
      // Make the POST request using axios
      const response = await axios.post(url, data);

      // Handle the response
      // console.log("API Response:", response.data);
      prefillStatsData(response.data);
      return response.data; // Return the response for further use
    } catch (error) {
      // Handle errors
      console.error("Error fetching stats:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTheStats();
  }, []);
  return (
    <div className="dashboard-overview">
      {loading && <GlobalLoader />}
      <TimeRangeMettrix data={timerangeMetrix} />
      <TimeLineMetrix />
      <CandidateMetrixWeekly />
    </div>
  );
};

const TimeRangeMettrix = ({ data }) => {
  return (
    <div className="dashboard-graph">
      {data?.map((item, index) => (
        <EachTimeRangeMettrix key={index} data={item} />
      ))}
    </div>
  );
};

const EachTimeRangeMettrix = ({ data }) => {
  return (
    <div className="each-item">
      <div className="title">{data?.title}</div>
      <div className="graph">
        <div>{data?.num}</div>
        <div>
          <img src={data?.up ? upGraph : downGraph} alt="" />
        </div>
      </div>
      <div className="desc">
        <div className={data?.up ? "upClass" : "downClass"}>
          <span>{data?.up ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
          <span>{data?.difference + "%"}</span>
        </div>
        <div>{data?.desc}</div>
      </div>
    </div>
  );
};
