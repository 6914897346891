import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const AlwaysForwardToRound2 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get the current query params from the URL
    const queryParams = new URLSearchParams(location.search).toString();

    // Construct the new URL with the query params
    const newUrl = `/assessment/coding${queryParams ? `?${queryParams}` : ""}`;

    // Navigate to the new URL
    navigate(newUrl);
  }, [location, navigate]);

  return null;
};
