import { useState } from "react";
import "./ReportHeader.scss";
import useStickyElements from "../../../hooks/useStickyElements";
import generatePDF from "react-to-pdf";
import { Select, MenuItem } from "@mui/material";

export const ReportHeader = ({
  selectedRound,
  setSelectedRound,
  roundValue,
  userDetails,
  setLoader,
  pageInput,
  submissionDate,
}) => {
  const [excludeForPdf, setExcludeForPdf] = useState(false);
  return (
    <div className="dev-report-header-container">
      <div className="personal">
        <div className="name">{userDetails?.userName}</div>
        <div className="submit">Submitted on</div>
        <div className="date">{submissionDate}</div>
      </div>
      {excludeForPdf ? (
        <></>
      ) : (
        <div className="data">
          <DownloadPage
            pageInput={pageInput}
            setExcludeForPdf={setExcludeForPdf}
            setShowLoader={setLoader}
          />
          <div className="roundNUm">
            <CustomDropDown
              options={roundValue}
              label={""}
              handleChange={(val) => setSelectedRound(val)}
              value={selectedRound}
              placeholder={""}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const DownloadPage = ({ pageInput, setExcludeForPdf, setShowLoader }) => {
  const options = {
    filename: "download.pdf",
    page: {
      margin: 10,
    },
  };
  const classNames = ["top-group", "details"];
  const { resetStickyPositions, restoreStickyPositions } = useStickyElements(
    pageInput,
    classNames
  );

  const handleGeneratePdf = () => {
    setExcludeForPdf(true);
    // setShowLoader(true);

    resetStickyPositions();

    setTimeout(async () => {
      try {
        await generatePDF(pageInput, options);
      } finally {
        // Restore sticky positions
        restoreStickyPositions();

        setExcludeForPdf(false);
        setShowLoader(false);
      }
    }, 0);
  };

  return (
    <button className="download" onClick={handleGeneratePdf}>
      DOWNLOAD
    </button>
  );
};

const CustomDropDown = ({
  label,
  options,
  handleChange,
  value,
  placeholder,
  isDisabled = false,
}) => {
  const handleValueChange = (stageName) => {
    // Find the option with the matching stageName
    const selectedOption = options.find(
      (option) => option.stageName === stageName
    );

    // Pass the selected option to the handleChange function
    if (selectedOption) {
      console.log("new selected option: ", selectedOption);
      handleChange(selectedOption);
    }
  };

  return (
    <div className="drop-down-container-header">
      <label className="drop-down-label">{label}</label>
      <Select
        value={value?.stageName}
        onChange={(e) => handleValueChange(e.target.value)}
        // displayEmpty
        fullWidth
        className={`select-value ${value === "" ? "empty-value" : ""}`}
        disabled={isDisabled}
        style={{ cursor: isDisabled ? "not-allowed" : "" }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option?.stageName}>
            {option?.stageName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
