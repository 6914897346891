import axios from "axios";
export const afterLogin = async () => {
  try {
    const userProgress = await getUserProgress();
    console.log("userProgress==>", userProgress);
    // if (userProgress?.progress) {
    redirectAfterLogin(userProgress.progress || []);
    // }
  } catch (err) {
    console.log("userProgress error==>", err);
  }
};

export const afterClinetSignup = async () => {
  try {
    // const userProgress = await getUserProgress();
    // console.log("userProgress==>", userProgress);
    window.location.href = "/roles/list";
  } catch (err) {
    console.log("userProgress error==>", err);
  }
};

export const getUserProgress = async () => {
  try {
    const endpoint = "/candidate/getprogress";
    // const email = sessionStorage.getItem("email");
    const email = localStorage.getItem("email");
    const clientId = localStorage.getItem("clientId");
    const data = {
      id: email,
      clientId: clientId,
    };
    //axios post call
    const res = await axios
      .post(endpoint, data)
      .then((response) => response.data);
    return res;
  } catch (err) {
    console.log("getUserProgress error==>", err);
    return Promise.reject(err);
  }
};

export const overAllUserProgress = async () => {
  try {
    const endpoint = "/candidate/getprogress/roundProgress";
    // const email = sessionStorage.getItem("email");
    const email = localStorage.getItem("email");
    const data = {
      name: email,
    };
    //axios post call
    const res = await axios
      .post(endpoint, data)
      .then((response) => response.data);
    return res;
  } catch (err) {
    console.log("handleCheckR3Progress error==>", err);
    return Promise.reject(err);
  }
};

export const getPassedData = async () => {
  // get passed data
  // compare it with assessmentPassedData
  // accordingly add continue and passed options
  try {
    const urlEndpoint = "/candidate/passedDetails";
    // const email = sessionStorage.getItem("email");
    const email = localStorage.getItem("email");

    const data = {
      name: email,
    };
    const passedData = await axios.post(urlEndpoint, data);
    return passedData?.data;
  } catch (err) {
    console.log("gettng passed data error==>", err);
  }
};

export const getUserCustomTestFormat = async (
  candidateEmail = "",
  fetchNew = false
) => {
  // console.log("step3=>", candidateEmail);
  const email = candidateEmail ? candidateEmail : localStorage.getItem("email");
  const clientId = localStorage.getItem("clientId");

  // Check if the custom test format is already stored in localStorage
  const cachedFormat = localStorage.getItem("customTestFormat");

  if (!fetchNew && cachedFormat) {
    // If the custom test format exists in localStorage, return it
    return JSON.parse(cachedFormat);
  }

  // If not available in localStorage, fetch from the API
  const endpoint = "/candidate/getprogress/custom/format";
  const data = {
    candidateId: email,
    invitedByUserId: clientId,
  };

  try {
    const response = await axios.post(endpoint, data);
    const customTestFormat = response.data;

    // Store the fetched custom test format in localStorage
    localStorage.setItem("customTestFormat", JSON.stringify(customTestFormat));

    // Return the fetched data
    return customTestFormat;
  } catch (error) {
    console.error("Error fetching custom test format:", error);
    // Optionally return dummy data if there’s an error
    return {};
  }
};

// const dummyFormat = {
//   id: "66fe6c3b0b6a6b1484fd0697",
//   roleId: "SSE",
//   clientId: "66f64e0822ae6e3832e12251",
//   hiringProcessId: "742776",
//   stages: [
//     {
//       stageId: "648888",
//       stageType: "assignment",
//       stageName: "assignment",
//     },
//     {
//       stageId: "385871",
//       stageType: "hr",
//       stageName: "hr",
//     },
//     // {
//     //   stageId: "648888",
//     //   stageType: "discussion",
//     //   stageName: "discussion",
//     // },
//     // {
//     //   stageId: "111173",
//     //   stageType: "coding",
//     //   stageName: "coding",
//     // },
//     // {
//     //   stageId: "648888",
//     //   stageType: "mcq",
//     //   stageName: "mcq",
//     // },
//   ],
//   created_at: "2024-10-03T10:04:43.528+00:00",
// };

const redirectAfterLogin = (data = []) => {
  if (
    data.includes("Personal Details")
    // && data.includes("Professional Experience & Technical Skills")
  ) {
    // It has both "Personal Details" and "Professional Experience & Technical Skills"
    window.location.href = "/assessment/home";
  }
  //  else if (data.includes("Personal Details")) {
  //   // It has "Personal Details" but not "Professional Experience & Technical Skills"
  //   window.location.href = "/details/professional";
  // }
  else {
    // It doesn't have either "Personal Details" or "Professional Experience & Technical Skills"
    window.location.href = "/details/personal";
  }
};
